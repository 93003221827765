.block__accordion {
    .block__body {
        .accordion {
            --bs-accordion-bg: transparent;
            
            .accordion-item {
                .accordion-header {
                    .accordion-button {
                        color: var(--bs-body-color);
                        font-size: 1.5rem;
                        font-weight: 500;
                        padding-left: 0;
                        padding-right: 3rem;
                       
                        &::before,
                        &::after {
                            content: '';
                            width: 1.5rem;
                            height: 1.5rem;
                            margin-top: -0.75rem;
                            @include transition;
                            position: absolute;
                            top: 50%;
                            right: 1rem;
                        }
                        
                        /* Minus */
                        &::after {
                            background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M432 256c0 13.3-10.7 24-24 24L40 280c-13.3 0-24-10.7-24-24s10.7-24 24-24l368 0c13.3 0 24 10.7 24 24z" fill="%230D2240"/></svg>') center no-repeat;
                            transform:rotate(-90deg);
                            opacity:0;
                        }

                        /* Plus */
                        &::before {
                            background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M248 72c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 160L40 232c-13.3 0-24 10.7-24 24s10.7 24 24 24l160 0 0 160c0 13.3 10.7 24 24 24s24-10.7 24-24l0-160 160 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-160 0 0-160z" fill="%230D2240"/></svg>') center no-repeat;
                            opacity:1;
                        }

                        &:focus {
                            box-shadow: initial;
                        }

                        &[aria-expanded="true"] {
                            background-color: transparent;
                            color:var(--bs-body-color);

                            &::after {
                                transform:rotate(0deg);
                                opacity:1;
                            }

                            &::before {
                                transform:rotate(90deg);
                                opacity:0;
                            }
                        }
                    }
                }

                .accordion-body {
                    padding-inline: 0;
                }
            }
        }
    }
}