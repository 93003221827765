.block__text {

    .block__header__banner {
        figure {
            margin:0;
            img {
                aspect-ratio: 16 / 9;
                width: 100%;
                height:100%;
                object-fit: cover;
            }
        }
    }

    .block__header {
        .block__header__title {
            margin-top: var(--margin-default);
        }
        .block__header__byline {
            position:relative;
            font-size:1rem;
            color: $gray-800;
            margin-top:3rem;

            &:before {
                content:"";
                position: absolute;
                top:-1.5rem;
                width:150px;
                height:3px;
                background: var(--color-main-accent-hex);
                display:block;
            }
        }
    }

    .block__body {
        .text {
            blockquote {
                margin-block: calc(var(--bs-body-font-size) * 3);
                padding-inline: calc(var(--bs-body-font-size) * 3);
                border-left: 2px solid #a6a6a6;
                font-style:italic;
                font-size:calc(var(--bs-body-font-size) * 1.1);
                line-height:calc(var(--bs-body-font-size) * 1.875);
                color: #848484;
            }
        }
    }

    .block__footer {
        margin-top: calc(var(--margin-default)*1);

        .block__footer__contact {
            background-color: $gray-300;
            color:#000;
            padding: var(--padding-default);

            h3 {
                font-size:calc(var(--bs-body-font-size) * 1.25);
            }
        }

        .bttn {
            + .bttn {
                margin:.5rem;
            }
        }
    }
}