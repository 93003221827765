/*
=======================================================================
    Fonts
=======================================================================
*/

//@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;1,100;1,300;1,400;1,500&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;300;400;500&display=swap');
@import url('//fonts.googleapis.com/css2?family=Wix+Madefor+Display:wght@600;700&family=Wix+Madefor+Text:wght@400;500;600&display=swap');

/*
=======================================================================
    Body
=======================================================================
*/

body {
    font-family: 'Wix Madefor Text', sans-serif;
    font-weight: 400;
    color: var(--bs-body-color);
}


/*
=======================================================================
    Typography Vertical Rythm
=======================================================================
*/

blockquote {
    margin-top: 0;
    margin-bottom: 0;
}

.text {
    > * {
        margin-top: var(--margin-default);
    }
}

.text > :first-child {
    margin-top: 0!important;
}

.text > :last-child {
    margin-bottom: 0!important;
}

.text + .text {
    margin-top: var(--margin-default);

    @include media-breakpoint-up(lg) {
        margin-top:calc(var(--margin-default)*2);
    }
}

.text .teaser {
    font-weight:300;
}

.text h1, .text h2, .text h3, .text h4, .text h5, .text h6 {
    margin-bottom: var(--margin-default);
}

.text *:not(figure) + h1,
.text *:not(figure) + h2,
.text *:not(figure) + h3,
.text *:not(figure) + h4,
.text *:not(figure) + h5,
.text *:not(figure) + h6,

.text *:not(figure) > h1,
.text *:not(figure) > h2,
.text *:not(figure) > h3,
.text *:not(figure) > h4,
.text *:not(figure) > h5,
.text *:not(figure) > h6 {
    margin-top: calc(var(--margin-default) * 1.25);
}

.text > :first-child,
.text * + h1, .text * + h2, .text * + h3, .text * + h4, .text * + h5, .text * + h6,
.text * > h1, .text * > h2, .text * > h3, .text * > h4, .text * > h5, .text * > h6 {
    margin-bottom: calc(var(--margin-default) / 2.5);
}

.text h1 + *, .text h2 + *, .text h3 + *, .text h4 + *, .text h5 + *, .text h6 + * {
    margin-top:0;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
    font-family: 'Wix Madefor Display', sans-serif;
    font-weight:700;
    margin-bottom:0;
}


/*
=======================================================================
    Links
=======================================================================
*/

main {
    a {
        color: var(--color-link-hex);
        &:hover {
            color: var(--color-link-hover-hex);
        }
    }
}


/*
=======================================================================
    Responsive Font Sizes
=======================================================================
*/

/* Old */
/*
:root {
    --font-size-sm: clamp(0.8rem, 0.08vw + 0.78rem, 0.84rem);
    --font-size-base: clamp(1rem, 0.23vw + 0.94rem, 1.13rem);
    --font-size-md: clamp(1.25rem, 0.45vw + 1.14rem, 1.5rem);
    --font-size-lg: clamp(1.56rem, 0.79vw + 1.36rem, 2.5rem);
    --font-size-xl: clamp(1.95rem, 1.29vw + 1.63rem, 2.66rem);
}
*/

/* New */
:root {
    --font-size-sm: clamp(0.875rem, 0.8462rem + 0.1282vw, 1rem); /* 16 */
    --font-size-base: clamp(1rem, 1rem + 0vw, 1rem); /* H6 16 */
    --font-size-md: clamp(1.125rem, 1.0962rem + 0.1282vw, 1.25rem); /* H5 20 */
    --font-size-lg: clamp(1.25rem, 1.1923rem + 0.2564vw, 1.5rem); /* H4 24 */
    --font-size-xl: clamp(1.5rem, 1.3846rem + 0.5128vw, 2rem); /* H3 32 */
    --font-size-xxl: clamp(2rem, 1.8846rem + 0.5128vw, 2.5rem); /* H2 40 */
    --font-size-xxxl: clamp(2.5rem, 2.1538rem + 1.5385vw, 4rem); /* H1 64 */
}

small {
    font-size: var(--font-size-sm);
}

h1, .h1 {
    font-size: var(--font-size-xxxl);
}

h2, .h2 {
    font-size: var(--font-size-xxl);
}

h3, .h3 {
    font-size: var(--font-size-xl);
}

h4, .h4 {
    font-size: var(--font-size-lg);
}

h5, .h5 {
    font-size: var(--font-size-md);
}

h6, .h6 {
    font-size: var(--font-size-base);
}


h2.title,
h3.title {
    font-size: var(--font-size-md);
}


/*
=======================================================================
    Body Font Size
=======================================================================
*/

:root {
    --bs-body-font-size: var(--font-size-base);
}


/*
=======================================================================
    Header Size
=======================================================================
*/

/*
:root {
    --h1-font-size: 2rem;

    @include media-breakpoint-up(md) {
        --h1-font-size: 3rem;
    }

    @include media-breakpoint-up(lg) {
        --h1-font-size: 3rem;
    }

    @include media-breakpoint-up(xl) {
        --h1-font-size: 3.5rem;
    }

    --h2-font-size: 1.875rem;

    @include media-breakpoint-up(md) {
        --h2-font-size: 2rem;
    }

    @include media-breakpoint-up(lg) {
        --h2-font-size: 2rem;
    }

    @include media-breakpoint-up(xl) {
        --h2-font-size: 2.25rem;
    }

    --h3-font-size: 1.875rem;

    @include media-breakpoint-up(md) {
        --h3-font-size: 2rem;
    }

    @include media-breakpoint-up(lg) {
        --h3-font-size: 2rem;
    }

    @include media-breakpoint-up(xl) {
        --h3-font-size: 2.25rem;
    }

    --h4-font-size: 1.5rem;

    @include media-breakpoint-up(md) {
        --h4-font-size: 1.5rem;
    }

    @include media-breakpoint-up(lg) {
        --h4-font-size: 1.5rem;
    }

    @include media-breakpoint-up(xl) {
        --h4-font-size: 1.675rem;
    }    
}
*/


/*
h1, .h1 {
    margin-top: 0;
    font-size: var(--h1-font-size);
    font-weight: 500;

    strong {
        font-weight:800;
    }
}

h2, .h2 {
    font-size: var(--h2-font-size);
    font-weight: 400;

    strong {
        font-weight:800;
    }
}

h3, .h3 {
    font-size: var(--h3-font-size);
    font-weight: 400;
}

h4, .h4 {
    font-size: var(--h4-font-size);
    font-weight: 400;
}

h5, .h5 {
    font-size: var(--h5-font-size);
    font-weight: 400;
}

h6, .h6 {
    font-size: var(--h6-font-size);
    font-weight: 400;
}
*/


/*
=======================================================================
    Text Size
=======================================================================
*/

.text--large {
    @include media-breakpoint-up(xl) {
        font-size: calc(1rem * 2.5)!important;
        line-height:1.45;
    }
}

.text--medium {
    @include media-breakpoint-up(xl) {
        font-size: calc(var(--bs-body-font-size) * 1.5);
    }
}


.text--small {
    font-size: 1rem;
}

@media screen and (min-width: 1200px) {
    .text--small {
        font-size: 1rem;
    }
}