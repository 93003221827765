$pluginName: "product-breadcrumbs";

// The text for the expand pseuodo-element.
$expand: "...";

// The text for the collapse pseudo-element.
// Set this to an empty string to suppress the collapse element when expanded.
$collapse: "\e404";  

// The text for the PIPE pseudo-element.
$pipe: "/";

// Set this to false to disable debug styles.
$debug: false;

.#{$pluginName} {
    box-sizing: border-box;
    width: 100%;
    white-space: nowrap;
    padding:0;
    list-style:none;
    margin-bottom:30px;
    > li {
        &:before {
            content: $pipe;
        }

        &:first-child {

            &:before {
                display: none;
            }
        }
    }
    li {
        display: inline-block;

        /*
        &:before {
          content: $pipe;
          display: inline-block;
          padding: 5px;
        }
        */

        &:first-child:before {
            display: none;
        }
    }

    &.#{$pluginName}_overflow {
        white-space: normal;

        &.#{$pluginName}_expanded {
            white-space: normal;
            flex-direction: column;

            li {
                display: inline-block
            }

            .#{$pluginName}__toggle {
                /*@if $collapse == '' {*/
                display: none;
                /*}*/
                a:before {
                    display: inline-block;
                    content: $collapse;
                }
            }
        }

        li {
            display: none;

            &:last-child,
            &:first-child,
            &.#{$pluginName}__toggle,
            &.last {
                display: inline-block;
            }
        }

        .#{$pluginName}__toggle{
            display: inline-block;
            a:before {
                content: $expand;
            }
        }
    }

    .#{$pluginName}__toggle {
        display: none;
    }
}