.modal {
    --bs-modal-header-border-width:0;
    --bs-modal-footer-border-width:0;
    
    &#modal__spinner {
        --bs-modal-border-color: unset;
        .modal-content {
            background-color: unset;
        }
    }
    
    .bttn--close {
        padding:.5rem 1rem;
        position:absolute;
        top:.5rem;
        right:.5rem;

        svg {
            width:15px;
            height:30px;
        }
    }

    .modal-body {
        .text {
            + form {
                margin-top: var(--margin-default);
            }
        }
    }
}