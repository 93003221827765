.block__blockquote {
    figure {
        margin: 0;

        blockquote {
            position: relative;
            font-size: calc(var(--bs-body-font-size)*1.25);

            &::before,
            &::after {
                font: var(--fa-font-sharp-solid);
                position: absolute;
                color: var(--color-main-accent-hex);
                font-weight: bold;

                @include media-breakpoint-up(xl) {
                    font-size: calc(var(--bs-body-font-size)*2);
                }
            }

            &::before {
                content: "\f10d";
                /*top: calc((var(--bs-body-font-size)*2)*-1);*/
                left: calc((var(--bs-body-font-size)*2)*-1);

                @include media-breakpoint-up(xl) {
                    left: calc((var(--bs-body-font-size)*3)*-1);
                }
            }
            /*
            &::after {
                content:"\f10e";
                bottom: calc((var(--bs-body-font-size)*2)*-1);
                right: calc((var(--bs-body-font-size)*2)*-1);
            } 
            */

            + figcaption {
                font-size: var(--bs-body-font-size);
            }
        }
    }
}
