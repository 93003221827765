header {
    &.site__header {
        height: var(--header-height);
        background-color: var(--header-background-color);
        /*border-bottom: 1px solid #f3f3f3;*/
        transition: background-color 0.3s ease;

        --header-theme-color: var(--color-main-hex);

        .container {
            height: 100%;
            position:relative;

            @include media-breakpoint-up(xxl) {
                /*max-width: var(--container-max-width);*/
                /*padding-right: calc(var(--bs-gutter-x)*2);*/
                /*padding-left: calc(var(--bs-gutter-x)*2);*/
            }

            .site__header__wrapper {
                height: var(--header-height);

            }            
        }

        .site__header__wrapper {
            display: grid;
            grid-template-columns: 5fr 2fr 5fr;
            align-items: center;
            height: 100%;

            nav {
                ul {
                    @include reset-ul;
                    display: flex;
                    gap: var(--header-spacer);
                    flex-direction: row;
                    align-items: center;
                    --header-element-height:2.5rem;

                    li {
                        @include reset-li;

                        a {
                            @include transition-color;
                            color:var(--header-theme-color);
                        }

                        a, button {
                            background-color:transparent;
                            font-size: var(--header-font-size);
                            font-weight:500;
                            color: var(--header-theme-color);
                            text-decoration: none;
                            border: 1px solid transparent;
                            line-height: var(--header-element-height);

                            &:hover,
                            &:focus-visible {
                                text-decoration: underline;
                            }
                        }

                        &.nav--hamburger {
                            --nav-hamburger-padding: 1.15rem;

                            button {
                                position:relative;
                                background: var(--color-secondary-accent-hex);
                                display:flex;
                                gap:.5rem;
                                border-radius:var(--header-element-height);
                                padding:0;
                                height:var(--header-element-height);
                                color: var(--color-main-hex);
                                align-items: center;

                                @include media-breakpoint-down(md) {
                                    width:var(--header-element-height);
                                }

                                svg {
                                    position: absolute;
                                    left:var(--nav-hamburger-padding);
                                    top: 50%;
                                    transform: translateY(-50%);

                                    @include media-breakpoint-down(md) {
                                        left:50%;
                                        transform: translate(-50%, -50%);
                                    }
                                }

                                span {
                                    padding-left: calc(var(--nav-hamburger-padding)*2.3);
                                    padding-right: var(--nav-hamburger-padding);

                                    @include media-breakpoint-down(md) {
                                        display:none;
                                    }
                                }

                                &:hover,
                                &:focus-visible {
                                    background: var(--color-secondary-accent-hover-hex);
                                    text-decoration: none;
                                }
                            }
                        }

                        &.nav--search {
                            position: relative;
                        }
                    }
                }
            }

            .site__header__left {
                nav {
                    ul {
                        li {
                            &.nav--search {
                                a {
                                    svg{
                                        width:16px;
                                        height:44px;
                                    }
                                }
                                
                                @include media-breakpoint-up(xl) {
                                    display:none;
                                }                                
                            }                       
                        }
                    }
                }
            }

            .site__header__right {
                
                height: 100%;
                /*
                display: flex;
                align-items: center;
                */

                nav {
                    float:right;
                    display: flex;
                    height: 100%;

                    ul {
                        li {

                            a {
                                display:flex;
                                
                                svg {
                                    stroke: var(--header-theme-color);
                                }
                            }

                            &.nav--favourites {
                                a {
                                    position:relative;

                                    span {
                                        position:absolute;
                                        left: -0.5rem;
                                        top: -0.5rem;
                                        line-height: 1;
                                        padding: 0.2rem;
                                        border-radius: 10rem;
                                        display: inline-block;
                                        width: 20px;
                                        height: 20px;
                                        line-height: 20px;
                                        font-size: .75rem;
                                        color: var(--bs-body-color);
                                        background: var(--color-secondary-accent-hex);
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                    }
                                }
                            }                            

                            &.nav--cart {
                                a {
                                    position:relative;

                                    span {
                                        display:none;
                                        position:absolute;
                                        left: -0.5rem;
                                        top: -0.5rem;
                                        line-height: 1;
                                        padding: 0.2rem;
                                        border-radius: 10rem;
                                        width: 20px;
                                        height: 20px;
                                        line-height: 20px;
                                        font-size: .75rem;
                                        color: var(--bs-body-color);
                                        background: var(--color-secondary-accent-hex);
                                        align-items: center;
                                        justify-content: center;
                                    }
                                }

                                &.active {
                                    span {
                                        display: flex;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .site__header__center__brand {
                --logo-width:120px;

                a {
                    min-width:var(--logo-width);
                    display:block;

                    svg {
                        width: 100%;
                        max-width:var(--logo-width);
                        height: auto;
                        display: block;
                        fill: #26276D;
                    }
                }
            }

            .site__header__search__mobile {
                width: 100%;
            }
        }
    }
}


/*
=======================================================================
	Header color theme
=======================================================================
*/

.top-of-page {
    .site__header {
        &.theme--light {
            --header-theme-color: var(--color-white-hex);

            .site__header__center__brand {
                a {
                    svg {
                        fill: var(--color-white-hex);
                    }
                }
            }
        }

        &.header--transparent {
            background-color:transparent;
        }
    }
}

.header-search--open {
    .top-of-page {
        .site__header {
            &.theme--light {
                --header-theme-color: var(--color-main-hex)!important;
            }
        }
    }
}


/*
=======================================================================
	Off Canvas - Navigation / Menu
=======================================================================
*/

.menuSlidein {
    display:flex;
    flex-direction: column;

    .offcanvas-header {
        padding-inline: var(--margin-default);
        height: var(--header-height);
        display: flex;
        justify-content: space-between;
        align-items: center;

        nav {
            ul {
                @include reset-ul;
                display: flex;
                gap: .5rem;
                flex-direction: row;
                align-items: center;

                @include media-breakpoint-up(sm) {
                    gap: var(--spacer-3);
                }

                li {
                    @include reset-li;

                    a, button {
                        position:relative;
                        font-size: 1rem;
                        text-decoration: none;
                        border: 1px solid transparent;
                        line-height: normal;

                        --padding-button-vertical: .5rem;
                        --padding-button-horizontal: 1rem;
                    }

                    &.nav--close {
                        button {
                            background: none;
                            padding: 0 0 0 2rem;
                            position:relative;

                            &::before {
                                position:absolute;
                                top:60%;
                                transform:translateY(-50%);
                                left:0;
                                content:url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20 4L4 20M20 20L4 4' stroke='%230D2240' stroke-width='1.5' stroke-linecap='round'/%3E%3C/svg%3E%0A");
                            }

                            &:hover,
                            &:focus,
                            &:focus-visible {
                                text-decoration: underline;
                            }
                        }
                    }

                    &.nav--back {
                        button {
                            background: none;
                            font-size: 1.5rem;
                            font-weight: 500;
                            display: flex;
                            align-items: center;
                            gap: 0.5rem;

                            &::before {
                                content:url("data:image/svg+xml,%3Csvg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.3333 19.8335L10.5 14.0002L16.3333 8.16683' stroke='%230D2240' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
                                width: 1.75rem;
                                height: 1.75rem;
                            }

                            &:hover,
                            &:focus,
                            &:focus-visible {
                                text-decoration: underline;
                            }
                        }
                    }                        
                }
            }
        }
    }

    .offcanvas-body {
        display: flex;
        flex-direction: column;
        gap: calc(var(--spacer-5)*2);
        padding-inline:var(--margin-default);

        nav {
            ul {
                @include reset-ul;
                display:flex;
                flex-direction: column;
                gap:.2rem;

                li {
                    @include reset-li;

                    a {
                        font-size:1rem;
                        text-decoration: none;
                        
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
}


/*
=======================================================================
	Off Canvas - Main Menu and Submenu
=======================================================================
*/

.menuSlidein--common {
    .offcanvas-body {
        .offcanvas-body__section {
            display:flex;
            gap:var(--margin-default);

            @include media-breakpoint-down(md) {
                gap:.2rem;
            }

            /* Navigation */
            &:first-child {
                @include media-breakpoint-down(md) {
                    flex-direction: column;
                    order: 2;
                }
            }

            /* Campaign Banner */
            &:last-child {
                > a  {
                    position: relative;
                    width: 100%;
                    overflow: hidden;
                    border-radius: var(--border-radius-default);
                }

                @include media-breakpoint-down(md) {
                    order: 1;
                }
            }
            
            .offcanvas-body__section__col {
                display: flex;
                flex-direction: column;
                flex: 1;
                gap: var(--margin-default);
                position: relative;

                &:last-child {

                    // @include media-breakpoint-down (md) {
                    //     display:none;
                    // }

                    @include media-breakpoint-up (md) {
                        padding-left:var(--margin-default);

                        &::before {
                            content:"";
                            display:block;
                            width:1px;
                            height:100%;
                            background-color: #C4C6CA;
                            position:absolute;
                            top:0;
                            left:0;
                        }
                    }
                }

                nav {
                    &.nav-one {
                        ul {
                            gap: 0;
                            
                            > li {
                                transition: background-color 0.2s ease; 
                                border-radius: 0.5rem;
                                padding: 0.5rem 1rem;

                                &:hover, &.hover {
                                    background: #f7f7f9;
                                    
                                    a::after {
                                        opacity: 1;
                                        transition: opacity 0.1s ease;
                                    }
                                }
                                
                                a {
                                    font-size: 1.5rem;
                                    font-weight: 500;
                                    position: relative;
                                    display: flex;
                                    align-items: center;
                                    gap: 0.5rem;

                                    // &:hover {
                                    //     text-decoration: none;
                                    // }
                                }

                                &.has-ul {
                                    > a {
                                        &::after {
                                            width: .75rem;
                                            // Angle right
                                            content:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3C!--!Font Awesome Pro 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--%3E%3Cpath d='M273 239c9.4 9.4 9.4 24.6 0 33.9L113 433c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l143-143L79 113c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L273 239z'/%3E%3C/svg%3E");
                                        }

                                        @include media-breakpoint-down(md) {
                                            
                                            &::after {
                                                // Angle down
                                                content:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3C!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--%3E%3Cpath d='M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z'/%3E%3C/svg%3E");
                                            }

                                            &.active {    
                                                &::after {
                                                    // Angle up
                                                    content:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3C!--!Font Awesome Pro 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--%3E%3Cpath d='M207 143c9.4-9.4 24.6-9.4 33.9 0L401 303c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-143-143L81 337c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9L207 143z'/%3E%3C/svg%3E");
                                                }

                                                + ul {
                                                    display:block;
                                                }
                                            }
                                        }
                                    }
                                }

                                ul {
                                    padding-left:1rem;
                                    position:relative;
                                    display:none;

                                    &::before {
                                        content:"";
                                        width:2px;
                                        background-color: #e8e8e8;
                                        position:absolute;
                                        top:.4rem;
                                        left:0rem;
                                        bottom:.3rem;
                                        
                                    }


                                    li {
                                        padding:0;
                                        
                                        a {
                                            font-size:1rem;
                                            padding:0;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    &.nav-four {
                        &.hover {
                            display:block!important;
                        }
                    }
                }
            }
        }
    }
}


/*
=======================================================================
	Off Canvas - Main Menu
=======================================================================
*/

.menuSlidein--mainMenu {
    padding-block:initial;
    gap:0;

    @include media-breakpoint-down(md) {
        bottom:0;
    }

    .offcanvas-header {
        display:flex;
        flex-wrap:wrap;
        gap:1rem;
        justify-content: space-between;

        nav {
            display:flex;
            justify-content: space-between;

            @include media-breakpoint-down(md) {
                &:last-child {
                    position:absolute;
                    bottom:0;
                    left:var(--margin-default);
                    right:var(--margin-default);
                    padding: 0 0 var(--margin-default) 0;
                    background-color: var(--color-white-hex);
                    z-index: 1;

                    ul {
                        @include media-breakpoint-down(md) {
                            width:100%;
                        }

                        li {
                            flex:1;

                            a {
                                width:100%;
                            }
                        }
                    }
                }
            }

            ul {
                @include reset-ul;

                li {
                    @include reset-li;

                    &.nav--findDealer {
                        a {
                            background-color: #f7f7f9;
                            border-radius:10rem;
                            padding:.5rem 1rem;
                        }
                    }

                    &.nav--account {
                        a {
                            background-color: #f7f7f9;
                            border-radius:10rem;
                            padding:.5rem 1rem;
                        }
                    }

                    &.nav--contact {
                        a {
                            background-color: #BCCACF4D;

                            &:hover,
                            &:focus,
                            &:focus-visible {
                                background-color: #a6b5bb4d;
                            }
                        }
                    }

                    &.nav--login {
                        a {
                            span {
                                position:relative;
                                padding-left:1.5rem;

                                &::before {
                                    position:absolute;
                                    left:0rem;
                                    top:-4%;
                                    content:url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 6C13 7.65685 11.6569 9 10 9C8.34315 9 7 7.65685 7 6C7 4.34315 8.34315 3 10 3C11.6569 3 13 4.34315 13 6Z' stroke='%230D2240' stroke-width='1.5'/%3E%3Cpath d='M4 17.1765C4 14.3176 5.97143 12 10 12C14.0286 12 16 14.3176 16 17.1765C16 17.6313 15.7261 18 15.3882 18H4.61176C4.2739 18 4 17.6313 4 17.1765Z' stroke='%230D2240' stroke-width='1.5'/%3E%3C/svg%3E");
                                }
                            }

                            &.bttn--logOut {
                                border-color: var(--button-border-color);

                                &:hover,
                                &:focus,
                                &:focus-visible {

                                    span {
                                        &::before {
                                            position:absolute;
                                            left:0rem;
                                            top:-4%;
                                            content:url("data:image/svg+xml,%3Csvg width=%2720%27 height=%2720%27 viewBox=%270 0 20 20%27 fill=%27none%27 xmlns=%27http://www.w3.org/2000/svg%27%3E%3Cpath d=%27M13 6C13 7.65685 11.6569 9 10 9C8.34315 9 7 7.65685 7 6C7 4.34315 8.34315 3 10 3C11.6569 3 13 4.34315 13 6Z%27 stroke=%27%23FFFFFF%27 stroke-width=%271.5%27/%3E%3Cpath d=%27M4 17.1765C4 14.3176 5.97143 12 10 12C14.0286 12 16 14.3176 16 17.1765C16 17.6313 15.7261 18 15.3882 18H4.61176C4.2739 18 4 17.6313 4 17.1765Z%27 stroke=%27%23FFFFFF%27 stroke-width=%271.5%27/%3E%3C/svg%3E");
                                        }   
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    } 

    .offcanvas-body {
        --bs-offcanvas-padding-x: var(--margin-default);
        --bs-offcanvas-padding-y: var(--margin-default) !important;
        padding-top:0;
        gap:var(--margin-default);

        @include media-breakpoint-down(md) {
            margin-bottom: 4rem;
        }

        .offcanvas-body__section {
            .offcanvas-body__section__col {
                nav {
                    &.nav-two {

                        @include media-breakpoint-down(md) {
                            position:relative;
                            padding-top: var(--margin-default);

                            &::before {
                                content:"";
                                display:block;
                                width:100%;
                                height:1px;
                                background-color: #C4C6CA;
                                position:absolute;
                                top:0;
                                left:0;
                            }
                        }

                        ul {
                            li {
                                a {
                                    font-weight:500;
                                }
                            }
                        }
                    }

                    &.nav-three {
                        
                        @include media-breakpoint-down(md) {
                            order:2
                        }

                        ul {
                            li {
                                a {
                                    padding-left:1.65rem;
                                    position:relative;
                                    line-height:normal;
                                    font-weight:500;

                                    &::before {
                                        position: absolute;
                                        left:0;
                                        top:50%;
                                        transform:translateY(-50%);
                                    }
                                }

                                &.nav--icon-user {
                                    a {
                                        &::before {
                                            content:url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 6C13 7.65685 11.6569 9 10 9C8.34315 9 7 7.65685 7 6C7 4.34315 8.34315 3 10 3C11.6569 3 13 4.34315 13 6Z' stroke='%230D2240' stroke-width='1.5'/%3E%3Cpath d='M4 17.1765C4 14.3176 5.97143 12 10 12C14.0286 12 16 14.3176 16 17.1765C16 17.6313 15.7261 18 15.3882 18H4.61176C4.2739 18 4 17.6313 4 17.1765Z' stroke='%230D2240' stroke-width='1.5'/%3E%3C/svg%3E%0A");
                                        }
                                    }
                                }

                                &.nav--icon-question {
                                    a {
                                        &::before {
                                            content:url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.99919 13.75V13.783M8.125 7.69166C8.125 6.63657 8.96447 5.78125 10 5.78125C11.0355 5.78125 11.875 6.63657 11.875 7.69166C11.875 8.74675 11.0355 9.60207 10 9.60207C10 9.60207 9.99919 10.1723 9.99919 10.8757M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z' stroke='%230D2240' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
                                        }
                                    }
                                }

                                &.nav--icon-location {
                                    a {
                                        &::before {
                                            content:url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.99915 18C9.99915 18 16.26 12.4348 16.26 8.26087C16.26 4.80309 13.4569 2 9.99915 2C6.54137 2 3.73828 4.80309 3.73828 8.26087C3.73828 12.4348 9.99915 18 9.99915 18Z' stroke='%230D2240' stroke-width='1.5'/%3E%3Cpath d='M11.9994 8.00013C11.9994 9.1047 11.104 10.0001 9.99941 10.0001C8.89484 10.0001 7.99941 9.1047 7.99941 8.00013C7.99941 6.89556 8.89484 6.00013 9.99941 6.00013C11.104 6.00013 11.9994 6.89556 11.9994 8.00013Z' stroke='%230D2240' stroke-width='1.5'/%3E%3C/svg%3E%0A");
                                        }
                                    }
                                }
                            }
                        }
                    }

                    &.nav-four {
                        @include media-breakpoint-down(md) {
                            order:1
                        }

                        ul {
                            li {
                                a {
                                    font-weight:400;
                                }

                                ul {
                                    display: flex;
                                    flex-direction: column;
                                    gap: 0.2rem;
                                    margin-top: 0.2rem;
                                }
                            }
                        }

                        @include media-breakpoint-down (md) {
                            > ul {
                                > li {
                                    > a {
                                        position:relative;
                                        font-weight:500;

                                        &::after {                                                
                                            position:absolute;
                                            top:5%;
                                            right:-1.5rem;
                                            content:url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.168 8.33333L10.0013 12.5L5.83463 8.33333' stroke='%230D2240' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");;
                                            @include transition-transform;
                                        }

                                        &.active {

                                            &::after {
                                                transform: rotate(180deg);
                                                top: -25%;
                                            }

                                            + ul {
                                                display:block;
                                            }
                                        }
                                    }

                                    > ul {
                                        display:none;
                                    }
                                }
                            }
                        }

                        @include media-breakpoint-up (md) {
                            > ul {
                                > li {
                                    > a {
                                        display:none;
                                    }
                                }
                            }

                            &.hover {
                                display:block!important;
                            }
                        }
                    }
                }
            }
        }
    }
}


/*
=======================================================================
	Off Canvas - Sub Menu
=======================================================================
*/

.menuSlidein--subMenu {
    .offcanvas-header {
        nav {
            ul {
                gap:0;
            }
        }
    }

    .offcanvas-body {
        padding-top:0;

        .row {
            --bs-gutter-x:1rem;
            --bs-gutter-y:1rem;

            .listelement {
                a {
                    display: flex;
                    gap: 0.75rem;
                    flex-direction: column;
                    text-decoration: none;

                    &:hover,
                    &:focus,
                    &:focus-visible {
                        text-decoration: underline;
                    }

                    .listelement__media {
                        img {
                            width:100%;
                            border-radius:.75rem;
                            aspect-ratio:16/9;
                            object-fit:cover;
                        }
                    }

                    .listelement__body {
                        h3 {
                            font-size:1rem;
                            font-weight:500;
                        }
                    }
                }
            }
        }


        @include media-breakpoint-up (md) {
            .offcanvas-body__section__col {
                &.hover {
                    .nav-four {
                        &.hover {
                            display:block!important;
                        }

                        &:not(.hover) {
                            display:none!important;
                        }
                    }
                }
            }
        }        

    }
}



/*
=======================================================================
	other styles for slideins
=======================================================================
*/

/* (Commented code below in an attempt to simplify */

/*
.offcanvas {    
    &.custom-offcanvas {
        .offcanvas-header {
            padding-right: calc(var(--margin-default) + var(--spacer-3) * 2);

            nav {
                display:flex;
                justify-content: space-between;
                width:100%;

                ul {
                    li {
                        &.nav--close {
                            button {
                                &::before {
                                    @include fontawesome;
                                    content:"\f060";
                                }
                            }
                        }

                        &.nav--header {
                            font-size:2rem;
                        }

                        &.nav--all {
                            float:right;
                        }
                    }
                }
            }
        }

        &.offcanvas-default {
            .offcanvas-body {
                nav {
                    ul {
                        gap:1rem;
                    }
                }
            }   
        }

        &.offcanvas-style1 {
            .offcanvas-body {
                .row {

                    --bs-gutter-x:1rem;
                    --bs-gutter-y:1rem;

                    .listelement {

                        a {
                            display: flex;
                            gap:1rem;
                            flex-direction: column;
                            padding: var(--spacer-3);
                            justify-content:center;
                            text-decoration: none;
                            height:100%;

                            .listelement__icon {
                                max-width:100px;
                                margin-inline: auto;

                                img {
                                    margin-inline: auto;
                                }
                            }

                            .listelement__body {
                                padding:0;
                                text-align: center;
                                background-color:transparent;

                                .title {
                                    font-size: var(--bs-body-font-size);
                                    margin:0;

                                    @include media-breakpoint-down(md) {
                                        font-size: calc(var(--bs-body-font-size)*.8);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &.offcanvas-style2 {
            .offcanvas-body {

                .listelement {

                    a {
                        display: flex;
                        gap:1rem;
                        flex-direction: column;
                        justify-content:center;
                        text-decoration: none;
                        height:100%;

                        .listelement__icon {

                            img {
                                margin-inline: auto;
                                aspect-ratio: 4 / 3;
                                object-fit: cover;
                                width:100%;
                                height:100%;
                            }
                        }

                        .listelement__body {
                            padding:0;
                            background-color:transparent;

                            .title {
                                font-size: var(--bs-body-font-size);
                                margin:0;

                                @include media-breakpoint-down(md) {
                                    font-size: calc(var(--bs-body-font-size)*.8);
                                }
                            }
                        }
                    }
                }
            }
        }
           
    }  
}
*/

/*
=======================================================================
	Header Search Global
=======================================================================
*/

.nav--search {

    .bttn--close {
        z-index: 45;
        background: none;
        border: 0;
        display:none;
        svg{
            width:14px;
            height:20px;
        }
    }

    .search {
        display: table;
        border-bottom: 2px solid var(--header-nav-border-color);
        background-color: #FAFAFC;
        border-radius:5rem;
        position: relative;
        z-index: 25;
        /*transition: all 0.3s ease;*/

        /* When Search Dropdown is Open */
        @at-root .header-search--open {

            body {
                overflow:hidden!important;
            }

            .site__header__wrapper {
                position:relative;

                &::after {
                    content:"";
                    position:fixed;
                    top:0;
                    left:0;
                    width:100%;
                    height: var(--header-height);
                    background:#fff;
                    z-index: 21;
                    opacity:.9;
                }

                .nav--search {
                    position:initial!important;

                    .search {
                        /*
                        position:absolute!important;
                        top:50%;
                        left:0;
                        transform: translateY(-50%);
                        */
                        width:100%;
                        margin-inline:0;       
                    }                    
                }

                .bttn--close {
                    display:initial;
                    position: absolute;
                    top: 50%;
                    left:0;
                    transform: translateY(-50%);
                }
            }
        }

        > * {
            display: table-cell;
        }

        .search__input {

            input {
                background: transparent;
                height:48px;
                width:100%;
                /*min-width: 300px;*/
                font-size: var(--header-font-size);
                position: relative;
                z-index: 25;
                border: 0;
                padding-left:3.5rem;
                padding-right:1.5rem;

                @media (min-width:1600px) {
                    min-width: 400px;
                }

                @include placeholder {
                    color: rgba(0 0 0 / 75%);
                }

                &:focus-visible {
                    outline: none;
                    border-bottom-color: #000;
                }
            }

            &::before {
                position:absolute;
                top:50%;
                left:1.5rem;
                transform:translateY(-50%);
                content:url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.9284 17.0416L20.4016 20.4016M19.2816 11.4416C19.2816 15.7715 15.7715 19.2816 11.4416 19.2816C7.11165 19.2816 3.60156 15.7715 3.60156 11.4416C3.60156 7.11165 7.11165 3.60156 11.4416 3.60156C15.7715 3.60156 19.2816 7.11165 19.2816 11.4416Z' stroke='%230D2240' stroke-width='1.5' stroke-linecap='round'/%3E%3C/svg%3E");
                line-height:1;
            }
        }

        .search__button {
            padding-right:var(--header-spacer);
            width:50px;
            
            button {
                background: transparent;
                width: 100%;
                color: var(--color-main-hex);
            }
        }
    }

    .search__dropdown {
        display: none;

        @at-root .header-search--open {

            .site__header {
                border-bottom: 0 !important;
            }

            .search__dropdown {
                display: block;
                position: fixed;
                top: 0;
                left: 1rem;
                z-index: 20;
                background: #fff;
                width: 100%;
                transform: translate(-1rem);
                padding: var(--header-height) 1rem 2rem 1rem;
                max-height: 100%;
                overflow-y: auto;
                font-size:1rem;

                .container {
                    /*
                    display: flex !important;
                    flex-direction: column;
                    gap: 1.5rem;
                    */

                    .title {
                        /*margin-bottom: .5rem;*/
                    }

                    
                    /* New styling added 22.02.2024 */
                    .search__dropdown__cat {

                        + div {
                            margin-top:2.75rem;
                        }

                        .search__dropdown__cat__title {
                            a {
                                font-family: 'Wix Madefor Display', sans-serif;
                                font-size:1.25rem;
                                font-weight:700;
                                text-decoration: underline;
                            }
                        }

                        ul {
                            @include reset-ul;
                            margin-top:.75rem;
                            display:flex;
                            flex-direction:column;
                            gap:1rem;
                            align-items:flex-start;

                            li {
                                @include reset-li;

                                a {
                                    display:flex;
                                    gap:1rem;
                                    align-items:center;
                                    line-height:initial;

                                    
                                    &:hover,
                                    &:focus,
                                    &:focus-visible {
                                        text-decoration: none;

                                        .search__dropdown__cat__item__meta__title {
                                            text-decoration: underline;
                                        }
                                    }

                                    .search__dropdown__cat__item__media {
                                        width:3.5rem;
                                        
                                        figure {
                                            margin:0;
                                            
                                            img {
                                                width:100%;
                                                border-radius:1rem;
                                            }
                                        }
                                    }

                                    .search__dropdown__cat__item__meta {
                                        flex:1;
                                        display:flex;
                                        flex-direction: column;

                                        .search__dropdown__cat__item__meta__breadcrumb {
                                            font-size:.75rem;
                                            font-weight:400;
                                        }
                                        
                                        .search__dropdown__cat__item__meta__title {
                                            font-size:1rem;
                                            font-weight:700;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .search__dropdown__prod {

                        + div {
                            margin-top:2.75rem;
                        }

                        .search__dropdown__prod__title {
                            a {
                                font-family: 'Wix Madefor Display', sans-serif;
                                font-size:1.25rem;
                                font-weight:700;
                                text-decoration: underline;
                            }
                        }

                        ul {
                            @include reset-ul;
                            margin-top:.75rem;
                            display:flex;
                            flex-direction:column;
                            gap:1rem;
                            align-items:flex-start;

                            li {
                                @include reset-li;

                                a {
                                    display:flex;
                                    gap:1rem;
                                    align-items:center;
                                    line-height:initial;

                                    
                                    &:hover,
                                    &:focus,
                                    &:focus-visible {
                                        text-decoration: none;

                                        .search__dropdown__prod__item__meta__title {
                                            text-decoration: underline;
                                        }
                                    }

                                    .search__dropdown__prod__item__media {
                                        width:3.5rem;
                                        
                                        figure {
                                            margin:0;

                                            img {
                                                width:100%;
                                                border-radius:1rem;
                                            }
                                        }
                                    }

                                    .search__dropdown__prod__item__meta {
                                        flex:1;
                                        display:flex;
                                        flex-direction: column;
                                        
                                        .search__dropdown__prod__item__meta__title {
                                            font-size:1rem;
                                            font-weight:700;
                                        }

                                        .search__dropdown__prod__item__meta__teaser {
                                            font-size:.75rem;
                                            font-weight:400;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .search__dropdown__employee {

                        + div {
                            margin-top:2.75rem;
                        }

                        .search__dropdown__employee__title {
                            a {
                                font-family: 'Wix Madefor Display', sans-serif;
                                font-size:1.25rem;
                                font-weight:700;
                                text-decoration: underline;
                            }
                        }

                        ul {
                            @include reset-ul;
                            margin-top:.75rem;
                            display:flex;
                            flex-direction:column;
                            gap:1rem;
                            align-items:flex-start;

                            li {
                                @include reset-li;
                                display:flex;
                                gap:1rem;
                                align-items:center;
                                line-height:initial;

                                .search__dropdown__employee__item__media {
                                    width:3.5rem;
                                    
                                    figure {
                                        margin:0;

                                        img {
                                            width:100%;
                                            border-radius:1rem;
                                        }
                                    }
                                }

                                .search__dropdown__employee__item__meta {
                                    flex:1;
                                    display:flex;
                                    flex-wrap:wrap;
                                    gap:1rem;
                                    
                                    .search__dropdown__employee__item__meta__name-and-position {
                                        width:10rem;

                                        .search__dropdown__employee__item__meta__name {
                                            font-size:1rem;
                                            font-weight:700;
                                        }

                                        .search__dropdown__employee__item__meta__position {
                                            font-size:.75rem;
                                            font-weight:400;
                                        }
                                    }

                                    .search__dropdown__employee__item__meta__phone {
                                        width:10rem;

                                        a {
                                            svg{
                                                margin-right:.5rem;
                                                width:16px;
                                                height:40px;
                                            }
                                        }
                                    }

                                    .search__dropdown__employee__item__meta__email {
                                        width:10rem;

                                        a {
                                            svg{
                                                margin-right:.5rem;
                                                width:16px;
                                                height:40px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .search__dropdown__dep {

                        + div {
                            margin-top:2.75rem;
                        }

                        .search__dropdown__dep__title {
                            a {
                                font-family: 'Wix Madefor Display', sans-serif;
                                font-size:1.25rem;
                                font-weight:700;
                                text-decoration: underline;
                            }
                        }

                        ul {
                            @include reset-ul;
                            margin-top:.75rem;
                            display:flex;
                            flex-direction:column;
                            gap:1rem;
                            align-items:flex-start;

                            li {
                                @include reset-li;
                                
                                a {
                                    display: flex;
                                    gap: 1rem;
                                    align-items: center;
                                    line-height: initial;

                                    &:hover,
                                    &:focus,
                                    &:focus-visible {
                                        text-decoration: none;

                                        .search__dropdown__dep__item__meta__depname {
                                            text-decoration: underline;
                                        }
                                    }
                                }

                                .search__dropdown__dep__item__media {
                                    width:3.5rem;
                                    height:3.5rem;
                                    border-radius:3.5rem;
                                    background-color: #f7f7f7;
                                    position:relative;

                                    svg {
                                        width:12px;
                                        height:19px;
                                        position:absolute;
                                        top:50%;
                                        left:50%;
                                        transform:translate(-50%, -50%);
                                    }
                                }

                                .search__dropdown__dep__item__meta {
                                    flex:1;
                                    display:flex;
                                    flex-direction: column;

                                    .search__dropdown__dep__item__meta__type {
                                        font-size:.75rem;
                                        font-weight:400;
                                    }
                                    
                                    .search__dropdown__dep__item__meta__depname {
                                        font-size:1rem;
                                        font-weight:700;
                                    }
                                }
                            }
                        }
                    }

                    .search__dropdown__page {

                        + div {
                            margin-top:2.75rem;
                        }

                        .search__dropdown__page__title {
                            a {
                                font-family: 'Wix Madefor Display', sans-serif;
                                font-size:1.25rem;
                                font-weight:700;
                                text-decoration: underline;
                            }
                        }

                        ul {
                            @include reset-ul;
                            margin-top:.75rem;
                            display:flex;
                            flex-direction:column;
                            gap:1rem;
                            align-items:flex-start;

                            li {
                                @include reset-li;

                                a {
                                    display:flex;
                                    gap:1rem;
                                    align-items:center;
                                    line-height:initial;

                                    
                                    &:hover,
                                    &:focus,
                                    &:focus-visible {
                                        text-decoration: none;

                                        .search__dropdown__page__item__meta__title {
                                            text-decoration: underline;
                                        }
                                    }

                                    .search__dropdown__page__item__media {
                                        width:7rem;
                                        
                                        figure {
                                            margin:0;

                                            img {
                                                width:100%;
                                                border-radius:1rem;
                                            }
                                        }
                                    }

                                    .search__dropdown__page__item__meta {
                                        flex:1;
                                        display:flex;
                                        flex-direction: column;

                                        .search__dropdown__page__item__meta__breadcrumb {
                                            font-size:.75rem;
                                            font-weight:400;
                                        }
                                        
                                        .search__dropdown__page__item__meta__title {
                                            font-size:1rem;
                                            font-weight:700;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .search__dropdown__content {

                        + div {
                            margin-top:2.75rem;
                        }

                        .search__dropdown__content__title {
                            a {
                                font-family: 'Wix Madefor Display', sans-serif;
                                font-size:1.25rem;
                                font-weight:700;
                                text-decoration: underline;
                            }
                        }

                        ul {
                            @include reset-ul;
                            margin-top:.75rem;
                            display:flex;
                            flex-direction:column;
                            gap:1rem;
                            align-items:flex-start;

                            li {
                                @include reset-li;

                                a {
                                    display:flex;
                                    gap:1rem;
                                    align-items:center;
                                    line-height:initial;

                                    
                                    &:hover,
                                    &:focus,
                                    &:focus-visible {
                                        text-decoration: none;

                                        .search__dropdown__content__item__meta__title {
                                            text-decoration: underline;
                                        }
                                    }

                                    .search__dropdown__content__item__media {
                                        width:7rem;
                                        
                                        figure {
                                            margin:0;

                                            img {
                                                width:100%;
                                                border-radius:1rem;
                                            }
                                        }
                                    }

                                    .search__dropdown__content__item__meta {
                                        flex:1;
                                        display:flex;
                                        flex-direction: column;

                                        .search__dropdown__content__item__meta__type {
                                            font-size:.75rem;
                                            font-weight:400;
                                        }
                                        
                                        .search__dropdown__content__item__meta__title {
                                            font-size:1rem;
                                            font-weight:700;
                                        }
                                    }
                                }
                            }
                        }
                    }


                    /* This version of categories styling is now obsolete 22.02.2024 */
                    .search__dropdown__categories {
                        .swiper {
                            &::after {
                                content: "";
                                width: 50px;
                                height: 100%;
                                display: block;
                                position: absolute;
                                top: 0;
                                right: 0;
                                bottom: 0;
                                z-index: 35;
                                background: rgb(255,255,255);
                                background: linear-gradient(275deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
                            }

                            a {
                                padding: 0;
                            }
                        }
                    }

                    .search__dropdown__searchHistory {

                        ~ .search__dropdown__popularSearches {
                            /*
                            border-top:1px solid var(--color-main-accent-hex);
                            margin-top: 1.5rem;
                            */
                            padding-top:1.5rem;
                        }

                        .search__dropdown__searchHistory__header {
                            display: flex;
                            gap: .5rem;
                            align-items:center;

                            a {
                                text-decoration: underline;
                            }
                        }

                        .search__dropdown__searchHistory__body {
                            ul {
                                @include reset-ul;
                                display: flex;
                                flex-direction: column;
                                gap: .5rem;
                                align-items: flex-start;

                                li.listelement {
                                    @include reset-li;

                                    a {
                                        display: grid;
                                        grid-template-columns: 50px 1fr;
                                        gap:1rem;

                                        &:hover,
                                        &:focus-visible {
                                            text-decoration: none;
                                        }

                                        .listelement__media {
                                            position:relative;
                                            aspect-ratio: 1 / 1;

                                            &::after {
                                                @include image-overlay;
                                            }
                                        }

                                        .brand {
                                            text-transform: uppercase;
                                        }

                                        .title {
                                            @include line-clamp;
                                            --line-clamp: 1;
                                            margin: 0;
                                        }

                                        .description {
                                            @include line-clamp;
                                            --line-clamp: 1;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .sep {
                        display: block;
                        background-color: var(--color-main-accent-hex);
                        height: 1px;
                    }

                    .search__dropdown__popularSearches {

                        ~ .search__dropdown__autoSuggest {
                            padding-top:1.5rem;
                        }

                        ul {
                            @include reset-ul;
                            display: flex;
                            flex-direction: column;
                            gap: .5rem;
                            align-items: flex-start;

                            li.listelement {
                                @include reset-li;
                                display: block;
                                width: 100%;

                                a {
                                    display: block;
                                    padding: .25rem;
                                    svg{
                                        width:16px;
                                        height:40px;
                                        margin-right: .5rem;
                                    }
                                }
                            }
                        }
                    }

                    .search__dropdown__autoSuggest {

                        ul {
                            @include reset-ul;
                            display: flex;
                            flex-direction: column;
                            gap: .5rem;
                            align-items: flex-start;

                            li {
                                @include reset-li;
                                display: block;
                                width: 100%;

                                a {
                                    display: block;
                                    padding: .25rem;
                                }

                                &.type--search-term {
                                    a {
                                        &::before {
                                            @include fontawesome;
                                            content: "\f002";
                                            margin-right: .5rem;
                                        }
                                    }
                                }

                                &.type--category {
                                    a {
                                        &::before {
                                            @include fontawesome;
                                            content: "\e196";
                                            margin-right: .5rem;
                                        }
                                    }
                                }

                                &.type--product {
                                    a {
                                        display: grid;
                                        grid-template-columns: 50px 1fr;
                                        gap:1rem;

                                        .listelement__media {
                                            position:relative;
                                            aspect-ratio: 1 / 1;

                                            &::after {
                                                @include image-overlay;
                                            }
                                        }                                        

                                        &:hover,
                                        &:focus-visible {
                                            text-decoration: none;
                                        }

                                        .brand {
                                            text-transform: uppercase;
                                        }

                                        .title {
                                            @include line-clamp;
                                            --line-clamp: 1;
                                            margin: 0;
                                        }
                                    }
                                }

                                /*
                                &.type--producer {
                                    a {
                                        &::before {
                                            @include fontawesome;
                                            content: "\f1f9";
                                            margin-right: .5rem;
                                        }
                                    }
                                } 
                                */                               
                            }
                        }
                    }
                }
            }
        }
    }
}

/*
=======================================================================
	Header Search Desktop
=======================================================================
*/

@include media-breakpoint-up(xl) {
    .nav--search {
        display: flex;
        align-items: center;
        height: 100%;

        .nav--search__wrapper {
            display: flex;
            align-items: center;
            height: 100%;

            .search {
                .search__input {

                    input {
                        background: transparent;
                        min-width: 300px;
                        font-size: var(--header-font-size);
                        position: relative;
                        z-index: 25;

                        @media (min-width:1600px) {
                            min-width: 400px;
                        }

                        @include placeholder {
                            color: rgba(0 0 0 / 75%);
                        }

                        &:focus-visible {
                            outline: none;
                            border-bottom-color: #000;
                        }
                    }
                }
            }

            @at-root .header-search--open {
                .nav--search {
                    
                    .nav--search__wrapper {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        padding-left:50px;
                    }

                    .search__dropdown {
                        border-bottom-left-radius: var(--border-radius-default);
                        border-bottom-right-radius: var(--border-radius-default);
                    }
                }
            }
        }
    }
}


/*
=======================================================================
	Header Search Mobile
=======================================================================
*/

@include media-breakpoint-down(xl) {
    .nav--search {
        position: initial !important;

        .nav--search__wrapper {
            display:none;
        
            .search {
                display: none;
                width: -moz-available;
                width: -webkit-fill-available;
                margin-inline: calc(var(--bs-gutter-x) * 0.5);

                input {
                    width: 100%;
                }
            }
        }
    }

    @at-root .header-search--open {

        .nav--search {
            .bttn--close {
                display: block;
            }
        }

        .nav--search {
            .nav--search__wrapper {
                
                /* Removed 13/10 */
                /*padding-inline: calc(var(--bs-gutter-x) * 0.5);*/
                
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                padding-left:50px;

                /* Added 13/10 */
                height: var(--header-height);
                display: flex;
                align-items: center;

                .search {
                    display: table;
                    width: 100%;
                    margin: 0;
                    margin-inline: auto;
                }

                .search__dropdown {
                    position: fixed;
                    padding: var(--header-height) 1rem 1rem 1rem;
                    height: 100%;

                    .search__dropdown__wrapper {
                        height: 100%;
                        margin-inline: auto;
                        overflow-y: auto;
                    }
                }
            }
        }
    }
}


/*
=======================================================================
	Main Header Dropdown Overlay
=======================================================================
*/

main,
footer {
    &::after {
        @include transition--slow;
        content: "";
        background: #000;
        opacity: 0;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        pointer-events: none;
    }
}

html[class*="overlay"] {
    main,
    footer {
        &::after {
            opacity: .65;
        }
    }
}


/*
=======================================================================
	Main Header Search Overlay
=======================================================================
*/

header,
main,
footer {
    &::after {
        @include transition--slow;
        content: "";
        background: #000;
        opacity: 0;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        pointer-events: none;
    }
}


html[class*="overlay"] {
    header,
    main,
    footer {
        &::after {
            opacity: .65;
        }
    }
}

/* No need for overlay on small devices */

@include media-breakpoint-down(xl) {
    html.header-search--open.overlay {
        header,
        main,
        footer {
            &::after {
                opacity: 0;
            }
        }
    }
}