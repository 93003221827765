.block__list {
    &.block__list__wishlist {

        .block__header {
            .block__header__title {
                text-align: left;
            }
        }
    
        .block__body {
            .list {
    
                --bs-gutter-y: 2rem;
    
                .listelement {
                    .listelement__wrapper {
                        border-bottom:1px solid var(--color-custom-3-hex);
                        padding-bottom: var(--bs-gutter-y);

                        .listelement__meta {
                            display:grid;
                            gap:1rem;
                            width:100%;
                            align-items: center;

                            --image-size: 35px;

                            @include media-breakpoint-down(lg) {
                                padding:1rem;
                                border: 1px solid var(--color-custom-3-hex);

                                grid-template-columns: repeat(1, 1fr);
                                grid-template-areas:
                                'title'
                                'share'
                                'addToCart'
                                'getPrice'
                                'gallery'
                                'delete';
                            }

                            @include media-breakpoint-up(lg) {
                                grid-template-columns: 4fr 1fr 4fr 4fr 5fr 1fr;
                            }
                            
                            @include media-breakpoint-up(xl) {
                                --image-size: 40px;
                            }

                            @include media-breakpoint-up(xxl) {
                                --image-size: 50px;
                            }

                            .listelement__title {
                                
                                h3 {
                                    font-size: var(--bs-body-font-size);
                                    + button {
                                        font-size: calc(var(--bs-body-font-size)* .8);
                                        text-decoration: underline;
                                        display:flex;
                                        gap:.5rem;

                                        .list--hide {display:none;}

                                        &[aria-expanded="true"] {
                                            .list--show {
                                                display:none;
                                            }

                                            .list--hide {
                                                display:inline;
                                            }
                                        }
                                    }
                                }

                                @include media-breakpoint-down(lg) {
                                    grid-area: title;
                                }
                            }

                            .listelement__share {
                                @include media-breakpoint-down(lg) {
                                    grid-area: share;
                                }
                                
                                @at-root .popover {
                                    border:0!important;
                                    
                                    &[data-popper-placement^=top] > .popover-arrow::before,
                                    &[data-popper-placement^=top] > .popover-arrow::after {
                                        width: 0;
                                        height: 0;
                                        border-left: 10px solid transparent;
                                        border-right: 10px solid transparent;
                                        border-top: 10px solid var(--color-custom-4-hex);
                                    }

                                    .popover-body {
                                        background-color: var(--color-custom-4-hex);
                                        color: var(--color-main-hex);
                                        border-radius:.5rem;
                                    }
                                }
                            }

                            .listelement__addToCart {
                                .bttn {
                                    font-weight:400;
                                }

                                @include media-breakpoint-down(lg) {
                                    grid-area: addToCart;
                                }
                            }

                            .listelement__getPrice {
                                font-size: calc(var(--bs-body-font-size)* .8);

                                @include media-breakpoint-down(lg) {
                                    grid-area: getPrice;
                                }
                            }

                            .listelement__gallery {
                                display:flex;
                                gap:.5rem;
                                align-items: center;

                                .listelement__gallery__img {
                                    ul {
                                        @include reset-ul;
                                        display:flex;
                                        gap:.5rem;

                                        li {
                                            position:relative;
                                            @include reset-li;

                                            a {
                                                img {
                                                    aspect-ratio: 1/1;
                                                    width:var(--image-size);
                                                    object-fit: cover;
                                                    object-position: center center;
                                                }
                                            }
                                        }
                                    }

                                    &.plus {
                                        li {
                                            &:last-child {
                                                &::after {
                                                    position:absolute;
                                                    content:"";
                                                    display:block;
                                                    width:var(--image-size);
                                                    height: var(--image-size);
                                                    top:0;
                                                    left:0;
                                                    background: rgb(255,255,255);
                                                    background: linear-gradient(270deg, rgba(255,255,255,1) 0%, rgba(255,255,255,.5) 50%);
                                                }
                                            }                                            
                                        }
                                    }
                                }

                                .listelement__gallery__more {
                                    span {
                                        display:block;
                                        width:var(--image-size);
                                        border-radius:var(--image-size);
                                        line-height:var(--image-size);
                                        outline:1px solid var(--color-custom-3-hex);
                                        text-align:center;
                                        font-size: calc(var(--bs-body-font-size) * 0.8);
                                    }
                                }

                                @include media-breakpoint-down(lg) {
                                    grid-area: gallery;
                                }
                            }

                            .listelement__delete {
                                @include media-breakpoint-down(lg) {
                                    grid-area: delete;
                                }
                            }
                        }

                        .listelement__collapse {
                            .block__product__list {
                                .block__header {
                                    .block__header__toggle {
                                        button[data-bs-toggle="collapse"] {

                                            &::after {
                                                @include fontawesome;
                                                @include transition-transform;
                                                content:"\f107";
                                                margin-left:.5rem;
                                            }

                                            &[aria-expanded="true"] {
                                                &::after {
                                                    transform:rotate(-180deg);
                                                }
                                            }
                                        }
                                    }

                                    .block__header__collapse {
                                        margin-top: var(--margin-default);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .block__footer {
            ul {
                @include reset-ul;
                display:flex;
                gap:1rem;
                flex-wrap:wrap;

                li {
                    @include reset-li;
                }
            }
        }
    }
}