/*
=======================================================================
    Skip Navigation
=======================================================================
*/

#tilinnhold, #tilnavigasjon {
	position: absolute;
	overflow: hidden;
	z-index:99999;
	text-align:center;
	width:100%;
	background: var(--skip-navigation-background-color);
	color:var(--skip-navigation-color);
	top:-35px;
	font-size:18px;
	-webkit-transition: all 0.3s ease;                  
	-moz-transition: all 0.3s ease;                 
	-o-transition: all 0.3s ease;   
	-ms-transition: all 0.3s ease;          
	transition: all 0.3s ease;
	}

#tilinnhold:focus, #tilnavigasjon:focus {
	height:var(--header-height);
	line-height:var(--header-height);
	text-align:center;
	width:100%;
	top:0;
	
	}


:focus-visible {
	border: 2px solid red;
}


/*
=======================================================================
    Scroll to Top
=======================================================================
*/

#scrollToTopBtn {
    position: fixed;
    bottom: 15px;
    right: 15px;
    width: 50px;
    height: 50px;
    display: grid;
    place-items: center;
    text-align: center;
    line-height: 50px;
    background-color: var(--scroll-top-background-color-initial);
    color: var(--scroll-top-color-initial);
    z-index: 99;
    transition: all .5s ease;

	&.hidden {
		opacity:0;
		right:-100px;
	}

	&:hover,
	&:focus-visible {
		background-color:var(--scroll-top-background-color-hover);
		color:var(--scroll-top-color-hover);
	}
    
    svg {
        width: 1rem;
        height: 1rem;
    }
}