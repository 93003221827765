.block__list__figures {

    .block__header {
        .block__header__title {
            text-align:center;
        }
    }

    .block__body {
        .list {

            --bs-gutter-y: 2rem;

            .listelement {
                text-align:center;
                display:flex;
                flex-direction: column;
                gap:.75rem;
                    
                .listelement__icon {
                    font-size:3rem;
                }

                .listelement__counter {
                    font-size:5rem;
                    font-weight:300;
                
                }                

                .listelement__body {
                    padding-top:2rem;
                    background:transparent;

                    @include media-breakpoint-up(xl) {
                        padding-left:4rem;
                        padding-right:4rem;
                    }

                    .title {
                        font-size: var(--bs-body-font-size);
                        text-transform: uppercase;
                        margin:0;
                        position:relative;

                        &::before {
                            width:50px;
                            height:3px;
                            background-color:#000;
                            display:block;
                            position: absolute;
                            top:-2rem;
                            left:50%;
                            transform:translate(-50%);
                            content:"";
                        }
                    }
                }  
            }
        }
    }
}