/* .block:not(.block__textAndImage) {display:none;} */         

.block__textAndImage {
    .block__textAndImage__image,
    .block__textAndImage__text {
        display:flex;
        align-items: center;

        > .text {
            background-color: var(--text-bg);
            color: var(--bs-body-color);
            min-height: 100%;
            
            .title {
                h2 {
                    margin:0;
                    font-weight:600;
                }
            }

            .teaser {
                h3 {
                    font-size: var(--bs-body-font-size);
                    margin-bottom:.5rem;
                }
            }

            .text {
                h3 {
                    font-size: var(--bs-body-font-size);
                    margin-bottom:.5rem;
                }                    
            }

            .cta {
                display:flex;
                flex-wrap: wrap;
                gap:var(--margin-default);
            }
        }
    }
}


/*
=======================================================================
    Container Width
=======================================================================
*/

.block__textAndImage {
    .block__textAndImage__wrapper {
        display:flex;
    
        @include media-breakpoint-up(xl) {
            display:grid;
            grid-template-columns: 1fr 1fr;
            grid-template-areas: "text image";
        }

        @include media-breakpoint-down(xl) {
            flex-direction:  column-reverse;
        }

        .block__textAndImage__text {
            grid-area: text;

            .title {
                > * {
                    font-weight:500;
                }
            }

            > .text {
                flex-grow: 1;

                @include media-breakpoint-down(xl) {
                    padding-top: calc(var(--margin-default)*2);
                    padding-bottom:0;
                }

                @include media-breakpoint-up(xl) {
                    padding-right: calc(var(--margin-default)*2);
                }                        

                .shortcuts {
                    a {
                        position:relative;
                        display:block;
                        padding: var(--padding-default);
                        padding-right: calc(var(--padding-default)*2);
                        border-radius: var(--border-radius-default);
                        background-color: var(--color-custom-3-hex);
                        text-decoration: none;

                        .text {
                            padding-block: initial;
                            padding-inline: initial;

                            p {
                                color: #7C7C8D;
                                margin-bottom: 0;
                            }
                        }

                        svg{
                            width:10px;
                            height:24px;
                            position:absolute;
                            top:50%;
                            right: var(--padding-default);
                            transform:translateY(-50%);
                        }

                        &:hover,
                        &:focus-visible {
                            background-color: var(--color-custom-3-hover-hex);
                        }

                        + a {
                            margin-top: calc(var(--margin-default)/2);
                        }                            
                    }

                    
                }

                .contact {
                    display:flex;
                    gap:1rem;
                    align-items: center;

                    @include media-breakpoint-up(md) {
                        gap:1.5rem;
                    }                        

                    .contact__media {
                        position:relative;
                        min-width:115px;

                        @include media-breakpoint-up(md) {
                            min-width:135px;
                        }

                        img {
                            height: 35px;
                            width:35px;
                            border-radius:35px;
                            object-fit: cover;
                            position:relative;

                            @include media-breakpoint-up(md) {
                                height: 50px;
                                width:50px;
                                border-radius:50px;
                                
                            }                                



                            + img {
                                margin-left:-10%;
                            }

                            &:nth-child(1) {z-index:3;}
                            &:nth-child(2) {z-index:2;}
                            &:nth-child(3) {z-index:1;}
                        }
                    }
                }
            }
        }

        .block__textAndImage__image {
            grid-area: image;
        }
    }
    

    /* Image width options */
    &.image--25 {
        .block__textAndImage__wrapper {
            grid-template-columns: 75% 25%;
        }
    }

    &.image--75 {
        .block__textAndImage__wrapper {
            grid-template-columns: 25% 75%;
        }
    }
}


/*
=======================================================================
    Block Reverse Container Width
=======================================================================
*/

.block__textAndImage {
    &.block--reverse {
        > .container {
            > .block__textAndImage__wrapper {
                grid-template-areas: "image text"
            }
        }
    }
}


/*
=======================================================================
    Block Image Aspect Ratio
=======================================================================
*/

.block {
    &[class*="aspect-ratio--"] {

        &::after {
            display: block;
            clear: both;
            content: "";
        }
        
        .container-fluid {
            .block__textAndImage__text {

                @include media-breakpoint-up(xl) {
                    position: absolute;
                    height:100%;
                    width:100%;
                }

                .container {
                    @include media-breakpoint-up(xl) {
                        position:absolute;
                        left: auto;
                        top:50%;
                        transform:translateY(-50%);
                    }
                }
            }
            
            .block__textAndImage__image {


                @include media-breakpoint-up(xl) {

                    img {
                        height:initial;
                    }

                    position: initial;
                    top: initial;
                    right: initial;
                    bottom:initial;
                    width:50%;
                    float:right;

                    @at-root .block__textAndImage.block--reverse .container-fluid .block__textAndImage__text .text {
                        float:right;
                        padding-left: calc(var(--margin-default) * 2);
                        padding-right:0;
                    }

                    @at-root .block__textAndImage.block--reverse .container-fluid .block__textAndImage__image {
                        float:left;
                    }                    

                    > * {
                        width:initial;
                        height:initial;
                        object-fit:initial;
                    }
                }
            }
        }        
    }
}


.block__textAndImage {
    &.aspect-ratio--1by1 {
        .block__textAndImage__image {
            aspect-ratio: 1 / 1;
            img {
                aspect-ratio: 1 / 1;
            }
        }
    }

    &.aspect-ratio--4by3 {
        .block__textAndImage__image {
            aspect-ratio: 4 / 3;
            img {
                aspect-ratio: 4 / 3;
            }
        }
    }    

    &.aspect-ratio--16by9 {
        .block__textAndImage__image {
            aspect-ratio: 16 / 9;
            img {
                aspect-ratio: 16 / 9;
            }
        }
    }    
}


/*
=======================================================================
    Quote
=======================================================================
*/

figure.quote {
    margin: 0;

    .icon {
        fill: #fff;
        width:3rem;
        margin-bottom:1rem;
    }

    blockquote {
        position: relative;
        font-size: calc(var(--bs-body-font-size)*1.25);

        /*
        &::before {
            font: var(--fa-font-sharp-solid);
            content: "\f10d";
            position: absolute;
            color: var(--color-main-accent-hex);
            font-weight: bold;
            top:-50px;

            @include media-breakpoint-up(xl) {
                font-size: calc(var(--bs-body-font-size)*2);
            }
        }
        */

        + figcaption {
            font-size: var(--bs-body-font-size);
        }
    }
}


/*
=======================================================================
    Vertical Align Text
=======================================================================
*/

.block__textAndImage {
    &.vertical-align--top {

        .block__textAndImage__image {
            > * {
                height:initial;
            }
        }

        .block__textAndImage__image,
        .block__textAndImage__text {
            align-items:flex-start;
        }
    }
}


/*
=======================================================================
    Theme Blank
=======================================================================
*/

.block__textAndImage {
    &.theme--blank {
        
        figure.quote {
            .icon {
                fill: var(--bs-body-color);
            }
        }

        .block__textAndImage__text {
            > .text {
                padding: calc(var(--margin-default)*2)!important;

                .cta {
                    --button-color: var(--color-link-hex);
                    --button-border-color: var(--color-link-hex);

                    &:hover,
                    &:focus,
                    &:focus-visible {
                        --button-hover-text-color:#fff;
                        --button-hover-border-color: var(--color-link-hex);
                        --button-hover-background-color: var(--color-link-hex);
                    }
                }
            }
        }
    }
}


/*
=======================================================================
    Theme Sand
=======================================================================
*/

.block__textAndImage {
    &.theme--sand {

        figure.quote {
            .icon {
                fill: var(--bs-body-color);
            }
        }

        .block__textAndImage__text {
            > .text {
                --text-bg:#fdf7f2;
                padding: calc(var(--margin-default)*2)!important;

                .cta {
                    --button-color: var(--color-link-hex);
                    --button-border-color: var(--color-link-hex);

                    &:hover,
                    &:focus,
                    &:focus-visible {
                        --button-hover-text-color:#fff;
                        --button-hover-border-color: var(--color-link-hex);
                        --button-hover-background-color: var(--color-link-hex);
                    }
                }
            }
        }
    }
}


/*
=======================================================================
    Theme Grey
=======================================================================
*/

.block__textAndImage {
    &.theme--grey {

        figure.quote {
            .icon {
                fill: var(--bs-body-color);
            }
        }

        .block__textAndImage__text {
            > .text {
                --text-bg:#F7F7F7;
                padding: calc(var(--margin-default)*2)!important;

                .cta {
                    --button-color: var(--color-link-hex);
                    --button-border-color: var(--color-link-hex);

                    &:hover,
                    &:focus,
                    &:focus-visible {
                        --button-hover-text-color:#fff;
                        --button-hover-border-color: var(--color-link-hex);
                        --button-hover-background-color: var(--color-link-hex);
                    }
                }
            }
        }
    }
}


/*
=======================================================================
    Theme Teal
=======================================================================
*/

.block__textAndImage {
    &.theme--teal {

        figure.quote {
            .icon {
                fill: var(--bs-body-color);
            }
        }

        .block__textAndImage__text {
            > .text {
                --text-bg:#D9E6EA;
                padding: calc(var(--margin-default)*2)!important;

                .cta {
                    --button-color: var(--color-link-hex);
                    --button-border-color: var(--color-link-hex);

                    &:hover,
                    &:focus,
                    &:focus-visible {
                        --button-hover-text-color:#fff;
                        --button-hover-border-color: var(--color-link-hex);
                        --button-hover-background-color: var(--color-link-hex);
                    }
                }
            }
        }
    }
}


/*
=======================================================================
    Theme Green
=======================================================================
*/

.block__textAndImage {
    &.theme--green {

        figure.quote {
            .icon {
                fill: #fff;
            }
        }

        .block__textAndImage__text {
            > .text {
                --text-bg:#152E29;
                color: var(--color-white-hex);
                padding: calc(var(--margin-default)*2)!important;

                .teaser {
                    a {
                        color: var(--color-white-hex);
                        text-decoration: underline;

                        &:hover,
                        &:focus,
                        &:focus-visible {
                            color: var(--color-white-hover-hex);
                        }
                    }
                }

                .contact {
                    .link {
                        color: var(--color-white-hex);
                        
                        &:hover,
                        &:focus,
                        &:focus-visible {
                            color: var(--color-white-hover-hex);
                        }
                    }
                }

                .cta {
                    --button-color: var(--color-white-hex);
                    --button-border-color: var(--color-white-hex);

                    &:hover,
                    &:focus,
                    &:focus-visible {
                        --button-hover-text-color:#152E29;
                        --button-hover-border-color: var(--color-white-hex);
                        --button-hover-background-color: var(--color-white-hex);
                    }
                }
            }
        }
    }
}