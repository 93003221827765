.block__shortcutsWithImage {
    ul {
        @include reset-ul;
        gap:1rem;

        @include media-breakpoint-down(lg) {
            display:flex;
            flex-direction: column;
        }

        @include media-breakpoint-up(lg) {
            display:grid;
            grid-template-columns: repeat(2, 1fr);
        }

        @include media-breakpoint-up(xl) {
            grid-template-columns: repeat(3, 1fr);
        }

        li {
            @include reset-li;

            @include media-breakpoint-down(lg) {
                flex:1;
            }

            a {
                display:block;
                border-radius:var(--border-radius-default);
                overflow: hidden;

                figure {
                    aspect-ratio: 16 / 5;
                    position:relative;
                    margin:0;

                    &::after {
                        position:absolute;
                        top:0;
                        left:0;
                        bottom:0;
                        right:0;
                        content:"";
                        background-color: rgba(0,0,0,0.25);
                        @include transition-background-color;
                    }

                    img {
                        width:100%;
                        height:100%;
                        object-fit: cover;
                        object-position: center;
                    }

                    figcaption {
                        position: absolute;
                        bottom:1.5rem;
                        left:1.5rem;
                        font-size: calc(var(--bs-body-font-size) * 1.5);
                        font-weight:500;
                        color:#fff;
                        z-index: 1;
                        letter-spacing:1px;
                        line-height: 1;

                        svg{
                            width:16px;
                            height:24px;
                            margin-left:0.6rem;
                        }
                    }
                }

                &:hover,
                &:focus-visible {
                    figure {
                        &::after {
                            background-color: rgba(0,0,0,0.35);
                        }
                    }
                }
            }
        }
    }
}