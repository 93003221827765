.block__list {
    &.block__list__documents {

        .block__body {
            .list {
                .listelement {
                    .listelement__body {
                        padding:0;

                        .byline {
                            font-size:1rem;
                        }

                        .link--download {
                            font-weight:400;
                            text-decoration: none;

                            &:hover,
                            a:focus-visible {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }
    }
}