.block__imagerow {
    .block__body {
        .listelement {

            figure {
                margin:0;
                position:relative;

                a {
                    position:absolute;
                    top:0;
                    right:0;
                    bottom:0;
                    left:0;
                    z-index: 1;
                }

                figcaption {
                    font-size:1rem;
                    opacity:.8;
                    text-align: center;
                    margin-top:.5rem;
                }
            }
        }
    }

    &.aspect-ratio--1by1 .listelement figure img {
        aspect-ratio: 1 / 1;
        object-fit: cover;
        object-position: center;
    }

    &.aspect-ratio--3by2 .listelement figure img {
        aspect-ratio: 3 / 2;
        object-fit: cover;
        object-position: center;
    }

    &.aspect-ratio--4by3 .listelement figure img {
        aspect-ratio: 4 / 3;
        object-fit: cover;
        object-position: center;
    }

    &.aspect-ratio--5by4 .listelement figure img {
        aspect-ratio: 5 / 4;
        object-fit: cover;
        object-position: center;
    }

    &.aspect-ratio--7by6 .listelement figure img {
        aspect-ratio: 7 / 6;
        object-fit: cover;
        object-position: center;
    }    

    &.aspect-ratio--16by9 .listelement figure img {
        aspect-ratio: 16 / 9;
        object-fit: cover;
        object-position: center;
    }
}



