.block__breadcrumb {
    padding-top:0;

    .container {
        display:flex;
        flex-wrap:wrap;
        justify-content: space-between;
        gap:var(--spacer-3);

        ol,
        ul {
            @include reset-ul;
            display:flex;
            gap:.25rem;

            li {
                @include reset-li;

                a {
                    font-size:.85rem;
                    text-decoration: none;
                    
                    &:hover,
                    &:focus-visible {
                        text-decoration: underline;
                    }
                }
            }
        }

        [aria-label="breadcrumb"] {
            ol {
                li {
                    
                }
            }
        }

        [aria-label="segment"] {
            ul {
                li {
                    a {
                        opacity:.5;
                        
                    }

                    &.active {
                        a {
                            opacity:1;
                        }
                    }

                    &:first-child {
                        &::after {
                            content:"|";
                            padding-left:.25rem;
                            color:#000;
                            opacity:.5;
                        }
                    }
                }
            }
        }
    }
}
