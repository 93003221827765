.block__shortcutsWithIcon {
    margin-top: var(--margin-default);

    ul {
        @include reset-ul;
        display:grid;
        grid-template-columns: repeat(2, 1fr);
        gap:1rem;

        @include media-breakpoint-up(md) {
            grid-template-columns: repeat(3, 1fr);
        }        

        @include media-breakpoint-up(lg) {
            grid-template-columns: repeat(4, 1fr);
        }

        @include media-breakpoint-up(xl) {
            grid-template-columns: repeat(6, 1fr);
        }

        li {
            @include reset-li;
            @include media-breakpoint-down(xl) {
                flex:1;
            }

            a {
                aspect-ratio: 4 / 3;
                background-color: var(--color-custom-3-hex);
                text-decoration: none;
                display:flex;
                justify-content: center;
                align-items: center;
                padding:1rem;
                text-align: center;

                figure {
                    margin:0;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 0.5rem;

                    svg, img {
                        width: 50px!important;
                        height: 50px!important;
                        object-fit: contain;
                    }

                    figcaption {
                        font-size: 1rem;
                        font-weight:500;
                    }
                }

                &:hover,
                a:focus-visible {
                    background-color: var(--color-custom-3-hover-hex);
                }
            }
        }
    }
}