.block__list {
    &.block__product__wishlist {

        .block__header__title {
            h1 {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                gap: 1rem;
                &::before {
                    width:40px;
                    height:45px;
                }
            }
        }
    }

    &.block__product__wishlist--notfound {

        .block__header__title {
            h1 {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                gap: 1rem;
                svg {
                    width:40px;
                    height:45px;
                }
            }
        }
    }
}