/*.block:not(.block__shop__product) {display:none;}*/

.block__shop__product {
    .block__body {

        .product-image-xs .product-image__wrapper {
            position:relative;

            @include media-breakpoint-up(lg) {
                display:none;
            }

            /* Show only the first item */
            a {
                position:relative;
                display:block;

                &::after {
                    @include image-overlay;
                }

                &:not(:first-child) {
                    display: none;
                }
            }

            /* Show label if more images than one */
            &.more-images {
                &::after {
                    content:"Flere bilder";
                    position:absolute;
                    bottom:1rem;
                    right:1rem;
                    z-index: 1;
                    background-color: var(--color-custom-3-hex);
                    padding: 0.25rem 0.75rem;
                    border-radius:.4rem;
                    color: var(--bs-body-color);
                    font-size: .75rem;
                    pointer-events: none;
                }
            }
        }

        .product-image-xl {
            position:relative;
            
            .product-image__wrapper {    
                display:none;
                grid-template-columns: repeat(2, 1fr);
                gap: 0.25rem;
    
                @include media-breakpoint-up(lg) {
                    display:grid;
                }
                
                a {
                    position:relative;
                    display:none;
    
                    &::after {
                        @include image-overlay;
                    }
    
                    img {
                        aspect-ratio: 1 / 1;
                        object-fit: contain;
                        width:100%;
                    }
                }
                
                .certification-logos {
                    position: absolute;
                    z-index: 2;
                    top: 1rem;
                    right: 1rem;
                    display: flex;
                    flex-wrap: wrap;
                    gap: 0.25rem;
                    padding: 0.5rem;
                    background: #fff;
                    border-radius: 1.25rem;
                    
                    img, svg {
                        max-height: 1.5rem;
                        width: auto;
                    }
                }
            }

            /* Show only the first 4 items initially */
            &:not(.show--all) {
                a {
                    &:nth-child(-n+4) {
                        display: block;
                    }  
                }
            }

            /* Show all items eventually */
            &.show--all {
                a {
                    display:initial;
                }

                /* If all images are displayed, hide the load button */
                #load-more {
                    display:none!important;
                }

                /* If all images are displayed, show the hide button */
                #load-less {
                    display:block;
                }

                /* If the number of images is an odd number */
                &.odd {
                    .product-image__load {
                        right:calc(50% + 1rem);
                    }
                }
            }

            .product-image__load {
                position:absolute;
                bottom:1rem;
                right:1rem;
                z-index: 1;

                @include media-breakpoint-down(lg) {
                    display:none;
                }

                button {
                    background-color:var(--color-white-hex);
                    padding:.75rem;
                    border:0;
                    color: var(--bs-body-color);
                    border-radius:5px;
                    font-weight: 500;
                    font-size: 0.85rem;
                    letter-spacing: .5px;
                    display:none;

                    @include media-breakpoint-up(xl) {
                        padding:1rem;
                    }                    

                    &:hover,
                    &:focus-visible {
                        background-color:var(--color-white-hover-hex);
                    }
                }
            }
        }

        .product__image__modal {
            .bttn--close {
                padding:.5rem 1rem;
                position:absolute;
                z-index:15;
                top:1rem;
                right:1rem;

                &::after {
                    @include fontawesome;
                    content:"\f00d";
                }
            }

            .swiper {
                height:100%;

                .swiper-slide {
                    img {
                        height:100%;
                        width:100%;
                        object-fit: contain;
                        object-position:  center center;
                    }
                }

                --swiper-navigation-size: 2rem;
                --swiper-theme-color: #0D2240;
            }
        }

        .product__accordion__tab {
            margin-top: var(--spacer-5);

            .accordion-tab-wrap{
                position: relative;

                /* Tabs */
                .nav-tabs {
                    display:none;
                    border-bottom: 1px solid #dee2e6;

                    .nav-item {
                        margin-right: 10px;
                        margin-bottom: 0;

                        .nav-link {
                            text-transform:uppercase;
                            font-family: 'Wix Madefor Display', sans-serif;
                            font-weight:700;

                            &.active {
                                border-bottom: 3px solid var(--bs-body-color);
                            }
                        }                
                    }

                    .nav-link{
                        border: none;
                        border-top-left-radius: 0;
                        border-top-right-radius: 0;
                    }
                } 
                
                .custom-item-body {
                    padding: 1rem 0;

                    h1,h2,h3,h4,h5,h6 {
                        font-size: var(--bs-body-font-size);
                        font-weight:500;
                    }
                }

                /* Custom Tab 1 */

                .tab-pane--description {

                    .product-description {
                        .product-description--long {
                            display:none;
                        }

                        button {
                            @include reset-button;
                            color: var(--bs-body-color);
                            font-weight:600;
                            text-decoration: underline;
                            margin-top:.5rem;

                            .label--less {display:none;}
                        }

                        &.more {
                            .product-description--short {
                                display:none;
                            }

                            .product-description--long {
                                display:initial;
                            }                            

                            button {
                                .label--more {display:none;}
                                .label--less {display:initial;}
                            }
                        }
                    }

                    .product-properties {
                        ul {
                            @include reset-ul;
                            display:flex;
                            flex-wrap:wrap;
                            column-gap: 1rem;
                            row-gap: calc(var(--margin-default) / 2);

                            li {
                                @include reset-li;
                            }

                            + ul {
                                margin-top: calc(var(--margin-default) / 2);
                            }
                        }
                    }
                }

                /* Custom Tab 2 */
                .tab-pane--specification {
                    table {
                        tbody {
                            tr {
                                th {
                                    text-align:right;
                                    padding-right:1rem;
                                    font-weight:500;
                                    vertical-align:top;
                                }
                            }
                        }
                    }
                }

                /* Custom Tab 3 */
                .tab-pane--sustainability {
                    .logo__wrapper {
                        ul {
                            @include reset-ul;
                            display:flex;
                            gap:1rem;

                            li {
                                @include reset-li;

                                img {
                                    max-height:50px;
                                }
                            }
                        }
                    }

                    .key-figures__wrapper {
                        display:flex;
                        gap:1rem;

                        @include media-breakpoint-down(md) {
                            flex-direction: column;
                        }                        

                        .key-figures__item {
                            flex:1;
                            background-color: var(--color-custom-3-hex);
                            padding:1rem;
                            position:relative;
                            max-width: 50%;

                            .key-figures__item__tooltip {
                                position:absolute;
                                top:.5rem;
                                right:.5rem;

                                i {
                                    opacity:.75;
                                }
                            }

                            .key-figures__item__content {
                                display:flex;
                                flex-direction: column;
                                justify-content: space-between;
                                gap:1.5rem;
                                
                                .key-figures__item__content__body {
                                    font-size: 0.875rem;

                                    h3 {
                                        font-size: 0.875rem;
                                        font-weight:500;
                                        margin:0;
                                    }
                                }
                            }
                        }
                    }

                    table {
                        tbody {
                            tr {
                                th {
                                    padding-right:1rem;
                                    font-weight:500;
                                    vertical-align: top;
                                }
                            }
                        }
                    }
                }

                /* Custom Tab 4 */
                .tab-pane--documents {
                    .accordion {
                        .accordion-header {
                            .accordion-button {
                                --bs-accordion-btn-focus-box-shadow: none;
                                --bs-accordion-btn-color: var(--bs-body-color);
                                --bs-accordion-active-color: var(--bs-body-color);
                                --bs-accordion-active-bg:transparent;
                                text-transform:uppercase;

                                &::after {
                                    @include fontawesome;
                                    content:"\2b";
                                    background-image:none;
                                    width:unset;
                                    height:unset;
                                }

                                &[aria-expanded="true"] {
                                    &::after {
                                        --bs-accordion-btn-icon-transform: rotate(-45deg);
                                        --bs-accordion-btn-focus-border-color: transparent;
                                        --bs-accordion-active-color: var(--bs-body-color);

                                        background-image:none;
                                    }
                                }
                            }
                        }

                        .accordion-collapse {
                            &.collapse:not(.show) {
                                display:none;
                            }

                            .accordion-body {
                                ul {
                                    @include reset-ul;
                                    li {
                                        @include reset-li;

                                        a {
                                            text-decoration: none;

                                            span {
                                                text-decoration: underline;
                                                font-weight:500;
                                            }

                                            &::before {
                                                @include fontawesome;
                                                content:"\f56d";
                                                margin-right:.5rem;
                                                display:inline-block;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                /* Tab styling */
                @include media-breakpoint-up(xxl) {
                    .nav-tabs {
                        display: flex;
                        margin-bottom:1rem;
                    }
                    
                    .custom-accordion-tab-item {
                        border: none;

                        .custom-accordion-header {
                            display:none;
                        }                

                        .collapse{
                            display:block;
                        }
                    }                
                }

                /* Accordion styling */
                @include media-breakpoint-down(xxl) {
                    .tab-content > .tab-pane {
                        display: block !important;
                        opacity: 1;
                        margin-bottom: 10px;
                    }
                
                    .custom-accordion-header {
                        background:none;
                        border:0;
        
                        .custom-accordion-button {
                            text-transform:uppercase;
                            text-decoration: none;
                            border-bottom: none;
                            display: block;
                            position:relative;
                            padding: 1rem 0;
                            font-family: 'Wix Madefor Display', sans-serif;
                            font-weight:700;
        
                            &::after {
                                @include fontawesomesharp;
                                @include transition;
                                content:"\f078";
                                position: absolute;
                                top: 50%;
                                right: 1rem;
                                transform: translateY(-50%);                            
                            }
        
        
                            &[aria-expanded="true"] {
                                &::after {
                                    transform:rotate(-180deg) translateY(50%);
                                }
                            }
                        }
                    }
        
                    .custom-accordion-tab-item {
                        border-bottom: 1px solid #dee2e6;
                    }
                }
            }
        }

        .block__shop__product__swipe {
            padding-block: 0;
            margin-block: var(--spacer-5) 0;
        }

        nav[aria-label="breadcrumb"] {
            font-size: .875rem;
        }

        .product-meta {
            display:flex;
            flex-direction: column;
            position: sticky;
            top: var(--header-height);

            .product-header {
                display:flex;
                flex-direction: column;
                gap: calc(var(--spacer-1) / 2);

                @include media-breakpoint-down (lg) {
                    .product-image-xs {order:1;}
                    .product-labels-and-enviroLabels {order:2;}
                    .product-brand {order:3;}
                    .product-title {order:4;}
                }                

                .product-labels-and-logos,
                .product-labels-and-enviroLabels {
                    display:grid;
                    grid-template-columns: 1fr 1fr;                    
                    align-items: start;
                    margin-top: var(--spacer-3);

                    .product-labels {
                        .label {
                            span {
                                font-family: 'Wix Madefor Display', sans-serif;
                                font-size: .875rem;
                                font-weight:700;
                                border:1px solid var(--bs-body-color);
                                border-color: var(--label-border-color);
                            }
                        }
                    }

                    .product-enviroLabels {
                        @include reset-ul;
                        display: flex;
                        justify-content: flex-end;
                        gap:.5rem;

                        .label {
                            overflow: hidden;
                            background-color: #f8f7f7;
                            list-style: none;
                            white-space: nowrap;
                            border-radius: 25px;
                            display: flex;
                            align-items: center;
                            
                            .product-label-icon {
                                order:1;
                                line-height: 1;
                                display: inline-block;
                                padding: 0.5rem;

                                svg {
                                    max-height:18px;
                                }
                            }

                            .product-label-text {
                                order:2;
                                opacity: 0;
                                color: #666;
                                max-width: 0;
                                display: inline-block;
                                font-size: calc(var(--bs-body-font-size) * 0.75);
                                text-decoration: none;
                                transition: max-width .3s ease-out .1s, opacity .3s ease-out .1s, color;

                                > span {
                                    float: right;
                                    display: block;
                                    padding-right: 1em;
                                }
                            }

                            &:hover > a {
                                opacity: 1;
                                max-width: 150px;
                                transition: max-width .3s ease-out .1s, opacity .3s ease-out .1s, color .2s;
                                pointer-events: none;
                            }    
                        }
                    }

                    ul.product-logos {
                        @include reset-ul;
                        display:flex;
                        justify-content: flex-end;
                        gap:.5rem;

                        li {
                            @include reset-li;

                            img {
                                max-height:30px;
                            }
                        }
                    }
                }

                .product-brand {
                    font-size: 0.875rem;
                    line-height: 1.25rem;
                    font-weight: 400;
                    text-transform: uppercase;
                    letter-spacing: 0.07em;
                    color: var(--color-custom-1-hex);

                    @include media-breakpoint-down (lg) {
                        margin-top: var(--spacer-2);
                    }

                    a {
                        text-decoration: none;

                        &:hover,
                        &:focus,
                        &:focus-visible {
                            text-decoration: underline;
                        }
                    }
                }                

                .product-title {

                    .product-title h1.h2 {
                        font-size: calc(var(--font-size-xxl) * .8);
                    }

                    a {
                        text-decoration: none;

                        &:hover,
                        &:focus,
                        &:focus-visible {
                            text-decoration: underline;
                        }
                    }
                }
            }

            .product-price {
                margin-top: var(--spacer-1);
                display:flex;
                gap: var(--spacer-2);
                align-items:flex-end;

                .product-price__now {
                    font-weight: 700;
                    font-size: 1.25rem;

                    span {
                        font-size: calc(var(--bs-body-font-size) * .85);
                    }
                }

                .product-price__before {
                    text-decoration: line-through;
                    font-size: calc(var(--bs-body-font-size) * .85);
                    order:2;

                    + .product-price__now {
                        color: #f68d68; /* Color now when on sale */
                        font-size: calc(var(--bs-body-font-size)*1.2);
                        
                        span {
                            color: #f68d68; /* Color now when on sale */
                        }
                    }
                }

                .withTax, .withoutTax {
                    display: flex;
                    align-items: baseline;
                    gap: 0.25rem;
                }          
                
                .product-price__vat {
                    font-size: .75rem;
                }
            }

            .product-customize {
                margin-top: var(--spacer-4);
                .bttn--custom {
                    display:inline-block;
                    text-transform:uppercase;
                }
            }

            .product-variants {
                margin-top: var(--spacer-4);

                .variant-list-header {
                    text-transform: uppercase;
                    font-size: 0.875rem;
                    line-height: 1.25rem;
                    font-weight: 700;
                    letter-spacing: 0.07em;
                    margin-bottom: 0.75rem;
                }

                ul {
                    @include reset-ul;
                    display:grid;
                    grid-template-columns: repeat(8, 1fr);
                    gap:.5rem;
                    
                    li {
                        @include reset-li;

                        &:hover,
                        &:focus-visible,
                        &.active {
                            a {
                                border-color: var(--color-main-accent-hex);
                            }
                        }
                        
                        a {
                            position:relative;
                            border: 2px solid transparent;
                            display:inline-block;
                            border-radius:10px;
                            aspect-ratio: 1 / 1;
                            width:100%;

                            .variant-image {
                                aspect-ratio: 1 / 1;
                                width: 100%;
                                box-shadow: 0 1px 1rem 0 rgba(0, 0, 0, 0.07);
                                display:block;
                                border-radius:10px;
                                
                                img {
                                    border-radius:8px;
                                    width:100%;
                                    height:100%;
                                    object-fit: contain;
                                    object-position: center;
                                }
                            }

                            .variant-label {
                                position: absolute;
                                inset: 0;
                                font-weight: 500;
                                font-size: 0.875rem;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                gap: 0.5rem;
                                line-height: 1;
                            }
                        }
                        
                        &.variants-button {
                            grid-column-end: span 2;
                            background: #F7F7F7;
                            border-radius: 0.5rem;
                            position: relative;
                            
                            a {
                                aspect-ratio: initial;
                                position: absolute;
                                inset: 0;
                            }
                        }
                    }
                }
            }

            .variant-description {
                margin-top: var(--spacer-4);
                font-size: calc(var(--bs-body-font-size) * 0.75);
            }

            .product-options {
                margin-top: var(--spacer-4);

                ul {
                    @include reset-ul;
                    display:flex;
                    flex-direction: column;
                    gap: var(--spacer-2);

                    li {
                        @include reset-li;

                        button {
                            display:grid;
                            grid-template-columns: 1fr 2.5fr;
                            align-items:center;
                            width:100%;
                            text-align:left;
                            border-radius: 100px;
                            position:relative;
                            padding: var(--padding-button-vertical) 1rem;
                            background:var(--color-custom-3-hex);
                            font-size:1rem;

                            .option__label {
                                font-weight:500;
                            }

                            .option__value {
                                display:flex;
                                gap:.75rem;
                                align-items:center;

                                .option__value__img {
                                    img {
                                        width:30px;
                                        object-fit: cover;
                                        object-position: center;
                                        border-radius: calc(var(--border-radius-default) * .65);
                                    }
                                }
                            }
                            
                            &:hover,
                            &:focus-visible {
                                background:var(--color-custom-3-hover-hex);
                            }
                            
                            &::before {
                                @include fontawesome;
                            }

                            &::after {
                                @include fontawesome;
                                content:"\f105";
                                position:absolute;
                                right:1rem;
                                top:50%;
                                transform:translateY(-50%);
                            }
                        }
                    }
                }
            }

            .product-status {
                margin-top: var(--spacer-4);
                display: flex;
                align-items: center;
                gap: var(--spacer-1);
                justify-content: space-between;

                .product-status__delivery {
                    color: var(--bs-body-color);
                    font-weight:400;
                    
                    &.api {
                        font-size: 0.875rem;
                        max-width: 50%;
                    }
                }

                .product-status__stock {
                    position:relative;
                    padding-left:1rem;
                    font-family: 'Wix Madefor Display', sans-serif;
                    font-weight: 500;
                    font-size: 0.875rem;

                    &::before {
                        content: '';
                        display: block;
                        width: 0.625rem;
                        height: 0.625rem;
                        color: #666; /* Product not in stock */
                        background-color: currentColor;
                        border-radius: 50%;
                        position:absolute;
                        top:50%;
                        transform:translateY(-50%);
                        left:0;
                    }

                    /* Product in stock */
                    @at-root .block__shop__product.in-stock .product-status__stock::before {
                        color: #5EC094!important;
                    }

                    /* Product in backorder */
                    @at-root .block__shop__product.backorder .product-status__stock::before {
                        color: #F68D68!important;
                    }
                }
            }

            .product-cta {
                margin-top: var(--spacer-4);
                display:flex;
                flex-direction: row;
                align-items: center;
                gap: var(--spacer-1);

                .product-cta__qty {
                    display: inline-block;
                    min-width:120px;
            
                    .input-group {
                        display: inline-flex;
                        align-items: center;
                        gap: .25rem;
                        padding-inline: 4px;
            
                        input {
                            font-weight: 700;
                            text-align: center;
                            height: 3rem;
                            max-width: 2rem;
                            border: 0;
                            padding-inline: 0;
                            background-color: transparent;
                            -moz-appearance: textfield;
                
                            &::-webkit-outer-spin-button,
                            &::-webkit-inner-spin-button {
                                -webkit-appearance: none;
                                margin: 0;
                            }
                        }
                
                        button {
                            width: 2.5rem;
                            height: 2.5rem;
                            padding: 0;
                            border: 1px solid var(--color-custom-1-hex);
                            color: var(--color-custom-1-hex);
                            font-size: 1.25rem;
                            font-weight: 500;
                            border-radius: 50% !important;
                            transition: all 0.3s ease;
                
                            &:hover,
                            &:focus-visible {
                                background-color: var(--color-custom-1-hex);
                                color: #fff;
                            }
                        }
                    }
                }

                .product-cta__add-to-cart {
                    flex:1;

                    button {
                        font-weight:400;
                        background-color: var(--color-secondary-accent-hex);
                        width: 100%;
                        height: 3.25rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 0.5rem;

                        &:hover,
                        &:focus-visible {
                            background-color: var(--color-secondary-accent-hover-hex);
                        }
                    }
                }

                .product-cta__add-to-favorites {
                    a.bttn {
                        display:block;
                        padding-inline: .5rem;
                        background: transparent;
                        border: transparent;                        

                        &::after {
                            content: '';
                            display: block;
                            width: 1.5rem;
                            height: 1.5rem;
                            background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M225.8 468.2l-2.5-2.3L48.1 303.2C17.4 274.7 0 234.7 0 192.8v-3.3c0-70.4 50-130.8 119.2-144C158.6 37.9 198.9 47 231 69.6c9 6.4 17.4 13.8 25 22.3c4.2-4.8 8.7-9.2 13.5-13.3c3.7-3.2 7.5-6.2 11.5-9c0 0 0 0 0 0C313.1 47 353.4 37.9 392.8 45.4C462 58.6 512 119.1 512 189.5v3.3c0 41.9-17.4 81.9-48.1 110.4L288.7 465.9l-2.5 2.3c-8.2 7.6-19 11.9-30.2 11.9s-22-4.2-30.2-11.9zM239.1 145c-.4-.3-.7-.7-1-1.1l-17.8-20c0 0-.1-.1-.1-.1c0 0 0 0 0 0c-23.1-25.9-58-37.7-92-31.2C81.6 101.5 48 142.1 48 189.5v3.3c0 28.5 11.9 55.8 32.8 75.2L256 430.7 431.2 268c20.9-19.4 32.8-46.7 32.8-75.2v-3.3c0-47.3-33.6-88-80.1-96.9c-34-6.5-69 5.4-92 31.2c0 0 0 0-.1 .1s0 0-.1 .1l-17.8 20c-.3 .4-.7 .7-1 1.1c-4.5 4.5-10.6 7-16.9 7s-12.4-2.5-16.9-7z" fill="%230d2240"/></svg>') center no-repeat;
                        }

                        &:focus-visible {
                            &::after {
                                font-weight:600;
                            }
                        }

                        &:hover,
                        &.active {
                            &::after {
                                background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M39.8 263.8L64 288 256 480 448 288l24.2-24.2c25.5-25.5 39.8-60 39.8-96C512 92.8 451.2 32 376.2 32c-36 0-70.5 14.3-96 39.8L256 96 231.8 71.8c-25.5-25.5-60-39.8-96-39.8C60.8 32 0 92.8 0 167.8c0 36 14.3 70.5 39.8 96z" fill="%230d2240"/></svg>') center no-repeat;
                                
                            }
                        }                        
                    }
                }

                &.product-cta--request {
                    margin-top: var(--spacer-4);                    
                    display:flex;
                    flex-direction: row;
                    gap:var(--spacer-1);
                    align-items: center;
    
                    .product-cta__price-request {
                        flex:1;
    
                        button {
                            width:100%;
                            
                            &::before {
                                content:url("data:image/svg+xml,%3Csvg width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.9375 5.15449L10.5 10.5803L17.5312 5.15449M8.54657 10.0013L3.9375 15.0197M17.0625 14.6086L12.4528 10.0013M4.875 15.8346C3.83947 15.8346 3 14.9513 3 13.8616L3 6.14101C3 5.05133 3.83947 4.16797 4.875 4.16797L16.125 4.16797C17.1605 4.16797 18 5.05133 18 6.14101V13.8616C18 14.9513 17.1605 15.8346 16.125 15.8346L4.875 15.8346Z' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
                                vertical-align:middle;
                                margin-right:.5rem;
                            }
                        }
                    }
                }
            }

            .priceRequestHubspotForm{
                form{
                    max-width: 580px;
                    margin: auto;
                    display:block !important;
                }

                fieldset {
                    max-width: unset !important;
                    margin-top: 0.3rem !important;
                }

                .field > label{
                    width: fit-content;
                    font-size: .85rem !important;
                    margin: 0 0 0.5rem;
                }
                .field > label > .hs-form-required{
                    color: #DC3545;
                }
                .field > .input{
                    width: 100%;
                }
                .field{
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    border-radius: 0.75rem;
                    margin-bottom: 12px;
                }
                .field > .input > input, .field > .input > textarea{
                    background-color: #eff0f3;
                    border: unset;
                    width: 100% !important;
                    font-size: .75em;
                    padding: 0.75rem;
                    border-radius: 0.75rem;
                }

                .field > .input > input:focus-visible {
                    border: 2px solid red !important;
                }

                .field > .input > .hs-dateinput > input{
                    background-color: #eff0f3;
                    border: unset;
                    border-radius: 7px;
                    width: 100%;
                    max-width: 410px;
                }
                .field > ul{
                    margin: 0;
                }
                .field > .input > ul{
                    margin: 0;
                    padding: 0;
                }
                .field > .input > ul > li > label {
                    display: flex;
                    flex-direction: row;
                    gap: 1rem;
                }
                .field > .input > ul > li > label.hs-form-booleancheckbox-display {
                    gap: 0;
                }
                .field > ul > li, .field > .input > ul > li{
                    list-style: none;
                    text-transform: none;
                    font-size: 1rem;
                }
                .field > .input > select{
                    border-radius: 0.75rem;
                    padding: 0.75rem;
                    width: -webkit-fill-available;
                    background-color: #eff0f3;
                    border: unset;
                }
                .hs_TICKET\.hs_file_upload {
                    background-color: white;
                    border: 2px dashed #eff0f3;
                    border-radius: 0;
                    flex-direction: column;
                    padding: 3rem 1.75rem;
                }
                .field.hs_TICKET\.hs_file_upload > .input > input{
                    background-color: white;
                    padding-left: 20%;
                }
                .field.hs_TICKET\.hs_file_upload > .input {
                    width: unset;
                    margin: auto !important;
                }
                .field.hs_TICKET\.hs_file_upload > label{
                    width: unset;
                    margin: auto;
                    padding-right: 1rem;
                }
                .hs_TICKET\.hs_file_upload legend{
                    text-align: center;
                }

                .hs_filer {
                    background-color: white;
                    border: 2px dashed #eff0f3;
                    border-radius: 0;
                    flex-direction: column;
                    padding: 3rem 1.75rem;
                }
                .field.hs_filer > .input > input{
                    background-color: white;
                    padding-left: 20%;
                }
                .field.hs_filer > .input {
                    width: unset;
                    margin: auto;
                }
                .field.hs_filer > label{
                    width: unset;
                    margin: auto;
                    position: unset;
                }

                .field.hs_filer > legend{
                    max-width: 280px;
                    margin: auto;
                    text-align: center;
                }

                .hs_submit > .actions > input[type=submit]{
                    background-color: #FBDEC9;
                    border: unset;
                    color: black;
                    border-radius: 20px;
                    padding: 1rem 1.25rem;
                    font-size: .85rem;
                    font-weight: bold;
                }

                .hs_submit > .actions > input[type=submit]:hover{
                    background-color: #ebccb5;
                }

                form legend.hs-field-desc{
                    color: #6c757d;
                    font-size: .75em !important;
                    font-weight: 400 !important;
                    margin-top: 0.25rem;
                    text-transform: none;
                }

                form ul.no-list.hs-error-msgs.inputs-list{
                    padding: 0;
                }

                form label.hs-error-msg.hs-main-font-element {
                    color: rgba(220,53,69, 1)!important;
                    font-size: .85rem!important;
                    margin-top: 0.5rem!important;
                }

                @media screen and (max-width: 1200px){
                    img.form-image {
                        display: none;
                    }
                }

                @media screen and (max-width: 767px){
                    .hs-fieldtype-date {
                        flex-direction: column;
                    }
                    .field > label{
                        width: unset;
                    }
                    .field.hs_TICKET\.hs_file_upload > .input > input{
                        background-color: white;
                        padding-left: 15%;
                    }
                    .field.hs_filer > .input > input{
                        background-color: white;
                        padding-left: 15%;
                    }
                }
            }

            .priceRequestForm {
                form {
                    margin-top: var(--spacer-3);
                    --form-control-border-color: transparent;
                    --form-control-padding-v:.75rem;
                    --form-control-padding-h:.75rem;

                    @include media-breakpoint-up(lg) {
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                        gap:1rem;

                        .form-group {

                            > * {
                                font-size:.85rem;
                            }

                            &.required {
                                label {
                                    &::after {
                                        display:none;
                                    }
                                }
                            }

                            + .form-group {
                                margin-top:initial;
                            }

                            &:nth-child(1)  { grid-area: 1 / 1 / 2 / 2; }
                            &:nth-child(2)  { grid-area: 1 / 2 / 2 / 3; }
                            &:nth-child(3)  { grid-area: 2 / 1 / 3 / 2; }
                            &:nth-child(4)  { grid-area: 2 / 2 / 3 / 3; }
                            &:nth-child(5)  { grid-area: 3 / 1 / 4 / 2; }
                            &:nth-child(6)  { grid-area: 4 / 1 / 5 / 3; }
                            &:nth-child(7)  { grid-area: 5 / 1 / 6 / 3; }
                            &:nth-child(8)  { grid-area: 6 / 1 / 7 / 3; }
                        }
                    }

                    label {
                        font-size:1rem;
                    }
                }
            }

            .product-cta--used {
                margin-top: var(--spacer-2);
                display:flex;
                gap: var(--spacer-1);

                .bttn {
                    padding-inline: 1.5rem;
                }

                .product-cta__reserve {
                    flex: 1;

                    a.bttn {
                        display:block;
                        font-weight:400;
                    }
                }

                .product-cta__click-and-collect {
                    flex: 1;

                    a.bttn {
                        display:block;
                        font-weight:400;
                    }
                }
            }

            .product-description {
                margin-top: var(--spacer-4);
            }

            .product-addons {
                margin-top: var(--spacer-4);
                display:grid;
                grid-template-columns: 1fr;
                gap: var(--spacer-1);
                
                @include media-breakpoint-up (sm) {
                    grid-template-columns: 1fr 1fr;
                    gap: 2rem;
                }

                ul {
                    @include reset-ul;
                    display:flex;
                    flex-direction: column;
                    gap: var(--spacer-1);

                    li {
                        @include reset-li;
                        display: flex;
                        align-items: center;
                        gap: 1rem;
                        font-size: 0.875rem;
                        
                        svg {
                            width: 1.125rem;
                            height: 1.125rem;
                        }
                        
                        /*
                        &::before {
                            @include fontawesome;
                            position:absolute;
                            left:0;
                            margin-right: 0.5rem;
                        }

                        &.addon--freeshipping {
                            &::before {
                                content:"\f48b";
                            }
                        }

                        &.addon--climatecompensation {
                            &::before {
                                content:"\f1bb";
                            }
                        }

                        &.addon--priceguarantee {
                            &::before {
                                content:"\f646";
                            }
                        }

                        &.addon--discount {
                            &::before {
                                content:"\f646";
                            }
                        }

                        &.addon--rent {
                            &::before {
                                content:"\f073";
                            }
                        }

                        &.addon--assembly {
                            &::before {
                                content:"\f0ad";
                            }
                        }
                         */
                    }
                }
            }
        }
    }
}


/*
=======================================================================
    Off Canvas - General Rules
=======================================================================
*/

.block__shop__product {
    .offcanvas {
        top: 1%;
        right: 1%;
        bottom: auto;
        border-radius: var(--border-radius-default);
        transition: all 0.6s cubic-bezier(0.68, -0.55, 0.27, 1.55);
    
        .bttn--close {
            padding:.5rem 1rem;
            position:absolute;
            top:1rem;
            right:1rem;
    
            &::after {
                @include fontawesome;
                content:"\f00d";
            }
        }
    
        .offcanvas-body {
            --bs-offcanvas-padding-x: 5rem;
            --bs-offcanvas-padding-y: 3rem;
    
            @include media-breakpoint-up(md) {
                --bs-offcanvas-width: 600px;
                --bs-offcanvas-padding-x: 8rem;
                --bs-offcanvas-padding-y: 6rem;
            }
    
            @include media-breakpoint-up(lg) {
                --bs-offcanvas-width: 800px;
                --bs-offcanvas-padding-x: 8rem;
                --bs-offcanvas-padding-y: 10rem;
            }

            ul {
                @include reset-ul;
                margin-top: var(--spacer-4);
                display:flex;
                flex-direction: column;
                gap:var(--spacer-1);
        
                li {
                    @include reset-li;
                    
                    a {
                        position:relative;
                        display:flex;
                        flex-direction: initial;
                        align-items: center;
                        gap:1rem;
                        padding:1.25rem 1.5rem;
                        border: 1px solid #EFF0F3;
                        text-decoration: none;
        
                        &:hover,
                        &:focus-visible {
                            border-color: var(--color-main-hex);
                        }                                    
                        
                        &::after {
                            @include fontawesome;
                            content:"\f105";
                            position:absolute;
                            right:1.5rem;
                            top:50%;
                            transform:translateY(-50%);
                        }                                    
        
                        .product-option__img {
                            > * {
                                height: calc(var(--bs-body-font-size) * 2);
                                object-fit: contain;
                            }
                        }
                        
                        &.disabled {
                            pointer-events: none;
                            color: grey;
                            
                            .product-option__title::after {
                                content: " (konfigurasjon ikke tilgjengelig)";
                            }
                        }
                    }
                }                            
            }
        }
    }
}


/*
=======================================================================
    Off Canvas - Select Variant
=======================================================================
*/

#offcanvas__selectVariant {
    top: 1%;
    right: 1%;
    max-height:98%;
    bottom: auto;
    border-radius: var(--border-radius-default);
    transition: all 0.6s cubic-bezier(0.68, -0.55, 0.27, 1.55);
    padding-top:1rem;
    padding-bottom:1rem;

    .offcanvas-body {
        --bs-offcanvas-padding-x: 2rem;
        --bs-offcanvas-padding-y: 1rem;

        scrollbar-width: thin; /* or auto or none */
        scrollbar-color: var(--color-custom-1-hex) var(--color-custom-3-hex); /* thumb and track color */

        /* The scrollbar track */
        &::-webkit-scrollbar {
            width: 5px; /* for vertical scrollbars */
            background: var(--color-custom-3-hex);
        }

        /* The scrollbar handle */
        &::-webkit-scrollbar-thumb {
            background: var(--bs-body-color);
            border-radius: 4px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: var(--color-custom-1-hover-hex);
        }

        ul {
            padding-right:4rem;
            li {
                padding: 0.5rem;
                border-radius: 1.25rem;

                &.active, &:has(a:hover) {
                    background: #F7F7F7;
                }

                a {
                    padding:0;
                    border:0;

                    &::after {
                        display:none;
                    }

                    .product-variant__img {
                        width: 4rem;
                        flex-shrink: 0;
                        border-radius: var(--border-radius-default);
                        overflow: hidden;

                        img {
                            aspect-ratio: 1 / 1;
                            width:100%;
                            height:100%;
                            object-fit: contain;
                            object-position: center center;
                        }
                    }

                    .product-variant__meta {
                        flex:1; /* Allows the column to take up the remaining space */

                        display:flex;
                        flex-direction: column;
                        gap:.5rem;

                        .product-variant__meta__header {
                            .product-variant__meta__header__title {
                                > * {
                                    font-size: 0.875rem;
                                    line-height: 1.25rem;
                                    font-weight: 500;
                                }
                            }
                        }

                        .product-variant__meta__footer {
                            display:flex;
                            flex-wrap:wrap;
                            align-items: center;
                            gap: 0.5rem;

                            .product-price {
                                display:flex;
                                align-items: center;
                                gap:.5rem;

                                .product-price__before {
                                    text-decoration: line-through;
                                    font-size: calc(var(--bs-body-font-size) * .85);
                                    order:2;

                                    + .product-price__now {
                                        color: #f68d68; /* Color now when on sale */
                                    }
                                }

                                .product-price__now {
                                    font-weight:500;
                                    order:1;
                                    color: #4C818F;
                                    display: inline-block;
                                    vertical-align: baseline;
                                }

                                .product-price__vat {
                                    font-size: .675rem;
                                    align-self: flex-end;
                                    order:3;
                                    display: inline-block;
                                    vertical-align: baseline;
                                }
                            }

                            .product-labels {
                                span {
                                    border:1px solid var(--label-border-color);
                                    padding: .05rem .6rem;
                                    font-family: 'Wix Madefor Display', sans-serif;
                                    font-weight:500;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}