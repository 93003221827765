.block__profile {

    .block__header {
        text-align:center;
    }

    .block__body {
        text-align:center;

        .row {
            + .row {
                margin-top: calc(var(--bs-gutter-x) * 0.5);
            }
        }

        .color {
            width:min(100%, 226px);
            margin-inline:auto;

            h2 {
                font-size:var(--bs-body-font-size);
                margin-bottom:1rem;
            }

            .color__sample {
                aspect-ratio: 1 / 1;
                width:100%;

            }
        }

        .bttn {
            width:100%;
        }
    }
}