/*.block:not(.block__shop__product__swipe) {display:none;}*/

.product__swipe {
    width:100%;
    overflow: hidden;

    .block__body {
        .block__body__wrapper {
            position:relative;

            .swiper {
                position:sticky;
                top:0;
                left:0;
                overflow: hidden;

                .swiper-wrapper {
                    .swiper-slide {
                        //margin-right:var(--bs-gutter-x);
                
                        /* Center slide text vertically */
                        display: flex;
                        height: auto;
                    }
                }
                
                .swiper-scrollbar {
                    --swiper-scrollbar-sides-offset: 0%;
                    --swiper-scrollbar-top: initial;
                    --swiper-scrollbar-bottom: initial;
                    position: initial;
                    margin-top: var(--spacer-5);

                    .swiper-scrollbar-drag {
                        background-color: var(--color-main-hex);
                    }
                }
            }    
        }
    }    
}