.block {
    &.block__form {
        .block__form__image,
        .block__form__content {
            display:flex;
            flex-direction: column;
            justify-content: center;

            .block__form__content__wrapper {
                display:flex;
                flex-direction: column;
                gap:3rem;
            }

            .text {
                color: var(--bs-body-color);
                
                .title {
                    h2 {margin:0;}
                }

                .teaser {
                    h3 {
                        font-size: var(--bs-body-font-size);
                        margin-bottom:.5rem;
                    }
                }

                .text {
                    h3 {
                        font-size: var(--bs-body-font-size);
                        margin-bottom:.5rem;
                    }                    
                }

                .cta {
                    display:flex;
                    flex-wrap: wrap;
                    gap:var(--margin-default);
                }
            }
        }
    }
}


/*
=======================================================================
    Container Width
=======================================================================
*/

.block {
    &.block__form {
        > .container {
            > .block__form__wrapper {
                display:flex;
            
                @include media-breakpoint-up(xl) {
                    display:grid;
                    grid-template-columns: 1fr 1fr;
                    grid-template-areas: "text image";
                    gap: calc(var(--margin-default)*2);
                }
    
                @include media-breakpoint-down(xl) {
                    flex-direction:  column-reverse;
                }
                
                &.single_column {
                    @include media-breakpoint-up(xl) {
                        grid-template-columns: 1fr 2fr 1fr;
                        grid-template-areas: "a text b";
                    }
                }

                .block__form__content {
                    grid-area: text;                    
                }

                .block__form__image {
                    grid-area: image;

                    @include media-breakpoint-down(xl) {
                        display:none;
                    }
                }
            }
        }

        /* Image width options */
        &.image--25 {
            .block__form__wrapper {
                grid-template-columns: 75% 25%;
            }
        }

        &.image--75 {
            .block__form__wrapper {
                grid-template-columns: 25% 75%;
            }
        }
    }
}


/*
=======================================================================
    Block Reverse Container Width
=======================================================================
*/

.block {
    &.block__form {
        &.block--reverse {
            > .container {
                > .block__form__wrapper {
                    grid-template-areas: "image text"
                }
            }
        }
    }
}


/*
=======================================================================
    Block Image Aspect Ratio
=======================================================================
*/

.block {
    &[class*="aspect-ratio--"] {

        &::after {
            display: block;
            clear: both;
            content: "";
        }
        
        .container-fluid {
            .block__form__content {

                @include media-breakpoint-up(xl) {
                    position: absolute;
                    height:100%;
                    width:100%;
                }

                .container {
                    @include media-breakpoint-up(xl) {
                        position:absolute;
                        left: auto;
                        top:50%;
                        transform:translateY(-50%);
                    }
                }
            }
            
            .block__form__image {


                @include media-breakpoint-up(xl) {

                    img {
                        height:initial;
                    }

                    position: initial;
                    top: initial;
                    right: initial;
                    bottom:initial;
                    width:50%;
                    float:right;

                    @at-root .block__form.block--reverse .container-fluid .block__form__content .text {
                        float:right;
                        padding-left: calc(var(--margin-default) * 2);
                        padding-right:0;
                    }

                    @at-root .block__form.block--reverse .container-fluid .block__form__image {
                        float:left;
                    }                    

                    > * {
                        width:initial;
                        height:initial;
                        object-fit:initial;
                    }
                }
            }
        }        
    }
}


.block {
    &.block__form {
        &.aspect-ratio--1by1 {
            .block__form__image {
                aspect-ratio: 1 / 1;
                img {
                    aspect-ratio: 1 / 1;
                }
            }
        }

        &.aspect-ratio--4by3 {
            .block__form__image {
                aspect-ratio: 4 / 3;
                img {
                    aspect-ratio: 4 / 3;
                }
            }
        }    

        &.aspect-ratio--16by9 {
            .block__form__image {
                aspect-ratio: 16 / 9;
                img {
                    aspect-ratio: 16 / 9;
                }
            }
        }    
    }
}


/*
=======================================================================
    Background Color
=======================================================================
*/

.block {
    &.block__form {

        .block__form__wrapper {

            &.gap--none {
                --text-and-image-gap:0;
            }

            .block__form__content {
                &[class*="background-color--"] {
                    .text {
                        padding: calc(var(--margin-default) * 2);
                    }
                }
            }
        }
    }
}


/*
=======================================================================
    Vertifcal Align Text
=======================================================================
*/

.block { 
    &.block__form {
        &.vertical-align--top {

            .block__form__image {
                > * {
                    height:initial;
                }
            }

            .block__form__image,
            .block__form__content {
                align-items:flex-start;
            }
        }
    }
}


/*
=======================================================================
    Custom Form Styling
=======================================================================
*/

.block__form {

    .block__form__content__header {
        .block__header__text {
            margin-top: 1rem;
        }
    }

    .block__form__content__body {
                    
        fieldset {
            + fieldset {
                margin-top: var(--margin-default);
            }

            legend {
                font-size: var(--bs-body-font-size);
                font-weight:500;
                margin-bottom: calc(var(--margin-default) / 2);
            }
        }

        .form-group {

            input,
            textarea {
                border:0;

                &::placeholder {
                    font-size: .75rem;
                }
            }

            &.form-group__wrapper {
                display:flex;
                gap:.5rem;
                flex-wrap: wrap;
                justify-content: space-between;

                .form-group {
                    margin-top:initial;
                }
            }

            &.required {

                label {
                    &::after {
                        display:none;
                    }
                }
            }
            
            .form-group__input {
                position:relative;

                label {
                    /*
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 1.75rem;
                    */
                    margin:0 0 .5rem 0;
                    text-transform: uppercase;
                    font-weight: 500;
                    font-size: .75rem;
                }

                .form-control {
                    --form-control-border-width: 0;
                    --form-control-background-color: var(--color-custom-3-hex);
                    /*padding-left: 8rem;*/
                    color: #626871;
                }

                .form-check-input + label {
                    text-transform: initial;
                    font-size:var(--bs-body-font-size);
                    left:0;
                }

                .form-check {
                    label {
                        font-weight:400;

                        strong {
                            font-weight:600;
                        }
                    }
                }
            }

            .form-group__textarea {
                position:relative;

                label {
                    /*
                    position: absolute;
                    top: 1rem;
                    left: 1.75rem;
                    */
                    margin:0 0 .5rem 0;
                    text-transform: uppercase;
                    font-weight: 600;
                    font-size: .75rem;
                }

                .form-control {
                    --form-control-border-width: 0;
                    --form-control-background-color: var(--color-custom-3-hex);
                    /*padding-left: 8rem;*/
                    color: #626871;
                }

                .form-check-textarea + label {
                    text-transform: initial;
                    font-size:var(--bs-body-font-size);
                    left:0;
                }
            }

            &.form-group__password {

                .form-group__input {

                    input {
                        padding-right:5rem;
                    }

                    button {
                        position: absolute;
                        top:50%;
                        right:2rem;
                        border:0;
                        background-color:transparent;
                        width:auto;
                    }
                }
            }

            &.form-group__check {
                label {
                    position: unset;
                    top: unset;
                    transform: unset;
                    left: unset;
                    padding-left:0;

                    &::before {
                        position:unset;
                        top:unset;
                        transform:unset;
                        margin-right:.5rem;
                    }
                }
            }

            .form-group__forgotPassword {
                a {
                    text-transform: uppercase;
                    font-weight: 500;
                    font-size: .75rem;
                }
            }

            &.form-group__submit {
                margin-top: var(--margin-default);
                
                button {
                    --padding-button-vertical: 1rem;
                    width:100%;
                }
            }
        }
        
        &:not(.custom-form) {
            .form-group {
                display: flex;
                flex-wrap: wrap;
                column-gap: 1rem;

                > * {
                    flex-grow: 1;
                }
            }
        }
    }

    .block__form__content__footer {
        margin-top: var(--margin-default);

        a {
            font-weight: 500;
        }
    }
}



/*
=======================================================================
    Label positions
=======================================================================
*/

.block__form {
    .form-group__input.label_left {
        label {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 1.75rem;
        }

        .form-control {
            padding-left: 8rem;
        }
    }

    .form-group__textarea.label_left {
        label {
            position: absolute;
            top: 2rem;
            left: 1.75rem;
            margin:0;
        }

        .form-control {
            padding-left:1.75rem;
            padding-top:3.5rem;
        }
    }
}