.block__list {
    &.block__list__manufacturers {

        .block__header {
            &__preTitle {
                a {
                    font-size:1rem;
                    text-decoration: none;

                    &:hover,
                    &:focus,
                    &:focus-visible {
                        text-decoration: underline;
                    }

                    &::before {
                        @include fontawesome;
                        content:"\f060";
                        margin-right:.75rem;
                    }
                }
            }
        }

        .block__body {
            &__header {
                ul {
                    @include reset-ul;
                    display:flex;
                    gap:1.25rem;
                    flex-wrap:wrap;

                    li {
                        @include reset-li;

                        a {
                            font-size:1rem;
                            font-weight:400;
                            text-decoration: none;

                            &:hover,
                            &:focus,
                            &:focus-visible {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }

            &__body {
                margin-top: var(--spacer-3);

                .listelement {

                    a {
                        &:hover,
                        &:focus,
                        &:focus-visible {
                            img {
                                transform: none;
                            }
                        }
                    }

                    &__media {
                        border-radius: var(--border-radius-default);
                        overflow:hidden;

                        figure {
                            &::after {
                                display:none;
                            }

                            img {
                                aspect-ratio: 4/3;
                            }
                        }
                    }

                    /*
                    .logo {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        width: 80%!important;
                        height: 30%!important;
                        transform:translate(-50%, -50%)!important;
                        object-fit: contain;
                        object-position: center;
                    }
                    */
                }
            }
        }
    }
}