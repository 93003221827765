html {
    scroll-padding-top: var(--header-height);
}

body {
    min-height: calc(var(--vh) * 100);    
    background-color: var(--color-body-background);
    display: flex;
    flex-direction: column;
    margin: 0;
}

main {
    /*padding-top: var(--header-height);*/
    position:relative;

    > .block__wrapper {
        > :first-child {margin-top: var(--header-height);padding-top:0;}
        > :last-child {padding-bottom: var(--margin-block);}

        > :first-child:last-child {margin-bottom:0;}
    }
}