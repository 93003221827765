/*.block:not(.block__list__icons) {display:none;}*/

.block__list__icons {

    .block__body {
        .list {
            --bs-gutter-y: 2rem;

            .listelement {
                display: flex;
                flex-direction: column;
                justify-content: center;

                a {
                    display: flex;
                    gap:1rem;
                    flex-direction: column;
                    background-color: var(--color-custom-3-hex);                    
                    padding: var(--spacer-3);
                    aspect-ratio: 4 / 3;
                    justify-content:center;
                    
                    .listelement__icon {
                        font-size:4rem;
                        font-weight:100;
                        line-height: 1;
                        text-align: center;
                    }
                    .listelement__body {
                        padding:0;
                        text-align: center;
                        background-color:transparent;

                        .title {
                            font-size: var(--bs-body-font-size);
                        }
                    }  
                }                
            }
        }
    }
}


/*
=======================================================================
    Font color
=======================================================================
*/


.block__list__icons {
    &.background-color--black,
    &.background-color--color1,
    &.background-color--color2,
    &.background-color--color5 {
        color: var(--color-white-hex);
    }
}