.block__shop__cart__empty {
    text-align:center;
    
    .block__header {
        padding-top: var(--spacer-5);
        
        svg {
            + h1 {
                margin-top: var(--spacer-3);
            }
        }
    }

    .block__body {
        margin-top: var(--spacer-2);
    }

    .block__footer {
        margin-top: var(--spacer-4);

        .container {
            margin-top: var(--spacer-5);
            display:flex;
            gap:var(--spacer-2);
            justify-content:center;
        }
    }
}