.block__slider {
    .block__header {
        .block__header__wrapper {
            .block__header__wrapper__left {
                display:flex;
                gap:var(--spacer-3);
                align-items:center;
            }

            .block__header__wrapper__right {
                .block__header__tools {
                    display: flex;
                    align-items: center;

                    button {
                        &.dropdown-toggle {
                            border:0;
                            background:none;
                        }
                    }
                }
            }

            .block__header__nav {
                display:flex;
                gap:1rem;
                
                button {
                    background-color: var(--color-custom-3-hex);
                    width: 40px;
                    height: 40px;
                    border-radius: 50px;
                    border: 0;

                    &:hover,
                    &:focus-visible {
                        background-color: var(--color-custom-3-hover-hex);
                    }

                    &::after {
                        width: 1rem;
                        height: 1rem;
                    }
                    
                    &.custom-prev {
                        &::after {
                            background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M7.4 273.4C2.7 268.8 0 262.6 0 256s2.7-12.8 7.4-17.4l176-168c9.6-9.2 24.8-8.8 33.9 .8s8.8 24.8-.8 33.9L83.9 232 424 232c13.3 0 24 10.7 24 24s-10.7 24-24 24L83.9 280 216.6 406.6c9.6 9.2 9.9 24.3 .8 33.9s-24.3 9.9-33.9 .8l-176-168z" fill="%230D2240"/></svg>') center no-repeat;
                        }
                    }

                    &.custom-next {
                        &::after {
                            background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M440.6 273.4c4.7-4.5 7.4-10.8 7.4-17.4s-2.7-12.8-7.4-17.4l-176-168c-9.6-9.2-24.8-8.8-33.9 .8s-8.8 24.8 .8 33.9L364.1 232 24 232c-13.3 0-24 10.7-24 24s10.7 24 24 24l340.1 0L231.4 406.6c-9.6 9.2-9.9 24.3-.8 33.9s24.3 9.9 33.9 .8l176-168z" fill="%230D2240"/></svg>') center no-repeat;
                        }
                    }
                }
            }
        }
    }

    .block__body {
        --swiper-theme-color: #000;
    
        .swiperSlider {
            width: 100%;
            height: 100%;
    
    
            .swiper-button-next::after, 
            .swiper-button-prev::after {
                content: "";
                width: 2.25rem;
                height: 2.25rem;
            }
    
            .swiper-button-prev {
                margin-left:1rem;
                
                &::after {
                    background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M18.2 273l-17-17 17-17L171.8 85.4l17-17 33.9 33.9-17 17L93.1 232 424 232l24 0 0 48-24 0L93.1 280 205.8 392.6l17 17-33.9 33.9-17-17L18.2 273z" fill="%230D2240"/></svg>') center no-repeat;
                }
            }
    
            .swiper-button-next {
                margin-right:1rem;
                
                &::after {
                    background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M429.8 273l17-17-17-17L276.2 85.4l-17-17-33.9 33.9 17 17L354.9 232 24 232 0 232l0 48 24 0 330.8 0L242.2 392.6l-17 17 33.9 33.9 17-17L429.8 273z" fill="%230D2240"/></svg>') center no-repeat;
                }
            }
        }
    
        .swiper-slide {
            text-align: center;
            font-size: 18px;
            background: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    
        .swiper-slide img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    
        .swiper-pagination {
            margin-bottom:1rem;
    
            .swiper-pagination-bullet {
                width: 30px;
                height: 30px;
                text-align: center;
                line-height: 30px;
                font-size: .875rem;
                color: #000;
                opacity: 1;
                background: rgba(0, 0, 0, 0.2);
            }
    
            .swiper-pagination-bullet-active {
                color: #fff;
                background: var(--swiper-theme-color);
            }    
        }
    }
}

