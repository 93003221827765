.block__buttons {
    .bttn {
        width: 30rem;
        max-width:100%;
    }
    .col-buttons {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;

        @include media-breakpoint-up(lg) {
            gap: 1.5rem;
        }
    }
}