/*
=======================================================================
    Text Align
=======================================================================
*/

.text-align--left {
    text-align: left;
}

.text-align--center {
    text-align: center;
}

.text-align--right {
    text-align:right;
}

.text-dark-blue {
    color: var(--color-custom-1-hex);
}


/*
=======================================================================
    Colors
=======================================================================
*/

.color--black {
    --bs-body-color: var(--color, var(--color-black-hex));

    --button-border-color: var(--color-black-hex);
    --button-background-color: var(--color-black-hex);
    --button-background-color-rgb: var(--color-black-rgb);
    --button-text-color: var(--color-white-hex);

    --button-hover-border-color: var(--color-black-hover-hex);
    --button-hover-background-color: var(--color-black-hover-hex);
    --button-hover-text-color: var(--color-white-hex);

    --button-outline-border-color: var(--color-black-hex);
    --button-outline-text-color: var(--color-black-hex);

    --button-hover-outline-border-color: var(--color-black-hex);
    --button-hover-outline-background-color: var(--color-black-hex);
    --button-hover-outline-text-color: var(--color-white-hex);
}

.color--white {
    --bs-body-color: var(--color, var(--color-white-hex));

    --button-border-color: var(--color-white-hex);
    --button-background-color: var(--color-white-hex);
    --button-background-color-rgb: var(--color-white-rgb);
    --button-text-color: var(--color-black-hex);

    --button-hover-border-color: var(--color-white-hover-hex);
    --button-hover-background-color: var(--color-white-hover-hex);
    --button-hover-text-color: var(--color-black-hex);

    --button-outline-border-color: var(--color-white-hex);
    --button-outline-text-color: var(--color-white-hex);

    --button-hover-outline-border-color: var(--color-white-hex);
    --button-hover-outline-background-color: var(--color-white-hex);
    --button-hover-outline-text-color: var(--color-black-hex);
}

/* Dark Blue */
.color--color1 {
    --bs-body-color: var(--color, var(--color-custom-1-hex));

    --button-border-color: var(--color-custom-1-hex);
    --button-background-color: var(--color-custom-1-hex);
    --button-background-color-rgb: var(--color-custom-1-rgb);
    --button-text-color: var(--color-white-hex);

    --button-hover-border-color: var(--color-custom-1-hover-hex);
    --button-hover-background-color: var(--color-custom-1-hover-hex);
    --button-hover-text-color: var(--color-white-hex);

    --button-outline-border-color: var(--color-custom-1-hex);
    --button-outline-text-color: var(--color-custom-1-hex);

    --button-hover-outline-border-color: var(--color-custom-1-hex);
    --button-hover-outline-background-color: var(--color-custom-1-hex);
    --button-hover-outline-text-color: var(--color-white-hex);
}

/* Light Blue */
.color--color2 {
    --bs-body-color: var(--color, var(--color-custom-2-hex));

    --button-border-color: var(--color-custom-2-hex);
    --button-background-color: var(--color-custom-2-hex);
    --button-background-color-rgb: var(--color-custom-2-rgb);
    --button-text-color: var(--color-white-hex);

    --button-hover-border-color: var(--color-custom-2-hover-hex);
    --button-hover-background-color: var(--color-custom-2-hover-hex);
    --button-hover-text-color: var(--color-white-hex);

    --button-outline-border-color: var(--color-custom-2-hex);
    --button-outline-text-color: var(--color-custom-2-hex);

    --button-hover-outline-border-color: var(--color-custom-2-hex);
    --button-hover-outline-background-color: var(--color-custom-2-hex);
    --button-hover-outline-text-color: var(--color-white-hex);
}

/* Dark White */
.color--color3 {
    --bs-body-color: var(--color, var(--color-custom-3-hex));

    --button-border-color: var(--color-custom-3-hex);
    --button-background-color: var(--color-custom-3-hex);
    --button-background-color-rgb: var(--color-custom-3-rgb);
    --button-text-color: #0D2240;

    --button-hover-border-color: var(--color-custom-3-hover-hex);
    --button-hover-background-color: var(--color-custom-3-hover-hex);
    --button-hover-text-color: #0D2240;

    --button-outline-border-color: var(--color-custom-3-hex);
    --button-outline-text-color: #0D2240;

    --button-hover-outline-border-color: var(--color-custom-3-hex);
    --button-hover-outline-background-color: var(--color-custom-3-hex);
    --button-hover-outline-text-color: var(--color-white-hex);
}

/* Light Orange */
.color--color4 {
    --bs-body-color: var(--color, var(--color-custom-4-hex));

    --button-border-color: var(--color-custom-4-hex);
    --button-background-color: var(--color-custom-4-hex);
    --button-background-color-rgb: var(--color-custom-4-rgb);
    --button-text-color: var(--color-main-hex);

    --button-hover-border-color: var(--color-custom-4-hover-hex);
    --button-hover-background-color: var(--color-custom-4-hover-hex);
    --button-hover-text-color: var(--color-main-hex);

    --button-outline-border-color: var(--color-custom-4-hex);
    --button-outline-text-color: var(--color-custom-4-hex);

    --button-hover-outline-border-color: var(--color-custom-4-hex);
    --button-hover-outline-background-color: var(--color-custom-4-hex);
    --button-hover-outline-text-color: var(--color-white-hex);
}

.color--color5 {
    --bs-body-color: var(--color, var(--color-custom-5-hex));

    --button-border-color: var(--color-custom-5-hex);
    --button-background-color: var(--color-custom-5-hex);
    --button-background-color-rgb: var(--color-custom-5-rgb);
    --button-text-color: var(--color-white-hex);

    --button-hover-border-color: var(--color-custom-5-hover-hex);
    --button-hover-background-color: var(--color-custom-5-hover-hex);
    --button-hover-text-color: var(--color-white-hex);

    --button-outline-border-color: var(--color-custom-5-hex);
    --button-outline-text-color: var(--color-custom-5-hex);

    --button-hover-outline-border-color: var(--color-custom-5-hex);
    --button-hover-outline-background-color: var(--color-custom-5-hex);
    --button-hover-outline-text-color: var(--color-white-hex);
}

.color--color7 {
    --bs-body-color: var(--color, var(--color-custom-7-hex));

    --button-border-color: var(--color-custom-7-hex);
    --button-background-color: var(--color-custom-7-hex);
    --button-background-color-rgb: var(--color-custom-7-rgb);
    --button-text-color: var(--color-white-hex);

    --button-hover-border-color: var(--color-custom-7-hover-hex);
    --button-hover-background-color: var(--color-custom-7-hover-hex);
    --button-hover-text-color: var(--color-white-hex);

    --button-outline-border-color: var(--color-custom-7-hex);
    --button-outline-text-color: var(--color-custom-7-hex);

    --button-hover-outline-border-color: var(--color-custom-7-hex);
    --button-hover-outline-background-color: var(--color-custom-7-hex);
    --button-hover-outline-text-color: var(--color-white-hex);
}


/*
=======================================================================
    Background Color with optional inline value
=======================================================================
*/

.background-color--white,
.background-color--white .block__back {
    background-color: var(--background-color, var(--color-white-hex));
}

.background-color--black,
.background-color--black .block__back {
    background-color: var(--background-color, var(--color-black-hex));
}

.background-color--color1,
.background-color--color1 .block__back {
    background-color: var(--background-color, var(--color-custom-1-hex));
}

.background-color--color2,
.background-color--color2 .block__back {
    background-color: var(--background-color, var(--color-custom-2-hex));
}

.background-color--color3,
.background-color--color3 .block__back {
    background-color: var(--background-color, var(--color-custom-3-hex));
}

.background-color--color4,
.background-color--color4 .block__back {
    background-color: var(--background-color, var(--color-custom-4-hex));
}

.background-color--color5,
.background-color--color5 .block__back {
    background-color: var(--background-color, var(--color-custom-5-hex));
}