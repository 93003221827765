form {
    --form-control-border-color: transparent;
    --form-control-padding-v: .75rem;
    --form-control-padding-h: .75rem;
}

.form-group {
    > * {
        font-size: 0.85rem;
    }

    // Margin between form groups
    + .form-group,
    + .row {
        margin-top: calc(var(--margin-default) / 2);

        &:has(fieldset) {
            margin-top: var(--margin-default);
        }
    }

    /* Global styling for all form controls */
    .form-control,
    .form-select {
        background-color:var(--form-control-background-color);
        padding: var(--form-control-padding-v) var(--form-control-padding-h) var(--form-control-padding-v) var(--form-control-padding-h);
        border-width: var(--form-control-border-width);
        border-color: var(--form-control-border-color);
        border-radius: var(--form-control-border-radius);
    }

    /* Global focus styling */
    input:focus-visible {
        @include reset-box-shadow;
        border-style:solid;
        border-color:var(--form-control-focus-visible-outline-color);
    }

    input:focus-visible + input + label {
        outline-style:solid;
        outline-width: var(--form-control-focus-visible-outline-width);
        outline-color:var(--form-control-focus-visible-outline-color);
    }

    input:focus-visible + label {
        outline-style:solid;
        outline-width: var(--form-control-focus-visible-outline-width);
        outline-color:var(--form-control-focus-visible-outline-color);
    }            

    select {
        &:focus {
            @include reset-box-shadow;
        }
        &:focus-visible {
            @include reset-box-shadow;
            border-style:solid;
            border-width: var(--form-control-focus-visible-outline-width);
            border-color:var(--form-control-focus-visible-outline-color);
        }
    }

    textarea:focus-visible {
        @include reset-box-shadow;
        border-style:solid;
        border-width: var(--form-control-focus-visible-outline-width);
        border-color:var(--form-control-focus-visible-outline-color);
    }
    
    &.form-group__files {
        width: 100%;
        height: 150px;
        border: 2px dashed var(--color-custom-3-hex);
        display: grid !important;
        place-items: center;
        position: relative;
        overflow: hidden;

        label.form-label-button {
            margin: 0;
            padding: 0.5rem 1rem;
            cursor:pointer;
            gap: 0.5rem;
            align-items: center;
            justify-content: center;

            &::before {
                content:url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.001 14.9425L12.001 3M12.001 3L7.06835 7.56318M12.001 3L16.9336 7.56318M4 18.9663V20.3827C4.40471 20.778 4.95361 21 5.52595 21H18.4741C19.0464 21 19.5953 20.778 20 20.3827V18.9663' stroke='%230D2240' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
                width: 24px;
                height: 24px;
                vertical-align: middle;
            }
        }

        input[type="file"] {
            opacity: 0;
            overflow: hidden;
            position: absolute;
            inset: 0;

            &:focus + label,
            + label:hover {
                background-color: var(--color-custom-3-hex);
                outline: 1px dotted #000;
                outline: -webkit-focus-ring-color auto 5px;
            }
        }
        
        // needed because of Formie plugin's fixed HTML structure: 
        &:has(input[type="file"]:focus) label,
        label:hover {
            background-color: var(--color-custom-3-hex);
            outline: 1px dotted #000;
            outline: -webkit-focus-ring-color auto 5px;
        }

        p:last-child {
            margin-bottom: 0;
        }
    }
    
    label.label-file-before {
        margin: 0 0 .5rem 0;
        text-transform: uppercase;
        font-weight: 500;
        font-size: .75rem;
    }

    /* Custom Radio Buttons and Checkboxes */
    .form-check {
        padding-left:0;
        margin:0;

        label {
            padding:0 3px;
            border: 2px solid transparent;
            width:100%;
            padding-left:2rem;
            position:relative;
            font-weight:300;
            cursor: pointer;

            @include media-breakpoint-down(sm) {
                font-size: 1rem;
            }                            

            &::before {
                content: '';
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
                width: 1rem;
                height: 1rem;
            }

            span {
                + span {
                    opacity:.6;
                    font-weight:400;
                    padding-left:5px;
                }
            }
        }        

        input {
            border: 0;
            margin: 0;
            opacity: 0;
            outline: 0;
            overflow: hidden;
            padding: 0;
            position: absolute;

            &.form-check-input {
                &.is-valid {
                    ~ .form-check-label {
                        color: var(--bs-body-color);
                    }
                }
            }
            
            &:checked {
                + label::before,
                + input + label::before {
                        color:var(--color-form-check-active-hex);
                        opacity: 1;
                }
            }
        }


        /* Adding icons for radio buttons */
        input[type=radio] {
            + label {             
                &::before {
                    background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z" fill="%230d2240"/></svg>') center no-repeat;
                }
            }

            &:checked {
                + label {
                    &::before {
                        background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" fill="%230d2240"/></svg>') center no-repeat;
                    }
                }
            }            
        }

        /* Adding icons for checkboxes */
        input[type=checkbox] {
            + label {             
                &::before {
                    background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M384 80c8.8 0 16 7.2 16 16V416c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V96c0-8.8 7.2-16 16-16H384zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64z" fill="%230d2240"/></svg>') center no-repeat;
                }
            }
        }

        input[type=checkbox] {
            &:checked {
                + label {
                    &::before {
                        background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M64 80c-8.8 0-16 7.2-16 16V416c0 8.8 7.2 16 16 16H384c8.8 0 16-7.2 16-16V96c0-8.8-7.2-16-16-16H64zM0 96C0 60.7 28.7 32 64 32H384c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM337 209L209 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L303 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" fill="%230d2240"/></svg>') center no-repeat;
                    }
                }
            }            
        }
    }


    /* Required fields */
    &.required {
        label,
        fieldset legend {
            display: flex;
            gap: 0.25rem;
            align-items: center;
            
            &::after {
                content: '';
                width: 0.375rem;
                height: 0.375rem;
                color: var(--color-form-required-hex);
                background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M208 0l96 0 0 172.9L453.7 86.4l48 83.1L352 256l149.7 86.4-48 83.1L304 339.1 304 512l-96 0 0-172.9L58.3 425.6l-48-83.1L160 256 10.3 169.6l48-83.1L208 172.9 208 0z" fill="%23f00"/></svg>');
                flex-shrink: 0;
            }
        }

        .form-check {
            label {
                &::after{
                    display:none;
                }
            }
        }
    }
}    

/* Was Validated */
.was-validated {
    .form-group {
        .form-control:valid,
        .form-select:valid {
            border-color: var(--form-control-validation-valid);
        }

        .form-check-input {
            &:valid {
                ~ label {
                    color:#000;
                }

                &:checked {
                    ~ label {
                        color:#000;
                        &::before {
                            color: var(--form-control-validation-valid);
                        }
                    }
                }
            }
        }
    }
}   


/*
=======================================================================
    Custom styling to display validation messages because of different container structure
=======================================================================
*/

.was-validated .form-group__input .form-control:invalid ~ .form-group__validation .invalid-feedback,
.was-validated .form-group__input .form-control:valid ~ .form-group__validation .valid-feedback {
    display: block;
}

