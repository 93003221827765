.block__manufacturer {

    .block__header {
        &__preTitle {
            a {
                font-size:1rem;
                text-decoration: none;

                &:hover,
                &:focus,
                &:focus-visible {
                    text-decoration: underline;
                }

                &::before {
                    @include fontawesome;
                    content:"\f060";
                    margin-right:.75rem;
                }
            }
        }

        &__title {
            h1 {
                font-size: var(--bs-body-font-size);
                font-weight: 500;
                margin-top: var(--spacer-3);
            }
        }
    }

    .block__body {
        figure {
            position:relative;
            aspect-ratio: 4 / 3;
            border-radius: var(--border-radius-default);
            overflow:hidden;

            img {
                width:100%;
                height:100%;
                object-fit: cover;

                &.logo {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 80%!important;
                    height: 30%!important;
                    transform:translate(-50%, -50%)!important;
                    object-fit: contain;
                    object-position: center;
                }
            }
        }

        .text {
            position:relative;
            overflow: hidden;
            height:100%;
            max-height:100%;
            
            @include media-breakpoint-down(lg) {
                max-height:8rem!important;

                &.text--expanded {
                    max-height:unset!important;
                }
            }

            &.text--truncated {

                &:not(.text--expanded) {

                    &::after {
                        content:"";
                        display:block;
                        width:100%;
                        height:40px;
                        background: rgb(255,255,255);
                        background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
                        position:absolute;
                        pointer-events: none;
                        bottom:0;
                        left:0;
                        right:0;
                    }
                }
            }            

            // &.expanded {
            //     &::after {
            //         display:none;
            //     }
            // }

            // &.truncate-text {
            //     display: -webkit-box;
            //     -webkit-line-clamp: 5;
            //     -webkit-box-orient: vertical;  
            //     overflow: hidden;
            //     white-space: normal;

            //     &.expanded {
            //         display: block;
            //         overflow: visible;
            //         -webkit-line-clamp: unset;
            //         -webkit-box-orient: unset;
            //         max-height:unset!important;

            //         + button {
            //             display:none!important;
            //         }
            //     }                            
            // }

            + .read-more {
                display: none;
    
                .read-more-link {
                    cursor: pointer;
                    font-weight:600;
                    margin-top:.5rem;
                }
            }
        }

        #expand-text {
            border:0;
            padding:0;
            background-color:transparent;
        }
    }    
}