.block__list {
    &.block__product__list,
    &.block__shop__product__list {

        .offcanvas {
            --bs-offcanvas-width: 400px;
            top: 1%;
            bottom: auto;
            left: 1%;
            max-height:98%;
            border-radius: var(--border-radius-default);
            transition: all 0.6s cubic-bezier(0.68, -0.55, 0.27, 1.55);
            padding-top:1rem;
            padding-bottom:1rem;
            z-index:10000!important;

            @media (min-width: 992px) {
                --bs-offcanvas-width: 400px
            }

            .offcanvas-body {
                --bs-offcanvas-padding-x: 2rem;
                --bs-offcanvas-padding-y: 1rem;

                display:flex;
                flex-direction: column;
                gap:var(--spacer-2);

                h2 {
                    font-size: var(--font-size-md);
                    text-transform: uppercase;
                }

                .link--resetFilter {
                    font-size:1rem;
                }
                
                /* Not used anymore?
                .filters-active {
                    ul {
                        @include reset-ul;
                        display:flex;
                        flex-wrap:wrap;
                        gap: .5rem;

                        li {
                            @include reset-li;

                            a {
                                background-color: var(--color-custom-3-hex);
                                border-radius:50px;
                                padding:.2rem .65rem;
                                font-size:.875rem;
                                display:inline-block;
                                text-decoration: none;

                                &::after {
                                    @include fontawesome;
                                    content:"\f00d";
                                    padding-left:.5rem;
                                    font-size: .75rem;
                                }

                                &:hover,
                                &:focus-visible {
                                    background-color: var(--color-custom-3-hover-hex);
                                }
                            }
                        }
                    }
                }
                 */

                .accordion {
                    --bs-accordion-border-width:0;
                    --bs-accordion-active-color: var(--bs-body-color);
                    --bs-accordion-active-bg: transparent;
                    --bs-accordion-btn-padding-x:0;
                    --bs-accordion-btn-focus-box-shadow: none;

                    margin-top: var(--spacer-4);

                    .accordion-item {

                        .accordion-header {
                            .accordion-button {
                                color: var(--bs-body-color);
                                text-transform:uppercase;

                                &::after {
                                    content: '';
                                    background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M241 337c-9.4 9.4-24.6 9.4-33.9 0L47 177c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l143 143L367 143c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9L241 337z" fill="%230D2240"/></svg>') center no-repeat;
                                    width: 1rem;
                                    height: 1rem;
                                }
                            }
                        }

                        .accordion-body {
                            --bs-accordion-body-padding-x:0;
                        }
                    }
                }
            }

            .offvcanvas-footer {
                margin-top: var(--spacer-4);
            }
        }

        .filter--category {

            .form-group {
                fieldset {
                    display:flex;
                    flex-direction: column;
                    gap:1rem;
                }
            }

            ul {
                @include reset-ul;

                ul {
                    display:none;
                    margin-left:2rem;
                    margin-top:.5rem;

                    .form-check {
                        > .form-check-label {
                            span {
                                font-size:.9rem;

                            }
                        }
                    }
                }


                li {
                    @include reset-li;

                    .form-check {
                        position:relative;
                        border-bottom:1px solid #EFF0F3;
                        padding-top:.5rem;
                        padding-bottom:.5rem;

                        > .form-check-label {
                            span {
                                font-size:1rem;
                                color: var(--bs-body-color);
                            }
                        }
                    }

                    &:first-child {
                        .form-check {
                            padding-top:0;
                        }
                    }

                    &.has-children {
                        > .form-check {
                            > .form-check-label {
                                span {
                                    color: var(--bs-body-color);

                                    &::after {
                                        @include transition-transform;
                                        font-size: 1rem;
                                        content:"+";
                                        position:absolute;
                                        right:0;
                                    }
                                }
                            }
                        }

                        &.open {
                            > .form-check {
                                > .form-check-label {
                                    span {
                                        &::after {
                                            transform: rotate(-45deg);
                                        }
                                    }
                                }
                            }
                        }
                    }

                    &.open {
                        > ul {
                            display: block;
                        }

                        + li {
                            margin-top:.75rem;
                        }
                    }
                }
            }
        }

        .range-slider {

            + .range-slider {
                margin-top:1rem;
            }

            .noUi-target {
                margin-left: 6px;
                margin-right: 6px;
                position: relative;
            }

            .range-slider__title {
                font-size: .75rem;
                text-transform: uppercase;
                color: #a6a6a6;
                margin-bottom:.5rem;
            }

            .noUi-horizontal {
                height:4px;

                .noUi-handle {
                    background: #fff;
                    border: 2px solid var(--bs-body-color);
                    border-radius: 100%;
                    height:12px;
                    width:12px;
                    right:-6px;
                    top:-4px;

                    &::before,
                    &::after {
                        content:initial;
                    }
                }

                .noUi-touch-area {
                    bottom: -10px;
                    cursor: pointer;
                    height: auto;
                    left: -10px;
                    position: absolute;
                    right: -10px;
                    top: -10px;
                    width: auto;
                }
            }

            .noUi-connect {
                background: var(--bs-body-color);
            }

            .labels {
                display:flex;
                justify-content: space-between;
                > * {
                    font-size: .75rem;
                    text-transform: uppercase;
                    color: #a6a6a6;
                    margin-top:.5rem;
                }
            }
        }

        .filter--color {
            fieldset {
                display:grid;
                grid-template-columns: repeat(5, 1fr);

                .form-check {
                    padding-left:0;
                    flex-basis: 50%;
                    flex-grow: 1;
                    flex-shrink: 1;

                    input {
                        @include hide-input;

                        &:checked ~ label .label-icon > span {
                            border-color: #000;
                        }

                        /*
                        &:checked ~ label .label-icon > span::before,
                        &:hover ~ label .label-icon > span::before {
                            border-color: #000;
                        }

                        &:checked ~ label .label-icon > span::after {
                            content:"";
                            border: 2px solid #f3f3f3;
                            border-radius: 100%;
                            height: 25px;
                            position:absolute;
                            left: 1px;
                            top: 1px;
                            -webkit-transform: none;
                            -ms-transform: none;
                            transform: none;
                            width: 25px;
                        }
                        */
                    }

                    label {
                        position:relative;
                        padding-left:0;
                        cursor: pointer;
                        display:flex;
                        flex-direction: column;
                        text-align: center;
                        gap:.25rem;

                        span {

                            &.label-icon {
                                span {
                                    width:25px;
                                    height:25px;
                                    border-radius:25px;
                                    display:block;
                                    margin: 0 auto;
                                    background-color: var(--color);
                                    border: 1px solid #e5e5e5;

                                    &.multi {
                                        position:relative;
                                        background:transparent;

                                        span {
                                            width:13px;
                                            height:13px;
                                            position:absolute;
                                            transform: translate(-50%, -50%);
                                            top: 50%;
                                            left: 50%;

                                            &:nth-child(1) {
                                                top:30%;
                                                background-color: #ff0002;
                                            }

                                            &:nth-child(2) {
                                                top:60%;
                                                left:35%;
                                                background-color: #0f73ad;

                                            }
                                            &:nth-child(3) {
                                                top:60%;
                                                left:65%;
                                                background-color: #fd3;
                                            }
                                        }
                                    }
                                }
                            }

                            &.label-text {
                                font-size:.875rem;
                                padding-left:0;
                            }
                        }


                        &::before {
                            display: none;
                        }
                    }
                }
            }
        }

        .filter--custom {
            .form-group {
                fieldset {
                    display:flex;
                    gap:.5rem;
                    flex-wrap:wrap;
                    align-items: flex-start;

                    .form-check {
                        display:inline-block;

                        input:checked {
                            + label {
                                border-color: var(--color-main-hex);
                            }
                        }

                        label {
                            border-color: var(--color-custom-3-hex);
                            padding: .35rem .75rem;
                            display:flex;
                            align-items: flex-start;

                            &:hover,
                            &:focus-visible {
                                border-color: var(--color-custom-3-hover-hex);
                            }

                            span {
                                font-size:1rem;

                                + span {
                                    font-size:.6rem;
                                }
                            }

                            &::before {
                                display: none;
                            }
                        }
                    }
                }
            }
        }

        .block__header {

            .block__header__header {                

                .block__header__header__title {
                    font-weight:500;
                }                

                .block__header__header__intro {
                    margin-top: var(--spacer-3);
                    
                    .truncate-text {
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;  
                        overflow: hidden;
                        white-space: normal;

                        &.expanded {
                            display: block;
                            overflow: visible;
                            -webkit-line-clamp: unset;
                            -webkit-box-orient: unset;

                            + #toggleReadMore {
                                display:none;
                            }
                        }                            
                    }
                    
                    #toggleReadMore {
                        cursor: pointer;
                        background:none;
                        border:0;
                        padding:0;
                    }
                }
            }

            .block__header__footer {
                display:flex;
                flex-wrap:wrap;
                flex-direction: column;
                gap:var(--spacer-1);
                justify-content: flex-start;
                margin-top:var(--spacer-5);
            }

            .block__header__wrapper {
                width:100%;
                display:flex;
                justify-content: space-between;
                align-items:center;
                flex-wrap: wrap;
                gap: var(--spacer-1);

                .block__header__wrapper__left {
                    display:flex;
                    gap:var(--spacer-3);
                    align-items:center;

                    .block__header__filter {

                        button {
                            display:flex;
                            gap:.5rem;
                            align-items: center;

                            .button-numb {
                                width:1.5rem;
                                height:1.5rem;
                                border-radius:5rem;
                                background-color: var(--color-custom-4-hex);
                                display:flex;
                                font-size:.625rem;
                                font-weight:700;
                                justify-content: center;
                                align-items: center;
                            }

                            &:hover,
                            &:focus,
                            &:focus-visible {
                                .button-numb {
                                    background-color: var(--color-white-hex);
                                    color: var(--button-outline-text-color);
                                }
                            }
                        }
                    }
                }

                .block__header__wrapper__right {
                    .block__header__tools {
                        display: flex;
                        align-items: center;

                        button {
                            &.dropdown-toggle {
                                border:0;
                                background:none;
                                font-weight:600;
                                color: var(--color-main-hex);
                                display: inline-flex;
                                gap: 0.375rem;
                                align-items: center;

                                &::after {
                                    content: '';
                                    width: 1rem;
                                    height: 1rem;
                                    background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M241 337c-9.4 9.4-24.6 9.4-33.9 0L47 177c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l143 143L367 143c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9L241 337z" fill="%230D2240"/></svg>') center no-repeat;
                                    border: 0;
                                    margin: 0;
                                }
                            }
                        }
                    }
                }

                .block__header__nav {
                    display:flex;
                    gap:1rem;
                    
                    button {
                        background-color: var(--color-custom-3-hex);
                        width: 40px;
                        height: 40px;
                        border-radius: 50px;
                        border: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        &:hover,
                        &:focus-visible {
                            background-color: var(--color-custom-3-hover-hex);
                        }

                        &::after {
                            content: '';
                            width: 1.125rem;
                            height: 1.125rem;
                        }

                        &.custom-prev {
                            &::after {
                                background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M7.4 273.4C2.7 268.8 0 262.6 0 256s2.7-12.8 7.4-17.4l176-168c9.6-9.2 24.8-8.8 33.9 .8s8.8 24.8-.8 33.9L83.9 232 424 232c13.3 0 24 10.7 24 24s-10.7 24-24 24L83.9 280 216.6 406.6c9.6 9.2 9.9 24.3 .8 33.9s-24.3 9.9-33.9 .8l-176-168z" fill="%230D2240"/></svg>') center no-repeat;
                            }
                        }

                        &.custom-next {
                            &::after {
                                background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M440.6 273.4c4.7-4.5 7.4-10.8 7.4-17.4s-2.7-12.8-7.4-17.4l-176-168c-9.6-9.2-24.8-8.8-33.9 .8s-8.8 24.8 .8 33.9L364.1 232 24 232c-13.3 0-24 10.7-24 24s10.7 24 24 24l340.1 0L231.4 406.6c-9.6 9.2-9.9 24.3-.8 33.9s24.3 9.9 33.9 .8l176-168z" fill="%230D2240"/></svg>') center no-repeat;
                            }
                        }                        
                    }
                }
            }

            .block__header__filter-active {
                ul {
                    @include reset-ul;
                    display:flex;
                    flex-wrap:wrap;
                    gap: .5rem;

                    li {
                        @include reset-li;

                        a {
                            background-color: var(--color-custom-3-hex);
                            border-radius:50px;
                            padding:.2rem .65rem;
                            font-family: 'Wix Madefor Display', sans-serif;
                            font-size:.875rem;
                            font-weight: 500;
                            display:inline-flex;
                            gap: 0.5rem;
                            align-items: center;
                            text-decoration: none;

                            &::after {
                                content: '';
                                margin-left: .5rem;
                                width: 0.75rem;
                                height: 0.75rem;
                                background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M345 137c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-119 119L73 103c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l119 119L39 375c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l119-119L311 409c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-119-119L345 137z" fill="%230D2240"/></svg>') center no-repeat;
                            }

                            &:hover,
                            &:focus-visible {
                                background-color: var(--color-custom-3-hover-hex);
                            }
                        }

                        &:last-child {
                            a {
                                background-color:transparent;
                                text-decoration: underline;
                                
                                &::after {
                                    display:none;
                                }
                            }
                        }
                    }
                }
            }
        }

        .block__body {
            .list {
                article {
                    display:flex;
                    flex-wrap: wrap;
                    flex-direction:column;
                    gap:.5rem;

                    &:hover,
                    &:focus,
                    &:focus-visible {
                        
                        .product-title a {
                            text-decoration:underline;
                        }

                        /* Hover effect on product image */
                        figure {
                            &::after {
                                border-radius: var(--border-radius-default);
                            }

                            img {
                                border-radius: var(--border-radius-default);
                            }
                        }

                        // Only apply variant hover effect on larger viewports
                        @include media-breakpoint-up(md) {
                            .product-variants {
                                opacity:1!important;
                            }
                            .product-variants__label {
                                opacity: 0;
                                pointer-events: none;
                            }
                        }
                    }

                    .product-header {
                        position:relative;
                        width:100%;
                        aspect-ratio: 1 / 1;

                        a {

                            figure {
                                position:relative;
                                overflow:hidden;
                                margin:0;

                                img {
                                    @include transition-border-radius;
                                    display: block;
                                    aspect-ratio: 1 / 1;
                                    width: 100%;
                                    height: 100%;
                                    object-fit: contain;
                                    

                                    /* Optional second image for hover effect */
                                    + img {
                                        @include transition-opacity;
                                        opacity: 0;
                                        position:absolute;
                                        top:0;
                                        left:0;
                                        right:0;
                                        bottom:0;
                                        border-radius: var(--border-radius-default);
                                        object-fit: cover;

                                        @at-root .block__list .block__body .list__product .listelement:hover .product-header figure img + img {
                                            opacity:1;
                                        }
                                    }
                                }

                                &::after {
                                    @include transition;
                                    @include image-overlay;
                                }
                            }
                        }
                        
                        .admin-edit-button-wrapper {
                            display: flex;
                            gap: 0.5rem;
                            position: absolute;
                            right: 1rem;
                            top: 1rem;
                            z-index: 1;
                        }

                        .product-labels {
                            position: absolute;
                            top: .5rem;
                            left: .5rem;
                            flex-direction: column;
                        }

                        .product-enviroLabels {
                            position: absolute;
                            top: .5rem;
                            right: .5rem;
                            z-index: 1;
                            display: flex;
                            flex-direction: column;
                            gap:.5rem;
                            align-items:flex-end;

                            .label {
                                overflow: hidden;
                                white-space:nowrap;
                                display:inline-flex;
                                align-items: center;
                                align-self:flex-end;
                                border-radius:25px;
                                background-color: #f8f7f7;
                                
                                .product-label-icon {
                                    @include transition;
                                    display:inline-block;
                                    line-height:1;
                                    padding: 0.5rem;

                                    @include media-breakpoint-down(sm) {
                                        padding:.25rem;
                                    }

                                    svg {
                                        @include media-breakpoint-down(sm) {
                                            max-width:15px;
                                        }
                                    }
                                }

                                .product-label-text {
                                    opacity:0;
                                    font-family: 'Wix Madefor Display', sans-serif;
                                    font-size:.675rem;
                                    font-weight:600;
                                    color:#666;
                                    max-width:0;
                                    display:inline-block;
                                    text-decoration: none;
                                    transition: max-width 0.3s ease-out 0.1s, opacity 0.3s ease-out 0.1s, color;

                                    > span {
                                        float: left;
                                        display: block;
                                        padding-left:1rem;
                                        padding-right:.5rem;
                                    }
                                }

                                &:hover {

                                    a {
                                        pointer-events: none;
                                        opacity: 1;
                                        max-width: 150px;
                                        transition: max-width .3s ease-out .1s, opacity .3s ease-out .1s, color .2s;    
                                        
                                        > span {
                                            padding-right:.5rem;
                                        }
                                    }
                                    
                                    .product-label-icon {
                                        padding-right:.5rem;
                                    }
                                }
                            }
                        }

                        .product-variants__label {
                            @include transition-opacity;
                            position:absolute;
                            bottom: .75rem;
                            left: .75rem;
                            font-weight:700;
                            font-size:.75rem;
                            line-height: 1rem;
                            text-transform: uppercase;
                            color: var(--color-custom-6-hex);
                            display: flex;
                            align-items: center;
                            gap: 0.25rem;
                            letter-spacing: 0.03em;

                            @at-root .block__shop__product__list .block__body .list .listelement:hover .product-header .product-variants__label {
                                opacity:0;
                                pointer-events: none;
                            }
                            
                            &::before {
                                content: '';
                                width: 1rem;
                                height: 1rem;
                                background: url('data:image/svg+xml,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.99922 6.58263C2.37638 7.09233 1.19922 8.60845 1.19922 10.3995C1.19922 12.6086 2.99008 14.3995 5.19922 14.3995C6.31818 14.3995 7.32982 13.9401 8.05579 13.1995C8.76309 12.478 9.19922 11.4897 9.19922 10.3995M11.9992 6.58263C13.6222 7.09223 14.7995 8.60843 14.7995 10.3996C14.7995 12.6087 13.0087 14.3996 10.7995 14.3996C9.89891 14.3996 9.0678 14.102 8.39922 13.5997M11.9992 5.59943C11.9992 7.80856 10.2084 9.59942 7.99922 9.59942C5.79008 9.59942 3.99922 7.80856 3.99922 5.59943C3.99922 3.39029 5.79008 1.59943 7.99922 1.59943C10.2084 1.59943 11.9992 3.39029 11.9992 5.59943Z" stroke="%23626871" stroke-linecap="round" stroke-linejoin="round"/></svg>');
                            }
                        }

                        .product-variants {
                            @include transition-opacity;
                            position:absolute;
                            bottom: 1rem;
                            left: 1rem;
                            right:1rem;
                            opacity:0;

                            ul {
                                @include reset-ul;
                                display:grid;
                                grid-template-columns: repeat(5, 1fr);
                                gap:.5rem;

                                li {
                                    @include reset-li;
                                    @include transition-outline;
                                    aspect-ratio:1 / 1;
                                    outline: 2px solid transparent;
                                    background-color: #fff;
                                    border-radius: calc(var(--border-radius-default) / 1.5);
                                    position:relative;
                                    z-index: 1;
                                    

                                    &.active,
                                    &:hover {
                                        outline-color: var(--bs-body-color);
                                        border-radius: calc(var(--border-radius-default) / 1.5);
                                    }

                                    a {
                                        text-decoration: none;
                                        
                                        .product-variant__img {
                                            width:100%;
                                            height:100%;

                                            img {
                                                width:100%;
                                                height:100%;
                                                object-fit: contain;
                                                border-radius: calc(var(--border-radius-default) / 1.75);
                                            }
                                        }

                                        .product-variant__text {
                                            position: absolute;
                                            top:0;
                                            left:0;
                                            bottom:0;
                                            right:0;
                                            height:100%;
                                            text-align:center;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            font-size: .875rem;
                                        }

                                        &:hover,
                                        &:focus-visible {
                                            text-decoration: underline;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .product-body {
                        display:flex;
                        flex-direction: column;
                        gap:.3rem;
                        flex-grow: 1;
                        position:relative;
                        
                        a {
                            text-decoration: none;
                        }

                        .product-brand {
                            height:1.325rem;
                            font-size: 0.875rem;
                            text-transform: uppercase;
                        }

                        .product-title-and-add-to-favorites {
                            display:flex;
                            justify-content: space-between;
                            align-items:center;

                            .product-title {
                                margin:0;
                                font-weight:700;

                                &:hover,
                                &:focus-visible {
                                    text-decoration: underline;
                                }

                                @include media-breakpoint-up(xl) {
                                    font-size: calc(var(--bs-body-font-size)*1.25);
                                }

                                @include media-breakpoint-down(xs) {
                                    font-size: var(--bs-body-font-size);
                                }
                            }

                            .add-to-favorites {    
                                &:hover,
                                &:focus-visible,
                                &.active {
                                    svg {
                                        path {
                                            fill: var(--color-main-hex);
                                            stroke: #fff;
                                        }
                                    }
                                }
    
                                &.active {
                                    &:hover,
                                    &:focus-visible {
                                        svg {
                                            path {
                                                stroke: var(--color-main-hex);
                                                fill: #fff;
                                            }
                                        }
                                    }                                        
                                }
    
                                svg {
                                    fill: #fff;
                                    path {
                                        stroke: var(--color-main-hex);
                                    }
                                }
                            }    
                        }

                        .product-description {
                            display: -webkit-box;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;  
                            overflow: hidden;
                            overflow-wrap: anywhere;

                            @media (max-width: 576px) {
                                font-size: calc(var(--bs-body-font-size)*.875);
                            }
                        }
                    }
                    
                    .product-footer {
                        display:flex;
                        flex-direction: column;
                        gap:.5rem;

                        .product-price {

                            > div {
                                display: flex;
                                gap: var(--spacer-1);
                                align-items: baseline;
                            }
                            
                            .product-price__before {
                                text-decoration: line-through;
                                order:2;

                                @media (max-width: 576px) {
                                    font-size: calc(var(--bs-body-font-size)*.875);
                                }
            
                                + .product-price__now {
                                    color: #f68d68; /* Color now when on sale */
                                }
                            } 

                            .product-price__now {
                                font-weight:700;

                                @media (max-width: 576px) {
                                    font-size: calc(var(--bs-body-font-size)*.875);
                                }
            
                                span {
                                    font-size: calc(var(--bs-body-font-size) * .85);
                                }
                            }
                            
                            .product-price__vat {
                                order:3;
                                font-size: .675rem;

                                
                                @media (max-width: 576px) {
                                    font-size: calc(var(--bs-body-font-size)*.5);
                                }
                            }
                        }

                        .product-stock-and-cta {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            gap: var(--spacer-4);

                            .product-stock {
                                font-size: .875rem;
                                color: #000;
                                display: flex;
                                align-items: center;
                                gap: .375rem;

                                &::before {
                                    content: '';
                                    background-color: currentColor;
                                    color: #7C7C8D; /* When not not in stock */
                                    width: 0.5rem;
                                    height: 0.5rem;
                                    display: block;
                                    border-radius: 50%;
                                }                                

                                /* Product stock status */
                                @at-root .block__product__list article.in-stock .product-stock::before {color: #5EC094!important;}
                                @at-root .block__shop__product__list article.in-stock .product-stock::before {color: #5EC094!important;}

                                @at-root .block__product__list article.backorder .product-stock::before {color: #F68D68!important;}
                                @at-root .block__shop__product__list article.backorder .product-stock::before {color: #F68D68!important;}
                            }

                            .product-cta {
                                .bttn--add-to-cart {
                                    position:relative;
                                    float:right;
                                    aspect-ratio: 1 / 1;
                                    padding:0;
                                    text-align: center;
                                    background-color: var(--color-custom-4-hex);
                                    width:3rem;
                                    height:3rem;

                                    &:hover,
                                    &:focus-visible {
                                        background-color: var(--color-custom-4-hover-hex);
                                    }

                                    &::after {
                                        position: absolute;
                                        top: 50%;
                                        left: 50%;
                                        transform: translate(-50%, -50%);                                        
                                        content:url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.39844 2.99219H3.16059C4.0638 2.99219 4.85493 3.59755 5.09104 4.46936L5.77754 7.00413M5.77754 7.00413L7.26738 15.8618C7.35211 16.3655 7.80292 16.7244 8.31286 16.6941L19.1201 16.0518C19.6229 16.0219 20.0251 15.6226 20.0585 15.1199L20.5275 8.07051C20.5659 7.49338 20.1081 7.00413 19.5297 7.00413H5.77754Z' stroke='%230D2240' stroke-width='1.5' stroke-linecap='round'/%3E%3Cpath d='M11 20.5C11 21.3284 10.3284 22 9.5 22C8.67157 22 8 21.3284 8 20.5C8 19.6716 8.67157 19 9.5 19C10.3284 19 11 19.6716 11 20.5Z' stroke='%230D2240' stroke-width='1.5'/%3E%3Cpath d='M19 20.5C19 21.3284 18.3284 22 17.5 22C16.6716 22 16 21.3284 16 20.5C16 19.6716 16.6716 19 17.5 19C18.3284 19 19 19.6716 19 20.5Z' stroke='%230D2240' stroke-width='1.5'/%3E%3Cpath d='M10.4999 13.1641L10 13.1641' stroke='%230D2240' stroke-width='1.5' stroke-linecap='round'/%3E%3Cpath d='M16.9999 13.1641L13 13.1641' stroke='%230D2240' stroke-width='1.5' stroke-linecap='round'/%3E%3Cpath d='M17 10.207L9 10.207' stroke='%230D2240' stroke-width='1.5' stroke-linecap='round'/%3E%3C/svg%3E%0A");
                                        line-height:1;
                                    }

                                    @at-root .list__product article.not-in-stock .bttn--add-to-cart {
                                        pointer-events: none;
                                        background-color: var(--color-custom-3-hex)!important;

                                        &::after {
                                            content:url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.39844 2.99219H3.16059C4.0638 2.99219 4.85493 3.59755 5.09104 4.46936L5.77754 7.00413M5.77754 7.00413L7.26738 15.8618C7.35211 16.3655 7.80292 16.7244 8.31286 16.6941L19.1201 16.0518C19.6229 16.0219 20.0251 15.6226 20.0585 15.1199L20.5275 8.07051C20.5659 7.49338 20.1081 7.00413 19.5297 7.00413H5.77754Z' stroke='%23bbb' stroke-width='1.5' stroke-linecap='round'/%3E%3Cpath d='M11 20.5C11 21.3284 10.3284 22 9.5 22C8.67157 22 8 21.3284 8 20.5C8 19.6716 8.67157 19 9.5 19C10.3284 19 11 19.6716 11 20.5Z' stroke='%23bbb' stroke-width='1.5'/%3E%3Cpath d='M19 20.5C19 21.3284 18.3284 22 17.5 22C16.6716 22 16 21.3284 16 20.5C16 19.6716 16.6716 19 17.5 19C18.3284 19 19 19.6716 19 20.5Z' stroke='%23bbb' stroke-width='1.5'/%3E%3Cpath d='M10.4999 13.1641L10 13.1641' stroke='%23bbb' stroke-width='1.5' stroke-linecap='round'/%3E%3Cpath d='M16.9999 13.1641L13 13.1641' stroke='%23bbb' stroke-width='1.5' stroke-linecap='round'/%3E%3Cpath d='M17 10.207L9 10.207' stroke='%23bbb' stroke-width='1.5' stroke-linecap='round'/%3E%3C/svg%3E")!important;
                                        }
                                    }

                                    @at-root .list__product article.request-price .bttn--add-to-cart {
                                        background-color: var(--color-custom-1-hex)!important;

                                        &:hover,
                                        &:focus-visible {
                                            background-color: var(--color-custom-1-hover-hex)!important;
                                        }

                                        &::after {
                                            content:url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.125 6.18382L12 12.6949L20.4375 6.18382M9.65589 12L4.125 18.0221M19.875 17.5288L14.3434 12M5.25 19C4.00736 19 3 17.94 3 16.6324V7.36765C3 6.06003 4.00736 5 5.25 5H18.75C19.9926 5 21 6.06003 21 7.36765V16.6324C21 17.94 19.9926 19 18.75 19H5.25Z' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")!important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    
                    &.product-video {
                        display: block;
                        
                        .block__video {
                            margin: 0;
                            display: flex;
                            flex-direction: column;

                            .block__body {
                                figure {
                                    margin-bottom: 0;
                                    container-type: inline-size;
                                    
                                    .play-button {
                                        @container (max-width: 32rem) {
                                            font-size: 1.125rem;
                                            padding: 0.75rem 1.125rem;
                                        }
                                        
                                        svg {
                                            @container (max-width: 32rem) {
                                                height: 1.125rem;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .block__footer {
            margin-top: calc(var(--spacer-5) * 2);
            display:flex;
            flex-direction: column;
            gap:var(--spacer-2);
            align-items: center;
        }
    }
}


/*
=======================================================================
    Product Labels - General Styling
=======================================================================
*/

.product-labels {
    display: flex;
    gap: .25rem;
    flex-wrap:wrap;
    z-index: 5;
    pointer-events: none;

    .label {
        span {
            --label-border-color: #CCCCCC99;
            --label-background-color: #CCCCCC99;
            --label-text-color: #32363E;

            font-family: 'Wix Madefor Display', sans-serif;
            background-color: var(--label-background-color);
            color: var(--label-text-color);
            padding: .1rem .7rem;
            border-radius: 25px;
            display: inline-block;
            font-size:.675rem;
            font-weight:700;
            text-transform: uppercase;
        }
    }
}