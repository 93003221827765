.block__hero {
    position:relative;
    overflow:hidden;
    padding:0!important;
    /*height: 70vh;*/ /* Use vh as a fallback for browsers that do not support Custom Properties */
    /*height: calc(var(--vh, 1vh) * 70);*/

    .block__back {
        position: absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        display:block;
        
        img {
            width:100%;
            height:100%;
            object-fit: cover;
            object-position: var(--focal-x, 50%) var(--focal-y, 50%);
        }
    }

    .block__front {
        min-height: max(60vmin, 30rem);
        margin-top:0;
        margin-bottom:0;
        position:relative;
        z-index: 2;
        display:flex;
        flex-direction: column;
        justify-content: center;
        height:100%;
        
        @include media-breakpoint-up(md) {
            min-height: max(60vh, 30rem);
        }
    
        .block__body {
            color: var(--color-white-hex);

            .container {

                &.container__breadcrumb {
                    position: absolute;
                    top: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    text-align:left;
                }

                 @at-root .header--transparent + main .container__breadcrumb {
                    top: var(--header-height)!important;
                 }
            }

            .text {
                padding-block: calc(var(--margin-default)*2);
                
                @media screen and (min-height: 770px) {
                    padding-block: calc(var(--margin-default)*3);
                }

                > * {
                    margin-top:1rem;
                }
                
                > :last-child {
                    margin-bottom: 0 !important;
                }
                
                .logo {
                    width: 9.375rem;
                    height: 9.375rem;

                    > * {
                        width:100%;
                        height:100%;
                        object-fit:contain;
                    }
                }
                
                .page__navigate__back {
                    display: flex;
                    align-items: center;
                    gap: 0.5rem;
                    font-weight: 700;
                    font-size: var(--font-size-md);
                    color: inherit;
                    text-decoration: none;
                }

                .pre-title {
                    font-size: 1.5rem;
                    font-weight:500;
                    text-transform: initial;
                }

                h1 {
                    padding:0;
                    word-break: break-word;
                }

                .teaser {
                    font-size:1.25rem;
                    font-weight:500;
                }

                .bttn {
                    margin-top:1.75rem;
                    display:inline-flex;
                    gap:.5rem;

                    .bttn__icon {
                        svg {
                            fill: #fff;
                        }
                    }

                    &:hover,
                    &:focus,
                    &:focus-visible {
                        .bttn__icon {
                            svg {
                                fill: #000;
                            }
                        }
                    }
                }
            }
        }
    }

    .block__navigation {
        position: absolute;
        bottom: 5rem;
        left: 50%;
        transform: translateX(-50%);

        a {
            color:#fff;
            text-decoration: none;
        }

        @keyframes scroll {
            0% {
              top: 160%;
            }
            50% {
              top: 195%;
            }
            100% {
              top: 160%;
            }
        }            

        &::before {
            content: "";
            position: absolute;
            top: 160%;
            left: 50%;
            transform: translate(-50%);
            width: 12px;
            height: 12px;
            background: #fff;
            border-radius: 15px;
            animation-name: scroll;
            animation-duration: 2s;
            animation-iteration-count: infinite;            
        }

        &::after {
            content: "";
            position: absolute;
            top: 140%;
            left: 50%;
            transform: translate(-50%);
            width: 25px;
            height: 45px;
            border: 2px solid #fff;
            border-radius: 5rem;
        }            
    }    
}


/*
=======================================================================
    Options for logo position
=======================================================================
*/

.block__hero {
    &.logo--top-right {
        .block__front {
            .logo {
                position:absolute;
                top:var(--margin-default);
                right:var(--margin-default);
                margin:0;
            }
        }
    }
}

header.fixed-top.header--transparent + main {
    .block__hero {
        &.logo--top-right {
            .block__front {
                .logo {
                    top: calc(var(--margin-default) / 2 + var(--header-height));
                }
            }
        }
    }
}


/*
=======================================================================
    Options for title size
=======================================================================
*/

.block__hero {
    &.title--large {
        .block__front {
            .text {
                h1 {
                    font-size:2.5rem;

                    @include media-breakpoint-up(xl) {
                        font-size:4rem;
                    }
                }
            }
        }
    }
}


/*
=======================================================================
    Options for text position
=======================================================================
*/

.block__hero {

    &.text--center-center {
        .block__front {
            justify-content:center;

            .block__body {
                text-align:center;
                
                .logo {
                    margin-inline: auto;
                }
            }
            
            .page__navigate__back {
                justify-content: center;
            }
        }
    }

    &.text--bottom-left {
        .block__front {
            justify-content:flex-end;

            .block__body {
                text-align:left;
            }
        }
    }

    &.text--bottom-center {
        .block__front {
            justify-content:flex-end;

            .block__body {
                text-align:center;
                
                .logo {
                    margin-inline: auto;
                }
            }

            .page__navigate__back {
                justify-content: center;
            }
        }
    }
}


/*
=======================================================================
    Options for text alignment
=======================================================================
*/

.block__hero {
    &.text-align--center {
        .block__front {
            .block__body {
                text-align:center;
            }
        }
    }
}