
.skeleton-wrapper {
    min-height: 465px;
}
.skeleton-wrapper .skeleton {
    animation: skeleton-loading 1s linear infinite alternate;
    border-radius: 0.125rem;
}
@keyframes skeleton-loading {
    0% {
        background-color: #fcfcfc;
    }
    100% {
        background-color: #ebebeb;
    }
}
.skeleton.image {
    width: 100%;
    position: relative;
}
.skeleton.image::after {
    content: '';
    display: block;
    padding-bottom: 100%;
}
.skeleton-availability-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.skeleton.producer {
    margin-top: 0.5rem;
}
.skeleton.title {
    margin-top: 0.3rem;
    height: 1.5rem;
    width: 75%;
}
.skeleton.description {
    margin-top: 0.3rem;
    height: 1rem;
}
.skeleton.price {
    margin-top: 0.5rem;
    height: 1.125rem;
    width: 50%;
}
.skeleton.producer,
.skeleton-availability-wrapper .skeleton.text {
    width: 6rem;
    height: 1.125rem;
}
.skeleton-availability-wrapper .skeleton.button {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
}
