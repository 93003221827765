.block__list.block__list__gallery {
    .block__body {
        .row {
            --bs-gutter-x: 0.75rem;
            --bs-gutter-y: 0.75rem;
        }
        .listelement {
            .listelement__media {
                figure {
                    img {
                        aspect-ratio: initial;
                    }
                }
            }
        }
    }
}


/*
=======================================================================
    Magnific Popup overrides
=======================================================================
*/

.mfp-container {
    --gallery-nav-color-initial: #fff;
    --gallery-nav-color-hover: #333;
    --gallery-nav-background-color: #000;

    .mfp-arrow {
        @include reset-border;
        position: absolute;
        opacity: 0.65;
        top: 50%;
        margin: -50px 0 0;
        padding: 0;
        width: 50px;
        height: 50px;
        -webkit-tap-highlight-color: transparent;
        color: var(--gallery-nav-color-initial);
        background-color: var(--gallery-nav-background-color);

        &:active {
            margin-top: -49px;
        }

        &:hover,
        &:focus {
            opacity: 1;
        }

        &.mfp-arrow-left {
            left: 0;
        
            &::before {display:none;}
            &:after {
                @include fontawesomesharp;
                content:"\f060";
                color:#fff;
                border:0;
                margin:0;
                position:initial;
                width:auto;
                height:auto;
                
            }
        }
        
        &.mfp-arrow-right {
            right: 0;
            
            &::before {display:none;}
            &:after {
                @include fontawesomesharp;
                content:"\f061";
                color:#fff;
                border:0;
                margin:0;
                position:initial;
                width:auto;
                height:auto;                
            }
        }
    }
    
    .mfp-bottom-bar {
        .mfp-title,
        .mfp-counter {
            font-size: var(--font-size-sm);
        }
    }
}

/* Fade effect */
.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
  opacity: 0;
  -webkit-backface-visibility: hidden;
  /* ideally, transition speed should match zoom duration */
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.mfp-with-zoom.mfp-ready .mfp-container {
    opacity: 1;
}
.mfp-with-zoom.mfp-ready.mfp-bg {
    opacity: 0.8;
}

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
  opacity: 0;
}