.block__list {
    &.block__list__employees {
        .block__body {
            .listelement {
                .listelement__wrapper {
                    display: flex;
                    gap: calc(var(--bs-gutter-x) / 2);

                    @include media-breakpoint-up(sm) {
                        gap: var(--bs-gutter-x);
                    }

                    .listelement__media {
                        width: 75px;
                        flex-shrink: 0;

                        @include media-breakpoint-up(sm) {
                            width: 100px;
                        }

                        @include media-breakpoint-up(lg) {
                            width: 150px;
                        }

                        figure {
                            &::after {
                                display:none;
                            }

                            > * {
                                aspect-ratio: 1/1;
                                width:100%;
                                height:100%;
                                object-fit: cover;
                                border-radius:100%;
                            }
                        }
                    }

                    .listelement__body {
                        flex-grow:1;
                        background-color: transparent;
                        padding:0;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        a {
                            text-decoration: none;
                            
                            &:hover {
                                text-decoration: underline;    
                            }
                        }

                        .employee__meta--line1 {
                            margin:0;
                            
                            h3 {
                                font-size: 1.5rem;
                                line-height: 1.75rem;
                                color: #0D2240;
                                font-weight:600;
                            }
                        }

                        .employee__meta--line2 {
                            .employee--position {
                                color: #626871;
                                font-weight:500;
                                line-height: 1.5rem;
                                word-break: break-word;
                            }
                        }

                        .employee__meta--line3 {
                            margin-top:.5rem;
                            margin-bottom:0;
                            display:flex;
                            flex-wrap:wrap;
                            gap:1rem;
                            font-weight:600;
                            align-items: center;
                            line-height: 1.5rem;

                            @include media-breakpoint-down(sm) {
                                flex-direction: column;
                                gap: 0.5rem;
                                align-items: flex-start;
                            }

                            .employee--phone {
                                display: flex;
                                align-items: center;
                                gap: 0.5rem;
                                
                                &::before {
                                    content:url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.2202 15.6423C17.2202 15.6423 16.2546 16.5906 16.018 16.8686C15.6325 17.28 15.1784 17.4742 14.583 17.4742C14.5258 17.4742 14.4647 17.4742 14.4075 17.4704C13.274 17.398 12.2207 16.9562 11.4307 16.5792C9.27058 15.5356 7.37382 14.0541 5.79764 12.1764C4.49624 10.6111 3.6261 9.16381 3.04982 7.60989C2.6949 6.66155 2.56514 5.92268 2.62238 5.2257C2.66055 4.78009 2.83229 4.41066 3.14905 4.09454L4.45045 2.7958C4.63745 2.62061 4.8359 2.52539 5.03054 2.52539C5.27098 2.52539 5.46561 2.67012 5.58774 2.79199C5.59155 2.7958 5.59537 2.79961 5.59919 2.80342C5.83199 3.02051 6.05334 3.24522 6.28614 3.48516C6.40445 3.60704 6.52658 3.72891 6.6487 3.8546L7.69058 4.89435C8.09512 5.29806 8.09512 5.67131 7.69058 6.07502C7.57991 6.18547 7.47305 6.29592 7.36237 6.40256C7.04179 6.7301 7.29363 6.47878 6.9616 6.77586C6.95396 6.78347 6.94633 6.78728 6.94252 6.7949C6.6143 7.12244 6.67537 7.44236 6.74406 7.65946C6.74788 7.67088 6.75169 7.68231 6.75551 7.69373C7.02648 8.34881 7.40812 8.96581 7.98821 9.70087L7.99203 9.70468C9.04536 10.9996 10.1559 12.0089 11.381 12.782C11.5375 12.8811 11.6978 12.961 11.8504 13.0372C11.9878 13.1058 12.1176 13.1705 12.2282 13.2391C12.2435 13.2467 12.2588 13.2581 12.274 13.2657C12.4038 13.3305 12.5259 13.361 12.6519 13.361C12.9686 13.361 13.1671 13.1629 13.232 13.0982L13.98 12.3516C14.1098 12.2221 14.3159 12.066 14.5563 12.066C14.7929 12.066 14.9876 12.2145 15.1059 12.344C15.1097 12.3478 15.1097 12.3478 15.1135 12.3516L17.2164 14.4502C17.6094 14.8386 17.2202 15.6423 17.2202 15.6423Z' stroke='%230D2240' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
                                    width: 1.25rem;
                                    height: 1.25rem;
                                }
                            }

                            .employee--linkedin {
                                text-decoration: underline;
                                display: flex;
                                align-items: center;
                                gap: 0.5rem;

                                &::before {
                                    content:url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.01323 17.4999H2.5V6.94434H6.01852V17.4999H6.01323Z' fill='%230D2240'/%3E%3Cpath d='M4.25926 5.18551C3.28595 5.18551 2.5 4.39499 2.5 3.42625C2.5 2.45752 3.28595 1.66699 4.25926 1.66699C5.22799 1.66699 6.01852 2.45752 6.01852 3.42625C6.01852 4.39956 5.23256 5.18551 4.25926 5.18551Z' fill='%230D2240'/%3E%3Cpath d='M18.3329 17.4999H14.9502V12.4895C14.9502 11.2947 14.9248 9.75787 13.1927 9.75787C11.43 9.75787 11.16 11.0586 11.16 12.4028V17.4999H7.77734V7.19967H11.0225V8.60644H11.0683C11.5217 7.79707 12.6272 6.94434 14.2727 6.94434C17.6961 6.94434 18.3329 9.07857 18.3329 11.8536V17.4999Z' fill='%230D2240'/%3E%3C/svg%3E%0A");
                                    width: 1.25rem;
                                    height: 1.25rem;
                                }
                            }
                        }

                        .employee__meta--line4 {
                            font-weight:600;
                            margin-top:.5rem;
                            line-height: 1.5rem;

                            .employee--email {
                                display: flex;
                                align-items: center;
                                gap: 0.5rem;
                                font-size: 0.875rem;
                                line-height: 1;
                                
                                @include media-breakpoint-up(sm) {
                                    font-size: 1rem;
                                }
                                
                                &::before {
                                    content:url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.5 6.13906V13.8596C2.5 14.9493 3.33947 15.8327 4.375 15.8327H15.625C16.6605 15.8327 17.5 14.9493 17.5 13.8596V6.13905M2.5 6.13906C2.5 5.04938 3.33947 4.16602 4.375 4.16602H15.625C16.6605 4.16602 17.5 5.04938 17.5 6.13905M2.5 6.13906L8.83698 10.6687C9.53261 11.1659 10.4674 11.1659 11.163 10.6687L17.5 6.13905' stroke='%230D2240' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
                                    width: 1.25rem;
                                    height: 1.25rem;
                                }
                            }
                        }
                    }
                }
            }
        }    
    }
}