.pagination {
    margin-top: var(--margin-default);
    justify-content:center;

    ul {
        @include reset-ul;
        display:flex;
        gap:.5rem;

        li {
            @include reset-li;

            a {
                --pagination-item-size: 2.5rem;
                display:block;
                width: var(--pagination-item-size);
                height: var(--pagination-item-size);
                border-radius: var(--pagination-item-size);
                line-height: var(--pagination-item-size);
                text-align: center;
                text-decoration: none;
                font-size:1rem;

                &:hover,
                &:focus-visible {
                    background-color: var(--color-custom-3-hover-hex);
                }
            }

            &.disabled {
                display:none;
            }

            &.active {
                a {
                    background-color: var(--color-custom-4-hex);
                }
            }

            &.pagination--prev {
                a {
                    background-color: var(--color-custom-3-hex);

                    &:hover,
                    &:focus-visible {
                        background-color: var(--color-custom-3-hover-hex);
                    }                                        

                    &::before {
                        @include fontawesome;
                        content:"\f060";
                    }
                }
            }

            &.pagination--next {
                a {
                    background-color: var(--color-custom-3-hex);

                    &:hover,
                    &:focus-visible {
                        background-color: var(--color-custom-3-hover-hex);
                    }

                    &::before {
                        @include fontawesome;
                        content:"\f061";
                    }
                }
            }                                    
        }
    }
}