.block__list__swipe {
    width:100%;
    overflow: hidden;

    .block__body {
        .block__body__wrapper {
            position:relative;

            .swiper {
                position:sticky;
                top:0;
                left:0;
                overflow: visible;

                .swiper-slide {
                    width: 60%;
                    margin-right:var(--bs-gutter-x);
            
                    /* Center slide text vertically */
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: -webkit-flex;
                    display: flex;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    -webkit-justify-content: center;
                    justify-content: center;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    -webkit-align-items: center;
                    align-items: center;

                    @include media-breakpoint-up(xl) {
                        width: 50%;
                    }

                    article {
                        a {
                            color: var(--bs-body-text-color);
                            text-decoration: none;
                            color: var(--color-black-hex);
                            display:block;

                            &:hover,
                            &:focus {
                                h2 {
                                    text-decoration: underline;
                                }

                                figure {
                                    position:relative;

                                    &:after {
                                        background-color: rgba(0,0,0,0.1); 
                                    }

                                    img {
                                        transform: scale(1.05);
                                    }                                    
                                }
                            }

                            figure {
                                position:relative;
                                overflow:hidden;

                                img {
                                    @include transition--slow;
                                    display: block;
                                    aspect-ratio: 16 / 9;
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                }

                                &::after {
                                    @include transition;
                                    @include image-overlay;
                                }
                            }

                            .byline {
                                font-size:1.25rem;
                                opacity:.75;
                                padding-left: var(--margin-default);
                                padding-right: var(--margin-default);
                            }
        
                            .title {
                                margin:0;

                                @include media-breakpoint-up(xl) {
                                    font-size: calc(var(--bs-body-font-size)*1.25);
                                }
                            }
                        }
                    }
                }
            }    
        }
    }    
}