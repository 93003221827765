.block__mypage {
    
    > .block__preHeader {
        h1 {
            font-size: 1rem;
        }
    }

    > .block__header {
        margin-top: var(--margin-default);

        .container {
            display:flex;
            gap:var(--margin-default);
            flex-wrap:wrap;
            align-items: center;
            justify-content: space-between;

            .block__header__title {
                display:flex;
                gap:1rem;
                align-items: center;

                .block__header__title__logo {
                    width:75px;
                    height:75px;
                    border-radius:75px;
                    padding:1rem;
                    background-color: var(--color-custom-3-hex);

                    > * {
                        width:100%;
                        height:100%;
                        object-fit: contain;
                    }
                }

                .block__header__title__text {

                    .dropdown {
                        font-size:1rem;
                        button {
                            border:0;
                            background-color:transparent;
                            margin:0;
                            padding:0;
                            span{
                                display: inline-flex;
                                justify-content: center;
                                align-items: center;
                                gap: 0.7rem;
                                svg{
                                    width: 14px;
                                    height: 25px;
                                }
                            }
                        }
                        
                        .customer-card-select {
                            li {
                                &.parent-customer-card {
                                    background-color: #dee2e6;
                                    pointer-events: none;
                                    a {
                                        font-weight: bold;
                                    }
                                }
                                
                                &:not(.parent-customer-card) {
                                    a {
                                        padding-left: 1.5rem;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .block__header__cta {
                ul {
                    @include reset-ul;
                    display:flex;
                    gap:.5rem;

                    li {
                        @include reset-li;
                        a {
                            &::before {

                            }
                        }
                    }
                }
            }
        }
    }

    .block__body {

        .block__accordiontab {
            margin-top:0;
            padding-top:0;
            padding-bottom:0;

            /* CSS for all tabs */
            .nav-tabs {
                margin-bottom:0;
            }

            .custom-item-body {
                padding:0;

                .block {
                    padding:0;
                    margin-top: var(--margin-default);
                    margin-bottom: var(--margin-default);

                    .block__header {
                        h2 {
                            font-size: var(--font-size-xxl);
                        }
                    }
                }
            }


            /* CSS for custom Tab 1 */
            .tab-pane--1 {
                .custom-item-body {

                    .block__orderHistory {
                        .block__header {
                            display:flex;
                            gap:var(--margin-default);
                            flex-wrap:wrap;
                            align-items: center;
                            justify-content: space-between;
                            
                            .block__header__cta {
                                ul {
                                    @include reset-ul;
                                    display:flex;
                                    gap:.5rem;

                                    li {
                                        @include reset-li;

                                        a {
                                            &.active {
                                                background-color: var(--color-custom-3-hex);
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .block__body {
                            --bs-accordion-btn-padding-x: 1.25rem;


                            .legend {
                                padding-inline:var(--bs-accordion-btn-padding-x);
                                font-size: 0.75rem;
                                text-transform: uppercase;
                                font-weight: 700;
                                color:var(--bs-body-color);
                                margin-bottom:1rem;

                                @include media-breakpoint-down(lg) {
                                    display:none;
                                }

                                @include media-breakpoint-up(lg) {
                                    display:grid;
                                    grid-template-columns: repeat(5, 1fr);
                                }
                                
                                & > div.legend__orderAmount {
                                    @include media-breakpoint-up(lg) {
                                        text-align: right;
                                    }
                                }
                            }

                            .accordion {

                                @include media-breakpoint-down(lg) {
                                    display:flex;
                                    flex-direction: column;
                                    gap:1rem;
                                }
                                
                                .accordion-item {
                                    border:0;
                                    border-bottom: 1px solid var(--color-custom-3-hex);
                                    --accordion-item-bg: var(--color-white-hex);

                                    @include media-breakpoint-down(lg) {
                                        --accordion-item-bg: var(--color-custom-3-hex);

                                        background-color: var(--accordion-item-bg);
                                        border-bottom:0;
                                        border-radius: var(--border-radius-default);
                                    }

                                    .accordion-header {
                                        .accordion-button {
                                            color: var(--bs-body-color);
                                            background-color:transparent;
                                            box-shadow: none;
                                            border:0;

                                            &::after {
                                                background-image:none;
                                                @include fontawesomesharp;
                                                @include transition;
                                                content:"\2b";
                                                position: absolute;
                                                top: 50%;
                                                right: 1rem;
                                                width:initial;
                                                transform: translateY(-50%);
                                            }
                                        

                                            &[aria-expanded="true"] {
                                                &::after {
                                                    content:"\f068";
                                                    transform:rotate(-180deg) translateY(50%);
                                                }
                                            }
                                            
                                            &[disabled] {
                                                &::after {
                                                    display:none;
                                                }
                                            }                  

                                            @include media-breakpoint-down(lg) {

                                                display:flex;
                                                flex-wrap:wrap;
                                                gap:1rem;

                                                > * {
                                                    flex:1;
                                                    min-width:110px;
                                                    max-width:110px;
                                                    display:flex;
                                                    flex-direction: column;
                                                    gap:.25rem;
                                                }

                                                .label {
                                                    font-size: 0.75rem;
                                                    text-transform: uppercase;
                                                    font-weight: 700;
                                                    color:var(--bs-body-color);
                                                }

                                                /*
                                                display:grid;
                                                grid-template-columns: repeat(3, 1fr);

                                                .orderStatus,
                                                .orderType {
                                                    display:none;
                                                }
                                                */
                                            }

                                            @include media-breakpoint-up(lg) {
                                                display:grid;
                                                grid-template-columns: repeat(5, 1fr);

                                                .label {
                                                    display:none;
                                                }

                                                /*
                                                > div {
                                                    .legend {
                                                        display:none;
                                                    }
                                                }
                                                */
                                            }

                                            .orderStatus {
                                                .value  {
                                                    display:inline-block;
                                                    font-size:.65rem;
                                                    padding: 0.45rem 0.75rem;
                                                    background-color: #FFD688;
                                                    border-radius:5rem;
                                                    text-transform: uppercase;
                                                    font-weight: 500;
                                                }

                                                &.orderStatus--received {
                                                    .value {
                                                        background-color: #F9E0B2!important;
                                                    }
                                                }

                                                &.orderStatus--processing {
                                                    .value {
                                                        background-color:#80AAA74D !important;
                                                    }
                                                }

                                                &.orderStatus--sent {
                                                    .value {
                                                        background-color: #80AAA74D!important;
                                                    }
                                                }

                                                &.orderStatus--delivered {
                                                    .value {
                                                        background-color: #80AAA74D!important;
                                                    }
                                                }

                                                &.orderStatus--invoiced {
                                                    .value {
                                                        background-color: #C7ECBA!important;
                                                    }
                                                }

                                                &.orderStatus--cancelled {
                                                    .value {
                                                        background-color: #E3534D99!important;
                                                    }
                                                }
                                            }
                                            
                                            .orderAmount {
                                                @include media-breakpoint-up(lg) {
                                                    text-align: center;
                                                }
                                            }
                                        }
                                    }

                                    .accordion-body {
                                        font-size:1rem;

                                        h4 {
                                            font-size:1rem;
                                        }

                                        .orderProgress {
                                            --orderProgressStepSize:32px;
                                            margin-top: var(--margin-default);
                                            width:100%;
                                            position:relative;

                                            @include media-breakpoint-down(md) {
                                                display:none;
                                            }
                                            
                                            .orderProgress__bar {
                                                position:absolute;
                                                top: calc((var(--orderProgressStepSize)/2) - 2px);
                                                width: 100%;
                                                height:4px;
                                                background-image: linear-gradient(to right, #bdbdbd 33%, rgba(255,255,255,0) 2%);
                                                background-position: bottom;
                                                background-size: 10px 4px;
                                                background-repeat: repeat-x;

                                                &::after {
                                                    position:absolute;
                                                    top:0;
                                                    left:0;
                                                    content:"";
                                                    display:block;
                                                    height:4px;
                                                    background-color: var(--bs-body-color);
                                                }
                                            }

                                            .orderProgress__step {
                                                @include reset-ul;
                                                display:flex;
                                                text-align: center;

                                                li {
                                                    position:relative;
                                                    @include reset-li;
                                                    flex:1;
                                                    display:flex;
                                                    flex-direction: column;

                                                    .step__icon {
                                                        width:var(--orderProgressStepSize);
                                                        height:var(--orderProgressStepSize);
                                                        line-height: var(--orderProgressStepSize);
                                                        border-radius:var(--orderProgressStepSize);
                                                        /*margin-top:calc((var(--orderProgressStepSize)/2)*-1);*/
                                                        margin-inline:auto;
                                                        background-color:#fff;
                                                        outline: 10px solid var(--accordion-item-bg);
                                                        border:2px solid var(--bs-body-color);
                                                        color:#fff;
                                                        z-index: 2;
                                                        position:relative;

                                                        &::after {
                                                            @include fontawesome;
                                                            position: absolute;
                                                            top: 50%;
                                                            left: 50%;
                                                            transform: translate(-50%, -50%);
                                                            font-size:.75rem;
                                                            line-height:1;
                                                            color: var(--bs-body-color);
                                                        }
                                                    }

                                                    /* Active state */
                                                    &.active {

                                                        .step__icon {
                                                            background-color:var(--bs-body-color);
                                                            
                                                            &::after {
                                                                content:"\f00c";
                                                                color: #fff;
                                                                font-weight: bold;
                                                            }                                                            
                                                        }

                                                        &::after {
                                                            content:"";
                                                            position:absolute;
                                                            top: calc((var(--orderProgressStepSize)/2) - 2px);
                                                            width:100%;
                                                            height:4px;
                                                            background-color: var(--bs-body-color);
                                                        }
                                                    }

                                                    /* Remove bar before step 1 and after step 5 */
                                                    &:first-child,
                                                    &:last-child {
                                                        &::before {
                                                            content:"";
                                                            display:block;
                                                            width:50%;
                                                            height:100%;
                                                            background:var(--accordion-item-bg);
                                                            position:absolute;
                                                            z-index: 2;
                                                            top:0;
                                                            left:0;
                                                        }
                                                    }

                                                    /* Extra css for last step */
                                                    &:last-child {
                                                        &::before {
                                                            left:initial;
                                                            right:0;
                                                        }
                                                    }

                                                    .step__text {
                                                        font-size:1rem;
                                                        font-weight:400;
                                                        margin-top: var(--spacer-1);
                                                        position: relative;
                                                        z-index: 2;
                                                    }

                                                    .step__subText {
                                                        font-size:.875rem;
                                                        color: var(--color-custom-2-hex);
                                                    }


                                                    &.step--received {
                                                        .step__icon {
                                                            svg{
                                                                width:12px;
                                                                height:12px;
                                                            }
                                                        }
                                                    }
                                                    
                                                    &.step--processing {
                                                        .step__icon {
                                                            &::after {
                                                                content:"\f013";
                                                            }
                                                        }                                                        
                                                    }

                                                    &.step--sent {
                                                        .step__icon {
                                                            &::after {
                                                                content:"\f0d1";
                                                            }
                                                        }
                                                    }
                                                    
                                                    &.step--delivered {
                                                        .step__icon {
                                                            &::after {
                                                                content:"\f49a";
                                                            }
                                                        }
                                                    }

                                                    &.step--invoiced {
                                                        .step__icon {
                                                            &::after {
                                                                content:"\f570";
                                                            }
                                                        }
                                                    }
                                                    
                                                    &.step--cancelled {
                                                        .step__icon {
                                                            &::after {
                                                                content:"\f00d";
                                                            }
                                                        }
                                                    }

                                                }
                                            }
                                        }
                                        
                                        .order__footer {
                                            margin-top: var(--margin-default);
                                            display:flex;
                                            gap: var(--margin-default);
                                            flex-wrap:wrap;
                                            justify-content: space-between;

                                            ul {
                                                @include reset-ul;
                                                display:flex;
                                                gap:1rem;

                                                li {
                                                    @include reset-li;
                                                }
                                            }

                                            .order__footer__right {
                                                ul {
                                                    justify-content: flex-end;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            /* CSS for custom Tab 2 */
            .tab-pane--2 {
                .custom-item-body {
                }
            }

            /* CSS for custom Tab 3 */
            .tab-pane--3 {
                .custom-item-body {
                    .block__accordion {
                        .accordion-header {
                            button {
                                font-weight:500;
                            }
                        }
                    }
                }
            }

            /* CSS for custom Tab 4 */
            .tab-pane--4 {
                .custom-item-body {

                }
            }        
        }
    }
}