/*
=======================================================================
    Bootstrap Overrides
=======================================================================
*/

$grid-gutter-width: 0;

:root {
    --bs-body-color: var(--color-custom-1-hex);
    --bs-body-color-hover: #333;    

    --bs-link-color: var(--color-main-accent-hex);
    --bs-link-hover-color: var(--color-main-accent-hover-hex);

    .container,
    .row:not(.g-0),
    .offcanvas {
        /* Global list gutter widths */
        --bs-gutter-x: 1.5rem;
        --bs-gutter-y: 1.5rem;
    }
}

@include media-breakpoint-up(xl) {
    :root { 
        .container,
        .row:not(.g-0),
        .offcanvas {
            /* Global list gutter widths */
            --bs-gutter-x: 3rem;
            --bs-gutter-y: 3rem;

            --container-max-width: 1950px;
        }
    }
}


/*
=======================================================================
    Mobile Breakpoint
=======================================================================
*/

$mobile-breakpoint: 1200px;


/*
=======================================================================
    Custom Colors
=======================================================================
*/

:root {
    --color-body-background: unset;

    --color-main-accent-rgb: 50, 54, 62;
    --color-main-accent-hover-rgb: 62, 67, 77;
    --color-main-accent-hex: #32363E;
    --color-main-accent-hover-hex: #3e434d;

    --color-secondary-accent-rgb: 250, 222, 201;
    --color-secondary-accent-hover-rgb: 219, 191, 170;
    --color-secondary-accent-hex: #FADEC9;
    --color-secondary-accent-hover-hex: #dbbfaa;

    --color-link-hex: #0D2240;
    --color-link-hover-hex: #11305c;

    --color-link-icon-rgb: 0,0,0;
    --color-link-icon-hover-rgb: 0, 0, 0;
    --color-link-icon-hex: #000;
    --color-link-icon-hover-hex: #000;    

    --color-black-rgb: 0, 0, 0;
    --color-black-hover-rgb: 51, 51, 51;
    --color-black-hex: #000;
    --color-black-hover-hex: #363636;

    --color-white-rgb: 255, 255, 255;
    --color-white-hover-rgb: 212, 212, 212;
    --color-white-hex: #fff;
    --color-white-hover-hex: #d4d4d4;

    --color-main-rgb: 13, 34, 64;
    --color-main-hover-rgb: 17, 35, 60;
    --color-main-hex: #0D2240;
    --color-main-hover-hex: #11233c;

    /* Dark Blue */
    --color-custom-1-rgb: 13, 34, 64;
    --color-custom-1-hover-rgb: 17, 35, 60;
    --color-custom-1-hex: #0D2240;
    --color-custom-1-hover-hex: #11233c;

    /* Light Blue */
    --color-custom-2-rgb: 124, 124, 141;
    --color-custom-2-hover-rgb: 168, 168, 191;
    --color-custom-2-hex: #7c7c8d;
    --color-custom-2-hover-hex: #a8a8bf;

    /* Dark White */
    --color-custom-3-rgb: 239, 240, 243;
    --color-custom-3-hover-rgb: 221, 222, 226;
    --color-custom-3-hex: #EFF0F3;
    --color-custom-3-hover-hex: #dddee2;

    /* Light Orange */
    --color-custom-4-rgb:250, 222, 201;
    --color-custom-4-hover-rgb: 235, 204, 181;
    --color-custom-4-hex: #FADEC9;
    --color-custom-4-hover-hex: #ebccb5;

    /* Light Blue */
    --color-custom-5-rgb: 20, 117, 164;
    --color-custom-5-hover-rgb: #1475a4;
    --color-custom-5-hex: #1475a4;
    --color-custom-5-hover-hex: #12638b;

    --color-custom-6-rgb: 98, 104, 113;
    --color-custom-6-hover-rgb: 124,130,139;
    --color-custom-6-hex: #626871;
    --color-custom-6-hover-hex: #7C828B;

    --color-custom-7-rgb: 21, 46, 41;
    --color-custom-7-hover-rgb: 26, 58, 51;
    --color-custom-7-hex: #152E29;
    --color-custom-7-hover-hex: #1A3A33;

    --color-checkbox-initial: #d1501c;
    --color-checkbox-focus: #d1501c;
    --color-checkbox-checked: #d1501c;

    --color-button-background-initial: #fff;
    --color-button-border-initial: #ebebea;
    --color-button-border-initial: #fff;
    --color-button-border-hover: #1475a4;

    --color-feedback-red-hex: #e3534d;
    --color-feedback-orange-hex: #f68d68;
    --color-feedback-yellow-hex: #ffd688;
    --color-feedback-green: #5ec094;
    
}


/*
=======================================================================
    Accessibility Colors
=======================================================================
*/

:root {

    --skip-navigation-background-color: #333;
    --skip-navigation-color: #fff;

    --scroll-top-background-color-initial: var(--color-main-hex);
    --scroll-top-background-color-hover: var(--color-main-hover-hex);
    --scroll-top-color-initial:#fff;
    --scroll-top-color-hover: #fff;
}


/*
=======================================================================
    Form Element Colors
=======================================================================
*/

:root {

    --form-control-background-color: var(--color-custom-3-hex);
    --form-control-padding-v: 1rem;
    --form-control-padding-h: .75rem;
    --form-control-border-radius: .75rem;
    --form-control-border-width: 1px;
    --form-control-border-color: var(--bs-body-color);
    --form-control-border-color-hover: var(--color-custom-1-hex);
    
    --form-control-validation-valid: #198754;

    --form-control-focus-visible-outline-color: var(--color-custom-1-hex);
    --form-control-focus-visible-outline-width: 1px;
    
    --color-form-check-initial-hex: var(--form-control-border-color);
    --color-form-check-hover-hex:var(--form-control-border-color-hover);
    --color-form-check-active-hex:var(--color-custom-1-hex);

    --color-form-required-hex:#ff0000;
    --color-form-error-hex:#ff0000;    

}


/*
=======================================================================
    Header
=======================================================================
*/

:root {
    --header-height: calc(var(--header-search-height) + (var(--spacer-1)*2));
    --header-search-height: 48px;
    --header-item-padding-x: 0.85rem;
    --header-item-padding-y: 0.5rem;
    --header-font-size: 1rem;
    --header-background-color: #fff;
    --header-link-color: var(--color-main-rgb);
    --header-mega-link-color: #000;    
    --header-spacer: var(--spacer-1);
}

/*
@include media-breakpoint-up(xl) {
    :root {
        --header-search-height: 48px;
    }
}
*/

/*
=======================================================================
    Footer
=======================================================================
*/

:root {
    --footer-text-color: var(--color-main-hex);
    --footer-background-color: var(--color-white-hex);
    --footer-link-color: var(--color-main-hex);
}


/*
=======================================================================
    Margins and Paddings
=======================================================================
*/

:root {
    --margin-default: 1.5rem;
    --padding-default: 1.5rem;
    --padding-block: 3rem;
    --margin-block: 3rem;
}

@include media-breakpoint-up(lg) {
    :root {
        --margin-default: 2rem;
        --padding-default: 2rem;
        --padding-block: 3rem;
        --margin-block: 3rem;
    }
}

@include media-breakpoint-up(xxl) {
    :root { 
        --margin-default: 2rem;
        --padding-default: 2rem;
        --padding-block: 3rem;
        --margin-block: 3rem;
    }
}


/*
=======================================================================
    Buttons
=======================================================================
*/

:root {

    --font-size-button-small: 1rem;
    --padding-button-small-vertical: .69rem;
    --padding-button-small-horizontal: 1.25rem;

    --font-size-button: 1rem;
    --font-weight-button: 600;
    --border-width-button: 2px;
    --background-color-button-initial: transparent;
    --padding-button-vertical: .8rem;
    --padding-button-horizontal: 1.25rem;
    
    --button-outline-border-color: var(--color-custom-1-hex);
    --button-hover-outline-border-color: var(--color-custom-1-hex);
    --button-hover-outline-background-color: var(--color-custom-1-hex);
    --button-hover-outline-text-color: var(--color-white-hex);
    --button-outline-text-color: var(--color-custom-1-hex);
    --button-outline-border-width: 1px;
}

@include media-breakpoint-up(xl) {
    :root {
        --font-size-button-small: 1rem;
        --font-size-button: 1.25rem;
        --border-width-button: 3px;
    }    
}


/*
=======================================================================
    Listelements / Columns
=======================================================================
*/

:root {
    --teaser-font-weight: 400;
}


/*
=======================================================================
    Pagination
=======================================================================
*/

@include media-breakpoint-up(xl) {
    :root {
        --bs-pagination-padding-x: 1.5rem;
        --bs-pagination-padding-y: 0.75rem;
        --bs-pagination-font-size: 1.25rem;
        --bs-pagination-border-radius: 0.5rem;
    }
}


/*
=======================================================================
    Border Radius
=======================================================================
*/

:root {
    --border-radius-default: 12px;
    --button-border-radius: 50rem;
}

@include media-breakpoint-up(xl) {
    :root {
        --border-radius-default: 18px;
    }
}


/*
=======================================================================
    Paths
=======================================================================
*/

$fa-font-path: '~@fortawesome/fontawesome-pro/webfonts';


/*
=======================================================================
    Superfish Drop Down
=======================================================================
*/

:root {
    
    --head-navigation-margin: 10px;
    --head-navigation-border-width: 2px;
    --head-navigation-border-style: solid;
    --head-navigation-border-color: #333;
    --head-navigation-background-color:transparent;
    --head-navigation-background-color-hover:#333;
    --head-navigation-color: #333;
    --head-navigation-color-hover: #fff;
    --head-navigation-font-size: 1rem;
    --head-navigation-font-weight: 400;
    --head-navigation-padding: .6em 1em;

    --head-navigation-dropdown-vertical-icon: "\f107";
    --head-navigation-dropdown-right-icon: "\f105";
    --head-navigation-dropdown-color: #333;
    --head-navigation-dropdown-color-hover: #fff;
    --head-navigation-dropdown-background-initial: transparent;
    --head-navigation-dropdown-background-hover: #333;

    --head-navigation-dropdown-submenu-color: rgba(255,255,255,0.6);
    --head-navigation-dropdown-submenu-color-hover: rgba(255,255,255,1);
    --head-navigation-dropdown-submenu-background-initial: #333;
    --head-navigation-dropdown-submenu-background-hover: rgb(36, 36, 36); 
    --head-navigation-dropdown-submenu-font-weight: 300;

}

@include media-breakpoint-up(xl) {
    :root { 
        --head-navigation-font-size: 1.25rem;
    }
}


/*
=======================================================================
    Block List Gallery
=======================================================================
*/

.mfp-container {
    --gallery-nav-color-initial: #fff;
    --gallery-nav-color-hover: #333;
    --gallery-nav-background-color: #000;
}