.block__list {
    &.block__list__shortcuts {
        position: relative; 

        .block__header {
            margin-bottom: var(--list-gutter-y);

            .block__header__wrapper {
                align-items: flex-end;

                .block__header__wrapper__left {
                    .block__header__preTitle {
                        text-transform: uppercase;
                    }
                }

                .block__header__wrapper__right {
                    a {
                        font-size:1.25rem;
                        font-weight:500;
                        display:flex;
                        gap:.5rem;
                        text-decoration: none;

                        &:hover,
                        &:focus,
                        &:focus-visible {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }

        .block__body {

            .listelement {
                @include media-breakpoint-down(xl) {
                    --list-gutter-y: 1.5rem;
                }

                a {
                    display:block;
                    text-decoration:none;
                    color: var(--bs-body-text-color);
                    background-color:#fff;                

                    .listelement__media {
                        position:relative;

                        figure {
                            width:100%;
                            height:100%;
                            object-fit: cover;
                        }

                        &::after {
                            content:"";
                            position:absolute;
                            z-index: 1;
                            top:1rem;
                            right:1rem;
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='1em' viewBox='0 0 448 512'%3E%3Cpath d='M429.8 273l17-17-17-17L276.2 85.4l-17-17-33.9 33.9 17 17L354.9 232 24 232 0 232l0 48 24 0 330.8 0L242.2 392.6l-17 17 33.9 33.9 17-17L429.8 273z'/%3E%3C/svg%3E");
                            background-size:contain;
                            background-repeat: no-repeat;
                            background-position: center center;
                            padding:.45rem;
                            border:.5rem solid #fff;
                            border-radius:30px;
                            background-color:#fff;
                            
                            @include media-breakpoint-up(lg) {
                                padding:.6rem;
                            }

                        }
                    }

                    .listelement__body {
                        display: flex;
                        flex-direction: column;
                        gap: 0.5rem;                        
                        padding: 1rem;

                        .title {
                            padding:0;
                            margin:0;
                        }

                        .product-labels {
                            flex-wrap:wrap;

                            .label {                                
                                span {
                                    --label-background-color: #EFF0F3;
                                    font-family: 'Wix Madefor Display', sans-serif;
                                }
                            }
                        }
                    }
                }
            }
        }

        .block__footer {
            margin-top: var(--margin-default);

            .container {
                text-align:center;
            }
        }
    }
}


/*
=======================================================================
    Layouts
=======================================================================
*/

.block__list {
    &.block__list__shortcuts {
        &.layout--100w-horizontal {
            .block__body {
                .listelement {
                    a {
                        @include media-breakpoint-up(lg) {
                            display:flex;
                            align-items: center;
                            justify-content: space-between;
                            gap:var(--bs-gutter-x);

                            > * {
                                flex:1;
                            }

                            .listelement__body {
                                padding:0;
                            }
                        }
                    }
                }
            }
        }
    }
}


.block__list {
    &.block__list__shortcuts {
        &.layout--33w-vertical {
            .block__body {
                .listelement {
                    a {
                        position:relative;

                        .listelement__body {
                            padding:0;

                            .title {
                                position:absolute;
                                top:1rem;
                                left:1rem;
                                background-color:#fff;
                                border-radius:10rem;
                                padding:.5rem 1rem;
                                font-size:1rem;
                                font-weight:500;
                                text-transform:uppercase;
                            }

                            .teaser {
                                display:none;
                            }

                            .product-labels {
                                position:absolute;
                                bottom:1rem;
                                left:1rem;

                                .label {
                                    span {
                                        --label-border-color: #CCCCCC99;
                                        --label-background-color: #CCCCCC99;
                                        color:#fff;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


/*
=======================================================================
    Aspect Ratios
=======================================================================
*/

.block__list {
    &.block__list__shortcuts {
    
        &.aspect-ratio--1by1 .listelement__media {
            aspect-ratio: 1 / 1;
            object-fit: cover;
            object-position: center;
        }

        &.aspect-ratio--3by2 .listelement__media {
            aspect-ratio: 3 / 2;
            object-fit: cover;
            object-position: center;
        }

        &.aspect-ratio--4by3 .listelement__media {
            aspect-ratio: 4 / 3;
            object-fit: cover;
            object-position: center;
        }

        &.aspect-ratio--5by4 .listelement__media {
            aspect-ratio: 5 / 4;
            object-fit: cover;
            object-position: center;
        }

        &.aspect-ratio--7by6 .listelement__media {
            aspect-ratio: 7 / 6;
            object-fit: cover;
            object-position: center;
        }    

        &.aspect-ratio--16by9 .listelement__media {
            aspect-ratio: 16 / 9;
            object-fit: cover;
            object-position: center;
        }
    }
}


/*
=======================================================================
    Aspect Ratios
=======================================================================
*/

.block__list {
    &.block__list__shortcuts {
        .block__body {
            .listelement {
                a {
                    .listelement__media.arrow--disabled {
                        &::after {
                            display:none;
                        }
                    }
                }
            }
        }
    }
}