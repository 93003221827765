.block__shop__checkout {

    .block__header {
        .block__header__title {
            + h2 {
                margin-top: var(--spacer-2);
            }
        }
    }

    .form-check {
        label {
            padding: var(--padding-button-vertical) var(--padding-button-horizontal);
            font-size: var(--font-size-button);
            font-weight:500;
            border-radius: var(--button-border-radius);
            text-align:center;
            border: 2px solid var(--color-main-hex);

            &::before {
                position:initial;
                margin-right:.5rem;
                transform:unset;
            }
        }

        input[type=radio] {
            &:checked {
                + label {
                    background-color: var(--color-main-hex);
                    color:#fff;

                    &::before {
                        color:#fff;
                    }
                }
            }
        }
    }        

    fieldset {
        + fieldset {
            margin-top: var(--spacer-5);
        }

        legend {
            margin-bottom: var(--spacer-1);
        }
    }

    .virtual-form {
        h2 {
            margin-top:var(--spacer-2);
        }

        .virtual-input {
            font-size:1rem;
            font-weight:500;
        }
    }

    .form__body {
        .row {
            --bs-gutter-y: 1rem;
        }
    }

    .form__footer {
        margin-top: var(--spacer-5);
    }
    
    .block__shop__checkout__orderInfo {
        margin-bottom: var(--spacer-5);

        .block__shop__checkout__orderInfo__body {
            margin-top: var(--spacer-5);

            .form__body {
                margin-top: var(--spacer-3);
            }
        }
    }

    .block__shop__checkout__usertype {
        margin-bottom: var(--spacer-5);

        .block__shop__checkout__usertype__body {
            margin-top: var(--spacer-5);

            .h2 {
                font-size: var(--bs-body-font-size);
                font-weight: 500;
            }

            .form__body {
                margin-top: var(--spacer-3);

                .form-group {
                    display:flex;
                    gap:1rem;
                }
            }
        }
    }

    .block__shop__checkout__userinfo {
        margin-bottom: var(--spacer-5);
        
        .block__shop__checkout__userinfo__body {
            margin-top: var(--spacer-3);

            /* Initially hide both private and business input forms */
            @at-root .checkoutStep--1 .block__shop__checkout__userinfo__body {
                display:none;
            }

            .h2 {
                font-size: var(--bs-body-font-size);
                font-weight: 500;
            }

            .form__body {
                margin-top: var(--spacer-3);
            }
        }
    }

    .block__shop__checkout__shipping {
        margin-bottom: var(--spacer-5);

        .block__shop__checkout__shipping__body {
            margin-top: var(--spacer-3);

            .h2 {
                font-size: var(--bs-body-font-size);
                font-weight: 500;
            }

            .form__body {
                .form-group {
                    display:flex;
                    gap:1rem;
                }
            }
        }
    }

    .block__shop__checkout__payment {
        margin-bottom: var(--spacer-5);

        .block__shop__checkout__payment__body {
            margin-top: var(--spacer-3);

            .h2 {
                font-size: var(--bs-body-font-size);
                font-weight: 500;
            }

            form {
                .form-group {
                    display:flex;
                    gap:1rem;
                }
            }
        }
    }

    .block__shop__checkout__nav {
        margin-top: calc(var(--spacer-5)*1.5);

        .block__shop__checkout__nav__body {
            display:grid;
            grid-template-columns: repeat(2, 1fr);
            gap:1rem;

            .bttn {
                padding:1rem .75rem;
                font-weight:300;
            }
        }
    }

    .block__shop__checkout__summary {
        position:sticky;
        top: calc(var(--header-height) + var(--spacer-2));

        .block__shop__checkout__summary__header {
            display:flex;
            gap:1rem;
            flex-wrap:wrap;
            justify-content: space-between;
            align-items:center;
        }

        .block__shop__checkout__summary__body {
            margin-top: var(--spacer-5);

            .block__shop__checkout__summary__list {
                display:flex;
                flex-direction: column;
                gap: var(--spacer-2);

                .listelement {
                    display:flex;
                    justify-content: flex-start;
                    align-items:flex-start;
                    gap: var(--spacer-2);
                    width:100%;

                    .listelement__image {
                        width:50px;
                        height:50px;
                        position:relative;

                        @include media-breakpoint-up(lg) {
                            width:90px;
                            height:90px;
                        }

                        figure {
                            width:100%;
                            height:100%;
                            margin-bottom: 0;
                            position:relative;
                            
                            img {
                                width:100%;
                                height:100%;
                                object-fit: contain;
                            }

                            &::after {
                                @include image-overlay;
                            }
                        }
                    }
                    
                    .listelement__meta {
                        display:flex;
                        justify-content: space-between;
                        align-items: center;
                        flex:1;
                        gap:1rem;

                        > &:last-child {
                            @include media-breakpoint-up(sm) {
                                text-align:right;
                            }
                        }

                        .listelement__meta__title {
                            font-size: var(--bs-body-font-size);
                        }

                        .listelement__meta__variantName {
                            margin-top:.25rem;
                            color: #626871;
                            font-weight:500;
                            font-size:calc(var(--bs-body-font-size)*0.75);
                        }
    
                        .listelement__meta__unitPrice {
                            margin-top:.25rem;
                            font-family: 'Wix Madefor Display', sans-serif;
                            font-size:calc(var(--bs-body-font-size)*0.875);
                            font-weight:600;
                        }
    
                        .listelement__meta__price {
                            font-family: 'Wix Madefor Display', sans-serif;
                            font-size: var(--bs-body-font-size);
                            font-weight:700;
                        }
    
                        .listelement__meta__qty {
                            margin-top:.25rem;
                            font-family: 'Wix Madefor Display', sans-serif;
                            font-size:.875rem;
                            font-weight:600;
                            text-align: right;
                        }
                    }
                }
            }

            .block__shop__checkout__summary__overview {
                margin-top: var(--spacer-5);

                > div {
                    + div {
                        border-top:1px solid #EFF0F3;
                        padding-top:var(--spacer-1);
                        margin-top:var(--spacer-1);
                    }
                }

                table {
                    tr {
                        th {
                            font-weight: normal;
                        }

                        th,
                        td {
                            padding-block:0;

                            &:first-child {
                                padding-left:0;
                            }
    
                            &:last-child {
                                text-align: right;
                                padding-right:0;
                            }
                        }


                        &.totalExShipping {
                            font-family: 'Wix Madefor Display', sans-serif;
                            font-weight:600;
                        }

                        &.shipping {
                            font-family: 'Wix Madefor Display', sans-serif;
                            font-weight:600;
                        }

                        &.totalVat {
                            > * {
                                font-size:calc(var(--bs-body-font-size)*1.25);
                                font-family: 'Wix Madefor Display', sans-serif;
                                font-weight:700;
                            }
                        }
                    }
                }
            }
        }

        .block__shop__checkout__summary__footer {
            margin-top: var(--spacer-5);
        }
    }
}