.block__list {
    .block__body {
        .list {
            .listelement {

                .listelement__media {
                    figure {
                        margin: 0;
                        overflow: hidden;
                        position: relative;

                        img {
                            @include transition--slow;
                            aspect-ratio: 16 / 10;
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }

                        &::after {
                            @include transition;
                            @include image-overlay;
                        }
                    }
                }

                .listelement__body {
                    background-color: #fff;
                    padding: var(--padding-default);

                    > * {
                        margin-bottom: .5rem;
                    }

                    > :last-child {
                        margin-bottom: 0;
                    }

                    .byline {
                        font-size: 1rem;
                        opacity: .5;
                    }

                    .title {
                        margin: 0 0 .5rem 0;
                    }

                    .teaser {
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        font-weight: var(--teaser-font-weight);

                        p {
                            &:last-child {
                                margin: 0;
                            }
                        }
                    }

                    .link {
                        color: var(--bs-body-color);
                    }
                }
                
                /* For linked list elements */
                > a {
                    text-decoration: none;

                    &:hover,
                    &:focus {
                        .listelement__media {
                            figure {
                                img {
                                    transform: scale(1.05);
                                }

                                &:after {
                                    background-color: rgba(0,0,0,0.1);
                                }
                            }

                            .link {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }
    }

    .block__footer {
        margin-top: var(--margin-default);

        .pagination {
            --bs-pagination-color: #000;

            .page-item {
                a {
                    @include reset-border;
                    font-size: var(--bs-body-font-size);

                    @include media-breakpoint-down(sm) {
                        font-size: .75rem;

                        --bs-pagination-padding-x: 1rem;
                        --bs-pagination-padding-y: .5rem;
                    }
                }

                &.active {
                    .page-link {
                        background-color: var(--color-main-accent-hex);
                    }
                }
            }
        }
    }
}


/*
=======================================================================
    Five Columns
=======================================================================
*/

@include media-breakpoint-down(sm) {
    .col-five {
        flex: 0 0 auto;
        width:20%;
    }
}

@include media-breakpoint-between(sm, md) {
    .col-sm-five {
        flex: 0 0 auto;
        width:20%;
    }
}

@include media-breakpoint-between(md, lg) {
    .col-md-five {
        flex: 0 0 auto;
        width:20%;
    }
}

@include media-breakpoint-between(lg, xl) {
    .col-lg-five {
        flex: 0 0 auto;
        width:20%;
    }
}

@include media-breakpoint-between(xl, xxl) {
    .col-xl-five {
        flex: 0 0 auto;
        width:20%;
    }
}

@include media-breakpoint-up(xxl) {
    .col-xxl-five {
        flex: 0 0 auto;
        width:20%;
    }
}