.bttn {
    @include reset-border;
    display: inline-block;
    padding: var(--padding-button-vertical) var(--padding-button-horizontal);
    font-family: 'Wix Madefor Display', sans-serif;
    font-size: var(--font-size-button);
    font-weight: var(--font-weight-button);
    border: 2px solid var(--button-border-color);
    border-radius: var(--button-border-radius);
    background-color: var(--button-background-color);
    color: var(--button-text-color);
    text-decoration: none;
    text-align:center;

    &:hover,
    &:hover span,
    &:focus,
    &:focus span {
        border-color: var(--button-hover-border-color);
        background-color: var(--button-hover-background-color);
        color: var(--button-hover-text-color);
    }

    &.bttn--outline {
        border-color: var(--button-outline-border-color);
        border-width: var(--button-outline-border-width);
        border-style: solid;
        background-color: transparent;
        color: var(--button-outline-text-color);

        &:hover,
        &:hover span,
        &:focus,
        &:focus span {
            border-color: var(--button-hover-outline-border-color);
            background-color: var(--button-hover-outline-background-color);
            color: var(--button-hover-outline-text-color);

            svg {
                fill: var(--button-hover-outline-text-color);
            }

            &::before {color: var(--button-hover-outline-text-color);}
            &::after {color: var(--button-hover-outline-text-color);}
        }
    }


/*
=======================================================================
    Button Size
=======================================================================
*/

    &.bttn--small {
        padding: var(--padding-button-small-vertical) var(--padding-button-small-horizontal);
        font-size: var(--font-size-button-small);
    }


/*
=======================================================================
    Button Icons
=======================================================================
*/

    > span:before {
        color: var(--color-main-hex);
    }

    /* Unused icons
    &.bttn--list > span:before {
        @include fontawesomesharp;
        content: "\f03a";
        margin-right: .5rem;
    }

    &.bttn--map > span:before {
        @include fontawesomesharp;
        content: "\f279";
        margin-right: .5rem;
    }

    &.bttn--archive > span:before {
        @include fontawesomesharp;
        content: "\f03a";
        margin-right: .5rem;
    }

    &.bttn--email > span:before {
        @include fontawesomesharp;
        content: "\40";
        margin-right: .5rem;
    }

    &.bttn--phone > span:before {
        @include fontawesomesharp;
        content: "\f3ce";
        margin-right: .5rem;
    }
    */

    &.bttn--right > span {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        
        svg {
            width: 18px;
            height: 25px;
        }
    }

    &.bttn--seeOrderConfirmation > span {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;

        svg {
            width: 15px;
            height: 25px;
        }
    }

    &.bttn--orderAgain > span {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;

        svg {
            width: 20px;
            height: 25px;
        }
    }

    &.bttn--logOut > span {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;

        svg{
            width: 20px;
            height: 17px;
        }
    }    

    &.bttn--wishlist > span {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;

        svg{
            width: 20px;
            height: 20px;
        }
    }

    &.bttn--contact > span {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;

        svg{
            width: 25px;
            height: 25px;
        }
    }

}


/*
=======================================================================
    Link
=======================================================================
*/

.link {
    background-color:transparent;
    padding:0;
    border:0;
    color: var(--color-link-hex);
    text-decoration: underline;

    &:hover,
    &:focus {
        color:var(--color-link-hover-hex);
        text-decoration: underline;
    }

    &.link--download {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        gap: 0.75rem;
        svg{
            width:12px;
            height:20px;
        }
    }
}


/*
=======================================================================
    Custom button with blur
=======================================================================
*/

.bttn--custom {
    --button-border-color: #fff;
    --button-background-color: transparent;

    display:inline-flex;
    align-items:center;
    gap:.75rem;
    padding:.75rem 1.25rem;
    position:relative;
    border-radius: 100px;
    border-width:1px;
    overflow: hidden;

    &:hover {
        --button-hover-background-color:transparent;
        --button-hover-border-color:#fff;

        &::before {
            background-color: rgba(var(--color-black-rgb), 0.6);
        }
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        backdrop-filter: blur(10px);
        background-color: rgba(var(--color-black-rgb), 0.5);
    }

    .bttn__icon {
        z-index: 1;

        svg {
            height:100%;
            object-fit: contain;
            fill:#fff;
        }
    }

    .bttn__text {
        text-transform: uppercase;
        letter-spacing:1.5px;
        color:#fff;
        font-weight:400;
        z-index: 1;

    }


    /* Custom colors */
    
    &.color--black {
        &::before {
            background-color: rgba(var(--color-black-rgb), 0.5);
        }
        &:hover {
            &::before {
                background-color: rgba(var(--color-black-rgb), 0.7);
            }   
        }
    }

    &.color--white {
        &::before {
            background-color: rgba(var(--color-white-rgb), 0.5);
        }
        &:hover {
            &::before {
                background-color: rgba(var(--color-white-rgb), 0.7);
            }   
        }
    }

    &.color--color1 {
        &::before {
            background-color: rgba(var(--color-custom-1-rgb), 0.5);
        }
        &:hover {
            &::before {
                background-color: rgba(var(--color-custom-1-rgb), 0.7);
            }   
        }
    }

    &.color--color2 {
        &::before {
            background-color: rgba(var(--color-custom-2-rgb), 0.5);
        }
        &:hover {
            &::before {
                background-color: rgba(var(--color-custom-2-rgb), 0.7);
            }   
        }
    }

    &.color--color3 {
        &::before {
            background-color: rgba(var(--color-custom-3-rgb), 0.5);
        }
        &:hover {
            &::before {
                background-color: rgba(var(--color-custom-3-rgb), 0.7);
            }   
        }
    }

    &.color--color4 {
        &::before {
            background-color: rgba(var(--color-custom-4-rgb), 0.5);
        }
        &:hover {
            &::before {
                background-color: rgba(var(--color-custom-4-rgb), 0.7);
            }   
        }
    }

    &.color--color5 {
        &::before {
            background-color: rgba(var(--color-custom-5-rgb), 0.5);
        }
        &:hover {
            &::before {
                background-color: rgba(var(--color-custom-5-rgb), 0.7);
            }   
        }
    }    
}


/*
=======================================================================
    Wysiwyg Button -- NOT USED
=======================================================================


.wysiwyg__button {
    a {
        @extend .bttn;
        --bs-body-color: var(--color, var(--color-custom-4-hex));

        --button-border-color: var(--color-custom-4-hex);
        --button-background-color: var(--color-custom-4-hex);
        --button-background-color-rgb: var(--color-custom-4-rgb);
        --button-text-color: var(--color-main-hex);

        --button-hover-border-color: var(--color-custom-4-hover-hex);
        --button-hover-background-color: var(--color-custom-4-hover-hex);
        --button-hover-text-color: var(--color-main-hex);

        &:after {
            @include fontawesome;
            content: "\f061";
            margin-left: .5rem;
            font-weight:600;
        }

        &:hover,
        &:focus,
        &:focus-visible {
            text-decoration: none;
        }
    }
}

 */