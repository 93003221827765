.block__col {
    .block__body {
        .list {
            .listelement {

                .listelement__media {
                    figure {
                        margin:0;
                        overflow:hidden;
                        position:relative;

                        &::after {
                            @include transition;
                            @include image-overlay;
                        }                    
                    }
                }                

                .listelement__body {

                    > * {margin-top: var(--margin-default);}

                    h3 {
                        margin:0;
                        font-size: calc(var(--bs-body-font-size)*1.5);
                    }

                    .teaser {
                        font-size: var(--bs-body-font-size);
                        font-weight:var(--teaser-font-weight);
                    }
                }
            }
        }
    }
}