footer {
    position:relative;
    background: var(--footer-background-color);
    padding-top: var(--spacer-5);
    padding-bottom: var(--spacer-5);
    font-size:1rem;
    color: var(--footer-text-color);

    a {
        text-decoration: none;

        &:hover,
        &:focus,
        &:focus-visible {
            text-decoration: underline;
        }
    }

    .container {
        display:flex;
        flex-direction: column;
        gap:var(--spacer-5);
    }

    .footer__header {
        display:flex;
        justify-content: space-between;

        @include media-breakpoint-down(sm) {
            flex-direction: column;
            gap:var(--spacer-3);
        }

        @include media-breakpoint-down(lg) {
            flex-direction: column;
            gap:var(--spacer-5);
        }
        
        &__left {
            .logo {
                height: 32px;
                
                svg {
                    fill:var(--color-main-hex);
                }
            }
        }

        &__right {
            display:flex;
            gap:1rem;
            align-items: center;

            @include media-breakpoint-down(sm) {
                flex-direction: column;
                align-items:flex-start;
            }

            @include media-breakpoint-down(xl) {
                justify-content: space-between;
            }

            .soMe {
                @include media-breakpoint-down(xl) {
                    order:2;
                }

                ul {
                    @include reset-ul;
                    display:flex;
                    gap:1rem;

                    li {
                        @include reset-li;

                        a {
                            display:block;
                            width:3.125rem;
                            height: 3.125rem;
                            border:1px solid var(--color-main-hex);
                            border-radius:5rem;
                            position:relative;

                            svg {
                                position:absolute;
                                top:50%;
                                left:50%;
                                transform:translate(-50%, -50%);
                                fill: var(--color-main-hex);

                            }
                            
                            &:hover,
                            &:focus,
                            &:focus-visible {
                                background: var(--color-main-hex);

                                svg {
                                    fill: var(--color-white-hex);
                                }
                            }
                        }
                    }
                }
            }

            .tel {
                @include media-breakpoint-down(xl) {
                    order:1;
                }

                a {
                    padding: .5rem 1.25rem;
                    border-radius:10rem;
                    font-weight:500;
                    text-decoration: none;
                    display:inline-block;

                    svg {
                        fill: #0D2240;
                        margin-right:.5rem;
                    }
                }
            }
        }
    }

    .footer__body {
        display:flex;
        gap:var(--spacer-5);
        justify-content: space-between;

        @include media-breakpoint-down(lg) {
            flex-direction: column;
        }

        @include media-breakpoint-up(lg) {
            gap:calc(var(--spacer-5)*2);
        }

        &__left {
        
            @include media-breakpoint-up(lg) {
                width:50%;
            }

            .text {

                h2 {
                    font-size:1.5rem;
                    font-weight:500;
                }
            }
          
            .newsletter {
                max-width:345px;
                margin-top: var(--margin-default);
                position:relative;

                &__input {
                    input {
                        width:100%;
                        height:2.5rem;
                        padding:.5rem 1rem;
                        border: 1px solid var(--color-main-hex);
                        border-radius:5rem;

                        &:focus {
                            outline:none;
                        }
                    }
                }

                &__btn {
                
                    > * {
                        border:0;
                        background:var(--color-custom-4-hex);
                        width:2rem;
                        height:2rem;
                        border-radius:2.25rem;
                        position:absolute;
                        top:50%;
                        transform:translateY(-50%);
                        right:.25rem;
                        display: grid;
                        place-content: center;

                        &:hover,
                        &:focus,
                        &:focus-visible {
                            background:var(--color-custom-4-hover-hex);
                        }
                    }
                }
            }
        }

        &__right {
            display:flex;
            gap:var(--spacer-5);

            @include media-breakpoint-up(lg) {
                gap:calc(var(--spacer-5)*2);
            }

            @include media-breakpoint-down(lg) {
                flex-direction: column;
            }

            .column {
                
                @include media-breakpoint-up(lg) {
                    flex:1;
                }

                h2 {
                    font-size: .875rem;
                    font-weight:500;
                    text-transform: uppercase;
                    line-height:1.25;
                    letter-spacing: .0875rem;
                    margin-bottom: 0.875rem;
                }

                ul {
                    @include reset-ul;
                    display:flex;
                    flex-direction: column;
                    gap:.25rem;

                    li {
                        @include reset-li;

                        a {
                            font-weight:500;
                        }
                    }
                }
            }
        }
    }

    .footer__footer {
        display:flex;
        justify-content: space-between;
        gap:var(--spacer-3);
        align-items: center;

        @include media-breakpoint-down(lg) {
            flex-direction: column;
            align-items:flex-start;
        }
        
        &__left {
            ul {
                @include reset-ul;
                display:flex;
                flex-wrap:wrap;
                gap: var(--spacer-5);

                @include media-breakpoint-down(md) {
                    flex-direction: column;
                    align-items:flex-start;
                    gap:.25rem;
                }

                li {
                    @include reset-li;

                    a {
                        line-height:1;
                        font-weight:600;
                        
                        svg {
                            stroke: var(--color-main-hex);
                            margin-right:.25rem;
                        }
                    }
                }
            }
        }

        &__right {
            font-weight:500;
        }
    }
}