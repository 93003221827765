
.skeleton-search {
    animation: skeleton-loading 1s linear infinite alternate;
    border-radius: 0.125rem;
    height: 1.25rem;
    width: 20rem;
    max-width: 100%;
}
@keyframes skeleton-loading {
    0% {
        background-color: #fcfcfc;
    }
    100% {
        background-color: #ebebeb;
    }
}

