/*.block:not(.block__shop__category__swipe) {display:none;}*/

.block__shop__category__slide {
    width:100%;

    &.layout--carousel {
        @include media-breakpoint-down(xl) {
            display:none;
        }
    }

    .block__header {
        margin-bottom: var(--list-gutter-y);

        .block__header__title {
            text-align:center;
        }
    }

    .block__body {

        .list {
            .listelement {
                width:100%;
                text-align:center;

                figure {
                    margin-inline:auto;
                    border-radius:1rem;
                    overflow: hidden;

                    @include media-breakpoint-up(lg) {
                        border-radius:1.25rem;
                    }

                    @include media-breakpoint-up(xl) {
                        border-radius:1.25rem;
                    }                    

                    img {
                        aspect-ratio: 1 / 1;
                        object-fit: cover;
                        object-position: center center;
                        width:100%;
                    }
                }
                
                h3 {
                    margin:.5rem 0 0 0;
                    font-size: 1rem;
                }

                a {
                    text-decoration: none;

                    &:hover,
                    &:focus-visible {
                        text-decoration: underline;
                    }
                }
            }            
        }

        .block__shop__category__slide__wrapper {
            display: grid;
            grid-template-columns: auto 1fr;
            position:relative;

            &::after {
                position:absolute;
                top:0;
                right:0;                    
                width:50px;
                height:100%;
                content:"";
                background: rgb(255,255,255);
                background: linear-gradient(270deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
                z-index: 1;
                pointer-events: none;

                @include media-breakpoint-up(lg) {
                    width:80px;
                }

                @include media-breakpoint-up(xl) {
                    width:100px;
                }
            }

            .active-category {
                position:relative;
                display:flex;
                gap: var(--spacer-4);
                margin-right: calc(var(--spacer-4) * 2);

                .link--back {
                    display:flex;
                    flex-direction: column;
                    gap:.5rem;
                    margin-top:25px;

                    @include media-breakpoint-up(lg) {
                        margin-top:45px;
                    }

                    @include media-breakpoint-up(xl) {
                        margin-top:70px;
                    } 

                    .link__icon {
                        text-align: center;
                        transform: rotate(-180deg);
                    }                 
                }

                .listelement {
                    position:relative;
                    width:100px;

                    @include media-breakpoint-up(lg) {
                        width:150px;
                    }

                    @include media-breakpoint-up(xl) {
                        width:200px;
                    }

                    &::after {
                        content:"";
                        display:block;
                        width:1px;
                        height:100px;
                        background: #EFF0F3;
                        position:absolute;
                        top:0;
                        right: calc(var(--spacer-4) * -1);

                        @include media-breakpoint-up(lg) {
                            height:150px;
                        }
    
                        @include media-breakpoint-up(xl) {
                            height:200px;
                        }
                    }
                    
                    &.small {
                        width: 75px;

                        @include media-breakpoint-up(lg) {
                            width: 100px;
                        }

                        @include media-breakpoint-up(xl) {
                            width: 125px;
                        }
                        
                        &::after {
                            height: 75px;

                            @include media-breakpoint-up(lg) {
                                height: 100px;
                            }

                            @include media-breakpoint-up(xl) {
                                height: 125px;
                            }
                        }
                    }
                }
            }

            .category-slider {
                overflow: hidden;
                position: relative;

                .slider {
                    flex-grow: 1; /* Make the element expand to fill the remaining space */
                    /*overflow: visible;*/
                    min-width:0;
                }
            }            
        }
    }
}


/*
=======================================================================
    Experimental horizontal native scrollbar
=======================================================================
*/

%block__shop__category__swipe__slide {
    display: inline-block;
    width: 100px;
    margin-bottom: var(--spacer-2);
    flex-shrink: 0;

    h3 {
        font-size:.8rem;
        color: var(--color-main-hex);
    }

    @include media-breakpoint-up(lg) {
        width: 150px;

        h3 {
            font-size: .875rem;
        }
    }

    @include media-breakpoint-up(xl) {
        width: 200px;

        h3 {
            font-size: 1rem;
        }
    }
    
    &.small {
        width: 75px;

        @include media-breakpoint-up(lg) {
            width: 100px;
        }
        
        @include media-breakpoint-up(xl) {
            width: 125px;
        }
    }
}

.block__shop__category__slide {
    .slider-wrapper {
        width: 100%;
        overflow-x: auto;
        cursor: grab; /* indicates the element is draggable */
        transition: transform 0.3s ease-out; /* Add transition to the transform property */
        display:flex;
        gap: var(--spacer-4);

        &.grabbing {
            cursor: grabbing; /* indicates dragging is happening */
        }

        &::-webkit-scrollbar {
            height: 5px; /* horizontal scrollbar */
        }

        &::-webkit-scrollbar-track {
            background-color: #f4f4f4;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: var(--color-main-hex);
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background-color: var(--color-main-hover-hex);
        }

        /* For Firefox */
        scrollbar-width: thin; /* or "auto" or "none" */
        scrollbar-color: #888 #f4f4f4; /* thumb and track color */

        /* For IE and Edge */
        -ms-overflow-style: -ms-autohiding-scrollbar; /* This will show scrollbars only when interacting with the element */

        .slide {
            @extend %block__shop__category__swipe__slide;
        }
    }
    
    .list.categories_grid {
        gap: var(--spacer-2) var(--spacer-4);
        display: inline-flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-start;
        max-width: calc(var(--grid-columns, 5) * 100px + calc(var(--grid-columns, 5) - 1) * var(--spacer-4));

        @include media-breakpoint-up(lg) {
            max-width: calc(var(--grid-columns, 5) * 150px + calc(var(--grid-columns, 5) - 1) * var(--spacer-4));
        }

        @include media-breakpoint-up(xl) {
            max-width: calc(var(--grid-columns, 5) * 200px + calc(var(--grid-columns, 5) - 1) * var(--spacer-4));
        }
        
        .categories_grid__element {
            @extend %block__shop__category__swipe__slide;
        }
    }
}