.block__video {
    .block__body {
        figure {
            position:relative;

            video, iframe {
                width: 100%!important;
                height: 100%!important;

                &.playing {
                    + .play-button {
                        display:none;
                    }
                }
            }

            .play-button {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 1;
                line-height: 28px;
                border-radius: 50px;
                background-color: rgba(var(--color-white-rgb), 1);
                color: var(--color-main-hex);
                text-align: center;
                padding: 8px 16px;
                display: flex;
                align-items: center;
                font-weight: 700;
                font-size: 16px;
                gap: 8px;
                width:auto;
                height:auto;
                
                @include media-breakpoint-up(md) {
                    padding: 20px 32px;
                    font-size: 24px;
                }
                
                &:hover {
                    cursor: pointer;
                }

                &:focus-visible {
                    border-color: #000;
                }
                
                svg {
                    height: 16px;

                    @include media-breakpoint-up(md) {
                        height: 24px;
                    }
                }
            }
        }
    }
}