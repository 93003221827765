:root {
    --bs-offcanvas-color: var(--bs-body-color);
    --bs-offcanvas-border-width: 0;
}

.offcanvas {
    top: 1rem;
    max-height:calc(100% - (1rem * 2));
    bottom: auto;
    border-radius: var(--border-radius-default);
    /*transition: all 0.6s cubic-bezier(0.68, -0.55, 0.27, 1.55);*/
    --bs-offcanvas-width: calc(100% - (1rem * 2));

    &.offcanvas-start {
        top:1rem;
        left:1rem;
    }

    &.offcanvas-end {
        top:1rem;
        right:1rem;
    }

    @include media-breakpoint-down(md) {
        width:calc(100% - (1rem * 2));
    }

    @include media-breakpoint-up(md) {
        --bs-offcanvas-width: 768px;
        max-width:calc(100% - (1rem * 2));
    }

    @include media-breakpoint-up(lg) {
        --bs-offcanvas-width: 800px
    }

    .bttn--close {
        padding:.5rem 1rem;
        position:absolute;
        top:1.5rem;
        right:1rem;

        &::after {
            content: '';
            display: block;
            width: 1.25rem;
            height: 1.25rem;
            background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M345 137c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-119 119L73 103c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l119 119L39 375c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l119-119L311 409c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-119-119L345 137z" fill="%230D2240"/></svg>') center no-repeat;
        }
    }

    .offcanvas-body {
        --bs-offcanvas-padding-x: 5rem;
        --bs-offcanvas-padding-y: 3rem;
        overflow-y: auto;

        scrollbar-width: thin; /* or auto or none */
        scrollbar-color: var(--color-custom-1-hex) var(--color-custom-3-hex); /* thumb and track color */

        /* The scrollbar track */
        &::-webkit-scrollbar {
            width: 5px; /* for vertical scrollbars */
            background: var(--color-custom-3-hex);
        }

        /* The scrollbar handle */
        &::-webkit-scrollbar-thumb {
            background: var(--bs-body-color);
            border-radius: 4px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: var(--color-custom-1-hover-hex);
        }        

        @include media-breakpoint-up(md) {
            --bs-offcanvas-width: 600px;
        }

        @include media-breakpoint-up(lg) {
            --bs-offcanvas-width: 800px;
        }
    }
}