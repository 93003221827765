// Whole file not in use.
.block__info {
    width:100%;
    padding-top: var(--padding-block);
    margin-bottom: var(--padding-block);
    border:0;
    border-radius:0;
    box-shadow:none;
    transition: height 0.3s ease, padding 0.3s ease, margin 0.3s ease;
    overflow: hidden;

    /*
    height: 0;
    padding: 0;
    margin: 0;

    &.show {
        height: initial;
        padding: initial;
        margin: initial;
    }
    */

    .bttn--close {
        padding:.5rem 1rem;
        position:absolute;
        top:1rem;
        right:1rem;
        border:0;
        background:transparent;

        &::after {
            @include fontawesome;
            content:"\f00d";
        }
    }

    .block__body {
        .text {
            font-size: var(--bs-body-font-size);
        }
    }

    /* Themes */
    &.theme--warning {
        background-color: #FFDDCC;
    }

    &.theme--info {
        background-color: #ebebeb;
    }

    &.info--success {
        background-color: #d9e8eb;
    }

    /* If info is present subsequent block needs top padding */
    &:not(.hide) {
        + .block {
            padding-top: var(--margin-block)!important;
        }
    }

}