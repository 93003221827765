.block {
    position:relative;
    margin-top: var(--margin-block);
    margin-bottom: var(--margin-block);

    // Remove padding below .block
    /*
    &.pb-0 {
        + .block {
            padding-top:0!important;
        }
    }
    */
}


/*
=======================================================================
    Block spacing (Optional)
=======================================================================
*/

.block {

    &.spacing-top--0 {
        padding-top: 0;
    }

    &.spacing-top--1 {
        padding-top: var(--spacer-1);
    }
    
    &.spacing-top--2 {
        padding-top: var(--spacer-2);
    }
    
    &.spacing-top--3 {
        padding-top: var(--spacer-3);
    }

    &.spacing-top--4 {
        padding-top: var(--spacer-4);
    }
    
    &.spacing-top--x5 {
        padding-top: var(--spacer-5);
    }

    &.spacing-bottom--0 {
        padding-bottom: 0;
    }

    &.spacing-bottom--1 {
        padding-bottom: var(--spacer-1);
    }
    
    &.spacing-bottom--2 {
        padding-bottom: var(--spacer-2);
    }
    
    &.spacing-bottom--3 {
        padding-bottom: var(--spacer-3);
    }

    &.spacing-bottom--4 {
        padding-bottom: var(--spacer-4);
    }
    
    &.spacing-bottom--5 {
        padding-bottom: var(--spacer-5);
    }
}



/*
=======================================================================
    Block Header
=======================================================================
*/

.block__header {
    .block__header__wrapper {
        width:100%;
        display:flex;
        justify-content: space-between;
        align-items:center;
        flex-wrap: wrap;
        gap: var(--spacer-1);
    }

    .block__header__preTitle {
        font-family: 'Wix Madefor Display', sans-serif;
        font-size:0.875rem;
        font-weight:700;
        color: #32363E;
    
        + .block__header__title {
            margin-top: 0.5rem;
        }
    }

    /*
    .block__header__title,
    .block__header__header__title {
        h2 {
            font-size: var(--font-size-xl);
        }        
    }
    */

    .block__header__text {
        margin-top: 1rem;
    }

    .block__header__subtitle {
        margin-top: 1rem;
    }
}

/* Spacing between block header and block body */
/* This value is also used for spacing between the title block (.block__title) and the subsequent block */

:root {
    --headerMargin: var(--margin-default);
}

.block__header {    
    + .block__body {
        margin-top: var(--headerMargin);
    } 
}


/*
=======================================================================
    Block Footer
=======================================================================
*/

.block__footer {
    margin-top: var(--margin-default);
}


/*
=======================================================================
    Block Background
=======================================================================
*/

// Add block padding when background modifier is active
.block[class*="background-color--"]:not(.background-color--white) {
    padding-top: var(--margin-block);
    padding-bottom: var(--margin-block);
    margin-top:0;
    margin-bottom:0;
}

.block__back {
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index: 0;
}


/*
=======================================================================
    Block Background Filter
=======================================================================
*/

.block {
    .block__back {
        height:100%;

        &::before {
            content:"";
            width:100%;
            height:100%;    
            position:absolute;
            bottom:0;
            left:0;
            top:0;
            right:0;   
            z-index: 1;
        }   

        &.filter--black-37 {&::before {background-color: rgba(0,0,0,0.37)}}
        &.filter--black-50 {&::before {background-color: rgba(0,0,0,0.50)}}
        &.filter--black-67 {&::before {background-color: rgba(0,0,0,0.67)}}
    
        &.filter--white-37 {&::before {background-color: rgba(255,255,255,0.37)}}
        &.filter--white-50 {&::before {background-color: rgba(255,255,255,0.50)}}
        &.filter--white-67 {&::before {background-color: rgba(255,255,255,0.67)}}

        // Filter Color 1
        &.filter--1-37 {&::before {background-color: rgba(var(--color-custom-1-rgb),0.37)}}
        &.filter--1-50 {&::before {background-color: rgba(var(--color-custom-1-rgb),0.50)}}
        &.filter--1-67 {&::before {background-color: rgba(var(--color-custom-1-rgb),0.67)}}

        // Filter Color 2
        &.filter--2-37 {&::before {background-color: rgba(var(--color-custom-2-rgb),0.37)}}
        &.filter--2-50 {&::before {background-color: rgba(var(--color-custom-2-rgb),0.50)}}
        &.filter--2-67 {&::before {background-color: rgba(var(--color-custom-2-rgb),0.67)}}
    
        // Filter Color 3
        &.filter--3-37 {&::before {background-color: rgba(var(--color-custom-3-rgb),0.37)}}
        &.filter--3-50 {&::before {background-color: rgba(var(--color-custom-3-rgb),0.50)}}
        &.filter--3-67 {&::before {background-color: rgba(var(--color-custom-3-rgb),0.67)}}
        
        // Filter Color 4
        &.filter--4-37 {&::before {background-color: rgba(var(--color-custom-4-rgb),0.37)}}
        &.filter--4-50 {&::before {background-color: rgba(var(--color-custom-4-rgb),0.50)}}
        &.filter--4-67 {&::before {background-color: rgba(var(--color-custom-4-rgb),0.67)}}

        // Filter Color 5
        &.filter--5-37 {&::before {background-color: rgba(var(--color-custom-5-rgb),0.37)}}
        &.filter--5-50 {&::before {background-color: rgba(var(--color-custom-5-rgb),0.50)}}
        &.filter--5-67 {&::before {background-color: rgba(var(--color-custom-5-rgb),0.67)}}
    }   
}


/*
=======================================================================
    Block Foreground
=======================================================================
*/

.block__front {
    position:relative;
}


/*
=======================================================================
    Block Separator
=======================================================================
*/

div[class*="block-separator--"] {
    --block-separator-color: rgba(0,0,0,0.15);
    border-color:transparent;
    border-width:1px;
    border-style: solid;
}

.block-separator--top {
    border-top-color: var(--block-separator-color)!important;
    padding-top: var(--padding-block)!important;
}

.block-separator--bottom {
    border-bottom-color: var(--block-separator-color)!important;
    + .block {
        padding-top: var(--padding-block)!important;
    }
}


/*
=======================================================================
    Boostrap Override
=======================================================================
*/

main {
    .container {
        .container {
            max-width: unset;
            padding:unset;
            margin:unset;
        }
    }
}