/*.block:not(.block__list__logos) {display:none;}*/

.block__list__logos {
    .block__header {
        .block__header__title {
            text-align:center;
        }
    }

    .block__body {
        .list {
            --bs-gutter-y:0;

            .listelement {
                --bs-gutter-y:0;
                display: flex;
                flex-direction: column;
                justify-content: center;

                a {
                    display: flex;
                    justify-content:center;
                    align-items: center;
                    aspect-ratio:4/3;
                
                    .listelement__logo {
                        width:100%;
                        height:100%;
                        
                        position:relative;

                        > * {
                            max-width: 100%;
                            max-height: 100%;
                            display: block;
                            margin: 0 auto; /* Center the SVG horizontally if needed */
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform:translate(-50%, -50%);
                        }
                    } 
                }                
            }
        }
    }
}


/*
=======================================================================
    Font color
=======================================================================
*/

.block__list__logos {
    &.background-color--black,
    &.background-color--color1,
    &.background-color--color2,
    &.background-color--color5 {
        color: var(--color-white-hex);
    }
}