.block__list__features {

    .block__body {
        .list {

            --bs-gutter-y: 2rem;

            .listelement {
                display: flex;
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;
                gap:1rem;
                    
                .listelement__icon {
                    width: 3rem;
                }
                .listelement__body {
                    flex-basis: 0;
                    -ms-flex-positive: 1;
                    flex-grow: 1;
                    min-width: 0;
                    max-width: 100%;
                    padding:0;

                    .title {
                        font-size: var(--bs-body-font-size);
                        text-transform: uppercase;
                    }
                }  
            }
        }
    }
}