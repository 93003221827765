.block__inspiration {
    .block__body {
        .parent {
            aspect-ratio: 1 / 1;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(7, 1fr);
            grid-column-gap: 1rem;
            grid-row-gap: 1rem;

            > div {
                position:relative;
                
                img {
                    width:100%;
                    height:100%;
                    object-fit:cover;
                    object-position: center center;
                }
            }
        }
            
        .div1 { grid-area: 1 / 1 / 3 / 2; }
        .div2 { grid-area: 3 / 1 / 8 / 2; }
        .div3 { grid-area: 1 / 2 / 4 / 3; }
        .div4 { grid-area: 4 / 2 / 8 / 3; }


        .hotspot {
            display:block;
            width:20px;
            height:20px;
            border-radius:20px;
            background: red;
            position: absolute;
            top:20%;
            left:20%;
        }        


    }
}