header {
    &.site__header {
        &.site__header--fixed {
            position:fixed;
            top:0;
            z-index: 15;
            width: 100%;
        }
    }
}


/*
========================================================================================================
    Header text color white if fixed and scroll position on top of page and first section is a hero

    Update: 19/10-23: This code is obsolete because of reasons
========================================================================================================
*/

/*
body.hero.top-of-page {
    .site__header {
        &.site__header--fixed {
            .site__header__wrapper {
                .site__header__left,
                .site__header__right {
                    > nav {
                        > ul {
                            > li:not(.nav--hamburger) {
                                > button,
                                > a {
                                    color: var(--color-white-hex);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
*/