.block__accordiontab {
    margin-top: var(--spacer-5);

    .accordion-tab-wrap{
        position: relative;

        /* Tabs */
        .nav-tabs {
            display:none;
            border-bottom: 1px solid #dee2e6;

            .nav-item {
                margin-bottom: 0px;
                flex:1;

                .nav-link {
                    text-transform:uppercase;
                    font-weight:500;
                    font-size: 1rem;
                    width:100%;

                    &.active {
                    border-bottom: 3px solid var(--bs-body-color);
                    }
                }                
            }

            .nav-link{
                border: none;
                border-top-left-radius: 0px;
                border-top-right-radius: 0px;
            }
        } 
        
        .custom-item-body {
            padding: 1rem 0;

            h1,h2,h3,h4,h5,h6 {
                font-size: var(--bs-body-font-size);
                font-weight:500;
            }
        }

        /* Tab styling */
        @include media-breakpoint-up(xxl) {
            .nav-tabs {
                display: flex;
                margin-bottom:var(--margin-default);
            }
            
            .custom-accordion-tab-item {
                border: none;

                > .custom-accordion-header {
                    display:none;
                }                

                > .collapse{
                    display:block;
                }
            }                
        }

        /* Accordion styling */
        @include media-breakpoint-down(xxl) {
            .tab-content > .tab-pane {
                display: block !important;
                opacity: 1;
                margin-bottom: 10px;
            }
        
            .custom-accordion-header {
                background:none;
                border:0;

                .custom-accordion-button {
                    text-transform:uppercase;
                    text-decoration: none;
                    border-bottom: none;
                    display: block;
                    position:relative;
                    padding: 1rem 0;
                    font-weight:400;

                    &::after {
                        @include fontawesomesharp;
                        @include transition;
                        content:"\f078";
                        position: absolute;
                        top: 50%;
                        right: 1rem;
                        transform: translateY(-50%);
                    }


                    &[aria-expanded="true"] {
                        &::after {
                            transform:rotate(-180deg) translateY(50%);
                        }
                    }
                }
            }

            .custom-accordion-tab-item {
                border-bottom: 1px solid #dee2e6;
            }
        }
    }
}