@mixin fontawesome {
    font-family: Font Awesome\ 6 Pro;
    display: inline-block;
}

@mixin fontawesomesharp {
    font-family: Font Awesome\ 6 Sharp;
    display: inline-block;
}

@mixin fontawesomebrands {
    font-family: Font Awesome\ 6 Brands;
    display: inline-block;
}

@mixin transition {transition: all 0.3s ease;}
@mixin transition-color {transition: color 0.3s ease;}
@mixin transition-opacity {transition: opacity 0.3s ease;}
@mixin transition-transform {transition: transform 0.3s ease;}
@mixin transition-outline {transition: outline-color 0.3s ease;}
@mixin transition-border-radius {transition: border-radius 0.3s ease;}
@mixin transition-background-color {transition: background-color 0.3s ease;}
@mixin transition-padding {transition: padding 0.3s ease;}

@mixin transition--slow {
    transition: all 0.8s ease;
}

@mixin reset-button {
    border:0;
    padding:0;
    background:transparent;
}

@mixin reset-border {
    border-radius: 0;
    border: 0;
}

@mixin reset-ul {
    margin:0;
    padding:0;
    list-style:none;
}

@mixin reset-li {
    margin:0;
    padding:0;
}

@mixin reset-box-shadow {
    -webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}

@mixin image-overlay {
    content:"";
    width:100%;
    height:100%;    
    position:absolute;
    bottom:0;
    left:0;
    top:0;
    right:0;   
    z-index: 1;
    box-shadow: rgba(229,229,229,0.3) 0 0 84px 6px inset;
    mix-blend-mode: normal;
}

@mixin placeholder {
    &.placeholder { @content; }
    &:-moz-placeholder { @content; }
    &::-moz-placeholder { @content; }
    &:-ms-input-placeholder { @content; }
    &::-webkit-input-placeholder { @content; }
}

@mixin absolute-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@mixin line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: var(--line-clamp);
    -webkit-box-orient: vertical;
    overflow: hidden;
}

@mixin hide-input {
    border: 0;
    margin: 0;
    opacity: 0;
    outline: 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
}