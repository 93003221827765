/*
=======================================================================
    Custom Spacers
=======================================================================
*/

:root {
    --spacer-1: clamp(0.8rem, 0.08vw + 0.78rem, 0.84rem);
    --spacer-2: clamp(1rem, 0.23vw + 0.94rem, 1.13rem);
    --spacer-3: clamp(1.25rem, 0.45vw + 1.14rem, 1.5rem);
    --spacer-4: clamp(1.56rem, 0.79vw + 1.36rem, 2rem);
    --spacer-5: clamp(1.95rem, 1.29vw + 1.63rem, 2.66rem);
}