.block__list {
    &.block__list__articles {

        .block__header {

            .block__header__wrapper {
                align-items: flex-end;

                .block__header__wrapper__left {
                    .block__header__preTitle {
                        text-transform:uppercase;
                    }
                }

                .block__header__wrapper__right {
                    a {
                        font-size:1.25rem;
                        font-weight:500;
                        display:flex;
                        gap:.5rem;
                        text-decoration: none;

                        &:hover,
                        &:focus,
                        &:focus-visible {
                            text-decoration: underline;
                        }
                    }
                }
            }

            .block__header__filter {
                margin-top:1rem;

                ul {
                    @include reset-ul;
                    display:flex;
                    flex-wrap:wrap;
                    gap:.5rem;
                    

                    li {
                        @include reset-li;

                        a {
                            display:block;
                            font-family: 'Wix Madefor Display', sans-serif;
                            font-size: 0.875rem;
                            font-weight:600;
                            text-decoration: none;
                            text-transform: uppercase;
                            color: var(--color-custom-1-hex);
                            border:1px solid var(--color-custom-1-hex);
                            border-radius: 5rem;
                            padding:.5rem 1rem;
                        }

                        &:hover,
                        &:focus,
                        &:focus-visible {
                            a {
                                background-color:var(--color-custom-1-hover-hex);
                                color:#fff;
                            }
                        }

                        &.active {
                            a {
                                background-color:var(--color-custom-1-hex);
                                color:#fff;
                            }
                        }
                    }
                }
            }
        }

        .block__body {
            .list {
                .listelement {
                    .listelement__body {
                        padding:1rem;

                        .byline {
                            font-size:1rem;
                        }

                        .product-labels {
                            flex-wrap:wrap;

                            .label {                         
                                span {
                                    --label-background-color: #EFF0F3;
                                    font-weight:500;
                                }
                            }
                        }
                    }
                }
            }
        }

        .block__footer {
            margin-top: var(--margin-default);

            .container {
                text-align:center;
            }
        }
    }
}


/*
=======================================================================
    Featured article
=======================================================================
*/

.block__list {
    &.block__list__articles {
        .block__body {
            .listelement--featured {
                a {        
                    @include media-breakpoint-up(md) {
                        display:flex;
                        align-items: center;
                        justify-content: space-between;
                        gap:var(--bs-gutter-x);

                        > * {
                            flex:1;
                        }

                        .listelement__body {
                            padding: 0 !important;
                        }
                    }
                }
            }
        }
    }
}